import {requests} from "../utils/http";
// 自定义列  查询用户自定义列表
export const zjyyFieldDetail=(query)=>{
  return requests({
     url:"/common/zjyyFieldDetail/list",
     method:'get',
     params: query
 })
}
// 初始化
export const initial=(query)=>{
  return requests({
     url:"/common/zjyyFieldConfig/list",
     method:'get',
     params: query
 })
}
// 修改
export const updateTable=(data)=>{
  return requests({
     url:"/common/zjyyFieldDetail/addList",
     method:'post',
     data
 })
}