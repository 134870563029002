import {requests} from "../utils/http";
// 品种分类导入查询
export const importSearch=(query)=>{
    return requests({
       url:"/prodCate/getProd",
       method:'get',
       params:query,
       headers: {"Content-Type":"multipart/form-data"},
   })
 }
// 品种分类全部导入
export const importProd=(query)=>{
    return requests({
       url:"/prodCate/importProd",
       method:'get',
       params:query,
       headers: {"Content-Type":"multipart/form-data"},
   })
 }
 // 查询所有的自定义分类
export const getAllFl=(query)=>{
  return requests({
     url:"/publiSystemConfig/list",
     method:'get',
     params:query
 })
}
// 查询渠道列表
export const getAllChannel=(query)=>{
  return requests({
     url:"/importChannelConfig/selectList",
     method:'get',
     params:query
 })
}


