import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ReprintReplenishment from '../views/ReprintReplenishment/ReprintReplenishment'

export default function IndexRouter() {
    return (
        <Router>
            <Switch>
                {/* 每次匹配路径 / 时，就会调用render里面这个回调函数 */}
                    <Route path='/' render={() => <ReprintReplenishment></ReprintReplenishment>
                } />
            </Switch>
        </Router>
    )
}
