/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react'
import style from './index.module.css'
import { Input, Select, Button, Table, Form, Row, Col, Popover, message, Tooltip, InputNumber } from 'antd';
import { ExclamationCircleFilled, EditOutlined, CloseOutlined, CheckOutlined, ForwardOutlined, DownCircleTwoTone ,QuestionCircleFilled} from '@ant-design/icons';
import CustomTable from '../../../components/CustomTable';
import HistoryRecord from '../../../components/HistoryRecord'
import { zjyyFieldDetail, initial } from '../../../api/customTable'
import { publiSystemConfig, dictData, replenishmentCal, showList, updateStock, stockExport, historyRecord, exportHistoryStock } from '../../../api/replenishmentSupervise'
import { downFile, getALLQuery } from '../../../utils';
import moment, { duration } from 'moment';
import { importLastTime } from '../../../api/importData';
import { getAllChannel } from '../../../api/config';
import {cloneDeep} from 'loadsh'

export default function PrintNumberSupervise() {
    const userInfo = getALLQuery()
    // 
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [data, setData] = useState([])
    const [isInit, setIsInit] = useState(true);
    const [changeFlag, setChangeFlag] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenHisModal, setIsOpenHisModal] = useState(false)
    const [columns, setColumns] = useState([])
    const [startTime, setStartTime] = useState()  // 当前时间减去统计周期
    const [optionsCate, setOptionsCate] = useState([])
    const [staticOptionsCate, setStaticOptionsCate] = useState([])
    const [optionsFunnel, setOptionsFunnel] = useState([])
    const [pzOptions, setPzOptions] = useState([]) // 下方表格品种选择数据
    const [channelOptions, setChannelOptions] = useState([]) // 下方表格渠道选择
    const [isNeedCate, setIsNeedCate] = useState()  // 将查询品种分类接口返回得isNeedCate保存，以便计算数据接口作为参数使用
    const [selectedFunValue, setSelectedFunValue] = useState('all')  // 当选择了最上面的渠道后，将value值保存
    const [selectedFunValueT, setSelectedFunValueT] = useState('all') 
    const [batchId, setBatchId] = useState() // 历史批次
    const [batchName, setBatchName] = useState('') // 导出历史名称
    const [batchSaleDate, setBatchSaleDate] = useState([]); // 销售统计周期
    const [lastTime, setLastTime ] = useState() //截止日期时间
    const [lastHistoryRecord, setLastHistoryRe] = useState({}) // 最近一条操作记录
    const [isDisabled, setIsDisabled] = useState(false) // 导出结果是否禁用
    const [isLoading, setIsLoading] = useState(false) // 在table展示出数据之前有一个加载效果
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0}) // 表单分页器
    const [jisuanLoading, setJisuanLoading] = useState(false);
    const [isRefresh, setRefresh] = useState(false)
    const ref1 = useRef(data);
    const ref = useRef();
    const ref2 = useRef('')
    const column = [
        {
            title: '书目信息',
            align: 'left',
            isOpen: true,
            width: 300,
            render: (_, record) => (
                <div>
                    <div style={{ color: '#1A76D2' }}>{record.prodName}</div>
                    <div>
                        <span style={{ marginRight: '10px' }}>ISBN:{record.isbn}</span>
                        <span>定价：{record.price}</span>
                    </div>
                    {/* <div>出版日期：{record.publiDate}</div>
                    <div>作者:{record.authorName}</div> */}
                </div>
            )
        },
        {
            title: (
                <span>
                    渠道库存数
                    <Tooltip placement="top" title={<p className="u-tips">该品种在当前销售渠道的库存数</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'channelInvNum',
            align: 'center',
            isOpen: true,
            width: 200,
        },
        {
            title: (
                <span>
                    渠道库存周转天
                    <Tooltip placement="top" title={<p className="u-tips">渠道库存数/渠道日均销售数</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'channelTurnoverDay',
            align: 'center',
            isOpen: true,
            width: 200,
        },
        {
            title: (
                <span>
                    渠道日均销售数
                    <Tooltip placement="top" title={<p className="u-tips">渠道销售总数/统计周期的天数</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'channelAvgSaleNum',
            align: 'center',
            isOpen: true,
            width: 200,
        },
        {
            title: (
                <span>
                    渠道销售总数
                    <Tooltip placement="top" title={<p className="u-tips">在统计周期内该品种在当前销售渠道的销售总数</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'channelSaleNum',
            align: 'center',
            isOpen: true,
            width: 180,
        },
        {
            title: '上次补货日期及数量',
            // dataIndex: 'lastReplenishmentDateNum',
            align: 'center',
            isOpen: true,
            width: 300,
            render: (_, record) => (
                <div>
                    <div style={{ paddingLeft: '27px' }}>日期：{record.lastStockDate}</div>
                    <div>数量：{record.lastStockNum}本</div>
                </div>

            ),
        },
        {
            title: '是否需要补货',
            dataIndex: 'isNeed',
            align: 'center',
            isOpen: true,
            width: 180,
            render: (value, record) => {
                return (
                    <>
                        {
                            record.printNum == 0 ? '否' : record.isNeed == '1' ? '是' : '否'
                        }

                    </>
                )
            }
        },
        {
            title: (
                <span>
                    本次补货数
                    <Tooltip placement="top" title={<p className="u-tips">系统估算出本次需要补货的数量</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'printNum',
            align: 'center',
            isOpen: true,
            width: 150,
            render: (text, record, index) => {
                return (
                    <>
                        {
                            // 每行数据自带的record.isOpen控制的是，点击该行编辑图标的时候不至于每一行输入框都显示    
                            record.edit == true ? <div className={style.printNumStyle}>
                                <InputNumber onChange={handleClick} style={{ width: '80px' }} defaultValue={text} />
                                <CloseOutlined onClick={() => cancelModifyNum(index)} />
                                <CheckOutlined onClick={() => yesModifyNum(record.id, index)} />
                            </div>
                            : <div className={style.editStyle}>
                                <div style={{ color: '#1A76D2', marginRight: '5px' }}>{text}本</div>
                                {
                                    ref2.current == ''  ? <EditOutlined onClick={() => modifyPrintNum(record, index)} /> : ''
                                }
                            </div>
                        }
                    </>
                )
            }
        },
    ];
    const popoverContent1 = (
        <div className={style.font}>
            <p>库存数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!lastTime?.kc ? lastTime?.kc : '--'}</span></p>
            <p>书目信息更新日期：<span style={{ color: '#A9A9A9' }}>{!!lastTime?.pz ? lastTime?.pz: '--'}</span></p>
        </div>
    );
    const popoverContent2 = (
        <div className={style.font}>
            {
                optionsFunnel.filter(item=>item.label!='全部渠道').map((item)=>{
                    const channel = item.value
                    return (
                        item.isMerge == '1' ?<p>{item.label}库存及销售数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!(lastTime&&lastTime[channel]) ? lastTime[channel]: '--'}</span></p>:
                        <>
                            <p>{item.label}销售数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!(lastTime&&lastTime[channel+'_xs'])? lastTime[channel+'_xs']: '--'}</span></p>
                            <p>{item.label}库存数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!(lastTime&&lastTime[channel+'_kc'])? lastTime[channel+'_kc']: '--'}</span></p>
                        </>
                       
                    )
                })
            }
        </div>
    );

    useEffect(() => {
        if (isInit == false) {
            if (batchId) {
                getHistoryListT()
            } else {
                getReplenishment()
            }
        } else {
            setIsInit(false)
        }
    }, [pagination.current, batchId, changeFlag])

    useEffect(() => {
        getCol(); // 获取表头
        getSaleChannelSelectList(); // 获取销售渠道下拉列表数据  
        getLastTime(); // 获取截止日期
    }, [])

    const refresh = () => {
        getCol(); // 获取表头
        getSaleChannelSelectList(); // 获取销售渠道下拉列表数据  
        if(pagination.current != 1) setPagination({...pagination, current: 1})
        else getReplenishment();
        getLastTime(); // 获取截止日期
        setRefresh(!isRefresh)
        form.resetFields()
        form1.resetFields()
    }

    // 读取表头配置
    const getCol = () => {
        zjyyFieldDetail({
            userId: userInfo.userId,
            module: 'app_stock',
            publiId: userInfo.publiId
        }).then(async resO => {
            let colArr = [];
            if (resO.result_code == 200 && resO.totalDataNum == 0) {
                await initial({
                    module: 'app_stock'
                }).then(res => {
                    colArr = res.data
                })
            } else {
                colArr = resO.data
            }
            let morenArr = ['书目信息', '渠道库存数', '渠道库存周转天' ,'渠道日均销售数', '渠道销售总数', '上次补货日期及数量', '是否需要补货', '本次补货数']
            let arrT = [...colArr].map((ele, idx )=> {
                
                if (morenArr.indexOf(ele.fieldName) != -1) {
                    
                    colArr.splice(idx , 1);
                    [...column].map(item => {
                        let title
                        if(typeof(item.title) != 'string'){
                            title = item.title.props.children[0]
                        }else{
                            title = item.title
                        }
                        if (ele.fieldName == title) {
                            item.align = ele.align == '1' ? 'left' : ele.align == '2' ? 'center' : 'right'
                            item.isOpen = ele.isOpen
                            item.fixed = ele.isFreeze == '1' ? 'left' : ele.align == '2' ? 'right' : undefined
                            item.orderId = ele.orderId
                            item.width = ele.size + 'px'
                        }
                    })
                } else {
                    return {
                        title: ele.fieldName,
                        dataIndex: ele.fieldCode,
                        align: ele.align == '1' ? 'left' : ele.align == '2' ? 'center' : 'right',
                        isOpen: ele.isOpen,
                        fixed: ele.isFreeze == '1' ? 'left' : ele.align == '2' ? 'right' : undefined,
                        orderId: ele.orderId,
                        width: ele.size + 'px'
                    }
                }
            });
            let cloneArr = [...column, ...arrT].filter(item => !!item && item.isOpen == 1);
            cloneArr.sort((a, b) => a.orderId - b.orderId)
            setColumns(cloneArr)
        })
    }

    const getPzSelectList = (value , type) => { // type: 0-上方表格 1-下方表格 3-更新数据     
        // console.log(type,'我是type');
        publiSystemConfig({
            publiId: userInfo.publiId,
            configType: '2',
            channel: value != 'all' && value  ? value : '0',
        }).then(res => {
            if (res.code == 200) {
                const cate = res.rows.map((item) => {
                    return (
                        {
                            label: item.levelName,
                            value: item.levelId,
                        }
                    )
                })
                let isNeed = res?.rows[0]?.isNeedCate;
                let arr = [{
                    label: '全部品种',
                    value: 'all',
                }, ...cate];
                if (type == 3) {
                    setStaticOptionsCate([...arr])
                    let newArr = arr.filter(item=>!(item.label.includes('全部品种-')))
                    setOptionsCate([...newArr])
                    setPzOptions([...newArr])
                    form.setFieldsValue({
                        leveIdList: arr[0].value
                    })
                    form1.setFieldsValue({
                        levelId: arr[0].value,
                    })
                } else if (type == '0'){
                    setStaticOptionsCate([...arr])
                    let values = form.getFieldsValue()
                    if(values.channelName == 'all'){  // 渠道为全部渠道的时候，品种分类中要去掉包含 '全部品种-' 的所有分类
                        let result1 = arr.filter(item=>!(item.label.includes('全部品种-')))
                        setOptionsCate([...result1])
                        form.setFieldsValue({
                            leveIdList: arr[0].value
                        })
                    }else if(isNeed == '0') { // 当渠道为具体的某一个渠道，如京东，此时京东渠道若不需要分类，则品种分类下拉框以及默认值都是全部品种-京东
                        let result2 = arr[1]
                        setOptionsCate([result2])
                        form.setFieldsValue({
                            leveIdList: result2.value
                        })
                    }else{ //当渠道为具体的某一个渠道，如京东，此时京东渠道需要分类，则品种分类下拉框以及默认值都是全部品种-京东
                        let length = arr.length
                        arr[0].label = arr[0].label + arr[length-1].label.slice(6)
                        setOptionsCate([...arr])
                        form.setFieldsValue({
                            leveIdList: arr[0].value
                        })
                    }
                } else {
                    let values = form1.getFieldsValue()
                    if(values.channel == 'all'){  // 渠道为全部渠道的时候，品种分类中要去掉包含 '全部品种-' 的所有分类
                        // console.log('我是全部渠道',arr);
                        let result1 = arr.filter(item=>!(item.label.includes('全部品种-')))
                        setPzOptions([...result1])
                        form1.setFieldsValue({
                            levelId: arr[0].value,
                        })
                    }else if(isNeed == 0) { // 当渠道为具体的某一个渠道，如京东，此时京东渠道若不需要分类，则品种分类下拉框以及默认值都是全部品种-京东
                        let result2 = arr[1]
                        setPzOptions([result2])
                        form1.setFieldsValue({
                            levelId: result2.value
                        })
                    }else{ //当渠道为具体的某一个渠道，如京东，此时京东渠道需要分类，则品种分类下拉框以及默认值都是全部品种-京东
                        let length = arr.length
                        arr[0].label = arr[0].label + arr[length-1].label.slice(6)
                        setPzOptions([...arr])
                        form1.setFieldsValue({
                            levelId: arr[0].value
                        })
                    }
                }
                setIsNeedCate(isNeed)
                const startDate = new Date().getTime() - res?.rows[0]?.countDay * 86400000
                setBatchSaleDate([moment(startDate).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
                setStartTime(startDate)
            }
        })
    }

    const getSaleChannelSelectList = () => {
        getAllChannel({publiId:userInfo.publiId}).then(res => {
            if(res.code == 200){
                const funnel = [...res.rows].filter(item => item.isOpen == '1' && item.channelName != '线下').map((item) => {
                    console.log(item, 'item====')
                    return (
                        {
                            label: item.channelName,
                            value: item.channel,
                            isMerge : item.isMerge,
                        }
                    )
                })
                setOptionsFunnel([{
                    label: '全部渠道',
                    value: 'all',
                }, ...funnel])
                setChannelOptions([{
                    label: '全部渠道',
                    value: 'all',
                }, ...funnel])
                getPzSelectList('all' , 3)
            }else{
                message.error(res.msg,2)
            }
        })
    }

    const getLastTime = () => {
        importLastTime(userInfo.publiId).then(res => {
            if (res.code == 200 && JSON.stringify(res.data) != '{}') {
                let arr = res.data;
                let arr1 = [!!arr.kc ? moment(arr.kc) : '', !!arr.pz ? moment(arr.pz) : ''].filter(item => !!item)
                let outArr = Object.assign({}, arr)
                delete outArr.kc
                delete outArr.pz
                console.log(outArr);
                let arr2 = Object.values(outArr).map(item=>moment(item)).filter(item => !!item)
                let max1 = [...arr1].length == 0 ? undefined : moment.max(arr1).format('YYYY-MM-DD')
                let max2 = [...arr2].length == 0 ? undefined : moment.max(arr2).format('YYYY-MM-DD')
                let arr3 = Object.assign(arr, {max1: max1}, {max2: max2})
                setLastTime(arr3)
            }
        })
    }

    // 根据id查询历史数据
    const getListByBatchIdT = (record) => {
        setBatchId(record.batchId);
        setBatchName(record.batchName);
        setBatchSaleDate([record.startDate, record.endDate]);
        ref2.current = record.batchName
        // setColumns([...columns])
    }

    // 改变分页重新查询数据   
    const onPageChange = (page) => {
        setPagination({...pagination, current: page})
    }

    const getReplenishment = async () => {
        let values  = await form1.getFieldsValue()
        showList({
            pageNum: pagination.current,
            pageSize: 10,
            isNeed: values?.isNeed == '3' ? '' : values?.isNeed,
            levelId: values?.levelId == 'all' ? null : values?.levelId,
            channel: values?.channel == 'all' ? null: values?.channel,
            creator: userInfo.userId,
            publiId: userInfo.publiId,
            prodName: values?.prodName
        }).then(res => {
            setIsLoading(false)
            if (res.code == '200') {
                let arr = [...res.rows]
                arr.map((item) => item.edit = false)
                setData(arr)
                ref1.current = arr;
                setPagination({
                    ...pagination,
                    total: res.total
                })
            } else {
                message.error(`${res.msg}`,2);
            }
        })
    }

    // 获取历史数据列表
    // 获取历史展示列表
    const getHistoryListT = () => {
        historyRecord({
            pageNum: pagination.current,
            batchId
        }).then(res => {
            if (res.msg == '查询成功') {
                _maq.trigger(['_trackEvent', `补货预测-查看历史数据: ${batchId}-第${pagination.current}页`]); 
                setData(res.rows)
            }
        })
    }

    const handleClick = (val) => {
        ref.current = val
    }

    // 当自定义列表弹框进行相应修改后，提印预测页面中table进行相应变化
    const change = () => {
        getCol()
    }
    // 当点击建议印数该列的编辑图标时相关操作
    const modifyPrintNum = (record, idx) =>  {
        let arr = cloneDeep(ref1.current)
        arr[idx].edit = true 
        setData([...arr])
    }

    // 修改建议印数
    function cancelModifyNum(idx) {
        let arr = cloneDeep(ref1.current)
        arr[idx].edit = false
        ref.current = arr[idx].printNum
        setData([...arr])
    }

    const yesModifyNum = (id, idx) => {
        let arr = cloneDeep(ref1.current)
        if (!/^[+]{0,1}(\d+)$/.test(ref.current)) {
            message.error('请输入正整数',2)
            return false;
        }
        updateStock({
            id,
            printNum: ref.current,
            isNeed: ref.current == 0 ? '0' : '1'
        }).then(res => {
            if (res.code == 200) {
                arr[idx].edit = false;
                arr[idx].printNum = ref.current;
                arr[idx].isNeed = ref.current == 0 ? '0' : '1';
                _maq.trigger(['_trackEvent', `补货预测-修改补货数: ${ref.current}`]); 
                // getReplenishment()
            } else {
                arr[idx].edit = false;
                message.error(res.msg,2)
            }
            setData(cloneDeep(arr))
            ref1.current = [...arr]
            message.success(res.msg,2)
        })
    }
    // 点击补货预测按钮后开始计算
    const startSupervise = (values) => {
        setIsDisabled(false)
        setJisuanLoading(true)
        let leveIdAll = staticOptionsCate.map(item => item.value)
        // let values = form.getFieldsValue()
        console.log(values, 'values=====')
        let parmas = {
            publiId: userInfo?.publiId,
            configType: '2',
            creator: userInfo?.userId,
            leveIdList: values?.leveIdList == 'all' ? leveIdAll.filter(item => item != 'all') : [values?.leveIdList],
            isNeedCate: '1',
            channel: values?.channelName == 'all' ? '1' : values?.channelName
        }
        console.log(parmas, 'parmas')
        replenishmentCal(parmas).then((res) => {
            setJisuanLoading(false)
            if (res.code == '200') {
                // 补货预测成功后，table表单联动 
                form1.setFieldsValue({ levelId: values?.leveIdList,channel: selectedFunValue })
                setPzOptions(optionsCate)
                setSelectedFunValueT(selectedFunValue)
                setIsLoading(true)
                getReplenishment()
                _maq.trigger(['_trackEvent', `补货预测-补货预测按钮: ${JSON.stringify(parmas)}`]);   
            } else {
                message.error(res.msg,2)
            }
        })
    }

    async function handleExport() {
        setIsDisabled(true)
        const values = await form1.getFieldsValue()
        let pzObj = pzOptions.filter(item => item.value == values.levelId)
        let levelIdStr = values.levelId == 'all' ? '' : values?.levelId; 
        let channelStr = values.channel == 'all' ? '' : values?.channel; 
        let parmas = {
            ...values,
            channel:channelStr,
            publiId: userInfo.publiId,
            creator: userInfo.userId,
            creatorName: userInfo?.userName,
            levelId: levelIdStr,
            configType: '2',
            startDate: batchSaleDate[0],
            endDate: batchSaleDate[1],
            prodName:!!values.prodName ? values.prodName : '',
            levelName: pzObj[0].label,
            isNeed: values.isNeed == '3' ? '' : values.isNeed
        }
        stockExport(parmas).then(res => {
            if (res.status == 200) {
                _maq.trigger(['_trackEvent', `补货预测-导出结果-导出条件:${JSON.stringify(parmas)}`]); 
                downFile(res)
                refresh()
            } else {
                message.error(res.msg,2)
            }
        })
    }
    // 导出历史结果
    function exportHistoryRecord() {
        exportHistoryStock({
            batchId: batchId
        }).then(res => {
            if (res.status == 200) {
                _maq.trigger(['_trackEvent', `补货预测-导出历史结果-批次id: ${batchId}`]); 
                downFile(res)
            } else {
                message.error(res.msg,2)
            }
        })
    }
    // 重置页面
    const goBack = () => {
        form.resetFields();
        form1.resetFields();
        setBatchId('');
        setBatchName('');
        setBatchSaleDate([moment(startTime).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
        setPagination({...pagination,current: 1, total: 0})
        setData([])
        setIsInit(true)
        getSaleChannelSelectList()
        setSelectedFunValueT('all')
        ref2.current = ''
    }

    const handleFunnelChange = (value) => {
        setSelectedFunValue(value)
        form.setFieldsValue({ channel: value })
        getPzSelectList(value, '0')
        // console.log(optionsFunnel, '全部渠道', value)
    };

    const handleChannelChange = (value) => {
        getPzSelectList(value, '1')
    };

    // 下方表单发生更改时
    const onFormChange = (props, changedValues, allValues) => {
        setChangeFlag(!changeFlag)
        setPagination({...pagination, current: 1})
    }
    return (
        <>
            <div className={style.replenishmentTop}>
                <div className={style.form}>
                    <Form 
                        initialValues={{ leveIdList: 'all', channelName: 'all' }} 
                        labelCol={{ style: { width: 100, textAlign: 'left' } }} 
                        layout="inline" 
                        onFinish={startSupervise}
                        form={form}
                    >
                        <Row>
                            <Col>
                                <Form.Item label="销售渠道 :" name='channelName' style={{ fontWeight: 'bold' }}>
                                    <Select 
                                        style={{ width: 120 }}
                                        options={optionsFunnel}
                                        onChange={handleFunnelChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name='leveIdList' label="品种分类 :" style={{ fontWeight: 'bold' }}>
                                    <Select style={{ width: 150 }} options={optionsCate} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button 
                            type="primary" 
                            htmlType='submit'
                            style={{ marginRight: '15px', marginLeft: '40px' }} 
                            disabled={batchName !== ''}
                            loading={jisuanLoading}
                        >
                            补货预测
                        </Button>
                        {
                            batchName !== '' ? <Button type="primary" ghost onClick={goBack}>返回</Button> : undefined
                        }
                    </Form>
                </div>
                <div className={style.time}>
                    <div style={{ marginRight: '50px', marginBottom: '20px' }}>
                        <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px' }} />
                        <span>最近补货：<span style={{ color: '#1A76D2', cursor: 'pointer' }}>{lastHistoryRecord?.batchName}<ForwardOutlined /></span></span>
                        <span onClick={() => {setIsOpenHisModal(!isOpenHisModal);_maq.trigger(['_trackEvent', `补货预测-查看更多历史记录`]); }} style={{ color: '#A9A9A9', cursor: 'pointer' }}>(点击展示更多记录)</span>
                    </div>
                    <div style={{ marginRight: '50px', marginBottom: '20px' }}>内部数据更新日期:
                        {
                            lastTime?.max1 ? (
                                <>
                                    <span style={{ color: '#A9A9A9', paddingLeft: '5px', paddingRight: '5px' }}>{lastTime?.max1}</span>
                                    <Popover placement="bottom" content={popoverContent1} trigger="click">
                                        <DownCircleTwoTone />
                                    </Popover>
                                </>
                            ) : '--'
                        }
                    </div>
                    <div style={{ marginBottom: '20px' }}>外部数据更新日期:
                        {
                            lastTime?.max2 ? (
                                <>
                                    <span style={{ color: '#A9A9A9', paddingLeft: '5px', paddingRight: '5px' }}>{lastTime?.max2}</span>
                                    <Popover placement="bottom" content={popoverContent2} trigger="click">
                                        <DownCircleTwoTone />
                                    </Popover>
                                </>
                            ) : '--'
                        }
                    </div>
                </div>
            </div>
            <div className={style.replenishmentMiddle}>
                <div style={{ padding: '10px', marginBottom: '10px' ,flex:'1',display:'flex',flexWrap:'wrap',alignItems:'baseline'}}>
                    <span style={{ marginRight: '20px', fontWeight: 'bold',marginBottom:'10px' }}>
                        品种展示 (销售统计周期：
                        <span style={{ color: '#A9A9A9' }}>{batchSaleDate[0]} 至 {batchSaleDate[1]}</span>)
                    </span>
                    <span style={{marginRight:'20px'}}>{batchName}</span>
                    <Button type="primary" ghost onClick={() => {setIsOpenModal(!isOpenModal);_maq.trigger(['_trackEvent', `补货预测-自定义列`]); }}>
                        自定义列
                    </Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-around' ,width:'910px'}} >
                    <Form 
                        initialValues={{ levelId: 'all', channel: 'all', isNeed: '1' }} 
                        form={form1} 
                        layout="inline"
                        onValuesChange={onFormChange} 
                    >
                        <Form.Item  label="是否需要补货 :" name='isNeed' style={{ marginLeft: '10px' }}>
                            <Select 
                                style={{ width: 120 }} 
                                disabled={!batchName == '' || isDisabled}
                                options={[
                                    { value: '3', label: '全部' },
                                    { value: '1', label: '需要' },
                                    { value: '0', label: '不需要' },
                                ]} 
                            />
                        </Form.Item>
                        <Form.Item label="" name='channel' style={{ marginLeft: '14px' }} >
                            <Select 
                                style={{ width: 120 }} 
                                disabled={!batchName == '' || isDisabled}
                                onChange={handleChannelChange}
                                options={selectedFunValueT == 'all' ? channelOptions : channelOptions.filter(item => item.value == selectedFunValue)} 
                            />
                        </Form.Item>
                        <Form.Item  name='levelId' label="" style={{ fontWeight: 'bold', marginLeft: '14px', marginRight: '14px' }} >
                            <Select 
                                style={{ width: 150 }} 
                                disabled={!batchName == '' || isDisabled}
                                options={pzOptions} 
                            />
                        </Form.Item>
                        <Form.Item 
                            name='prodName' 
                            label="" 
                            style={{ marginRight: '14px' }}
                        >
                            <Input 
                                placeholder="请输入品种名称或ISBN" 
                                style={{ width: '180px' }} 
                                disabled={ !batchName == '' || isDisabled }
                            />
                        </Form.Item>
                        {
                            batchName == '' ?
                                <Button 
                                    type="primary" 
                                    style={{ marginRight: '15px' }} 
                                    onClick={() => handleExport()} 
                                    disabled={isDisabled || data.length == 0}
                                >
                                    导出结果
                                </Button> :
                                <Button type="primary" style={{ marginRight: '15px' }} onClick={() => exportHistoryRecord()}>
                                    导出历史结果
                                </Button>
                        }
                    </Form>
                    
                </div>
            </div>
            <div className={style.replenishmentBottom}>
                <Table 
                    columns={columns} 
                    dataSource={data} 
                    bordered={true} 
                    pagination={{...pagination, showSizeChanger: false, onChange: (page) => onPageChange(page)}} 
                    scroll={{ x: true }}
                    loading={isLoading}
                >
                </Table>
            </div>
            {
                isOpenModal == true && (
                    <CustomTable 
                        visible={isOpenModal} 
                        columnsData={columns} 
                        change={change} 
                        onCancel={() => setIsOpenModal(false)} 
                        module={'app_stock'} 
                    />
                )
            }
            <HistoryRecord
                visible={isOpenHisModal} 
                configType={'2'}
                onCancel={() => setIsOpenHisModal(false)}
                onPostLastItem={(item) => {setLastHistoryRe(item)}}
                getListByBatchId={(record) => {
                    setIsOpenHisModal(false);
                    getListByBatchIdT(record)
                }} 
                name={'stock'} 
                isRefresh={isRefresh}
            />
        </>
    )
}