import { Button, Form, Modal, Select, Upload, Input, message } from 'antd'
import React, { useEffect, useState } from 'react';
import style from './index.module.css'
import { importCusTable, importFieldMapping, importTemplate } from '../../api/importData'
import TimeModal from '../../components/TimeModal'
import { downFile } from '../../utils';
import { getALLQuery } from '../../utils';

export default function DataImortCustomCol({ visible, onCancel, onChange, userColumn, name, allField, allModuled, file, query}) {
    const [deletionTime, setDeletionTime] = useState([0, 0]) // 设置缺失日期时间
    const userInfo = getALLQuery()
    const [form] = Form.useForm();
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false) // 控制缺失日期弹框的显示
    const [loaing, setLoding] = useState(false)
    // 由于点击自定义配置弹框的确定按钮后，发现表格中有缺失日期，此时需要弹出缺失日期对话框，让用户选择是否忽略缺失的日期，若用户选择忽略，
    // 则继续调用上传表格通用接口，此时参数data1即为父组件OutCustomCol传给子组件TimeModal，
    const [data1, setData1] = useState()
    
    let amodule = allModuled?.filter(ele => ele.sheetName == name)[0]

    // 查询上一次映射关系后，提前自动渲染到select的输入框中，作为默认值，用户可以自行改变
    useEffect(() => {
        form.resetFields()
        visible &&
            importFieldMapping({
                publiId: userInfo.publiId,
                userId: userInfo.userId,
                moduleId: amodule?.moduleId
            }).then(res => {
                let obj = {}
                res.rows.forEach(item => {
                    if (userColumn.find(ele => ele == item.inFieldName)) {
                        obj[item.fieldName] = item.inFieldName
                    }

                })
                form.setFieldsValue({ ...obj })
                
            })
    }, [visible])
    // options为用户导入的excel表格的所有字段
    const options = userColumn.map((item) => {
        return (
            {
                label: item,
                value: item
            }
        )
    })
    const handleChange = (value) => {
        
    };
    // 下载模板   
    const importTemplateT = (data, filename) => {
        setLoding(true)
        importTemplate({'moduleId': data.moduleId}).then((res) => {
            message.success('请耐心等待下载完成',2)
            downFile(res)
        })
    }
    const onCheck = async () => {
         // 将表格中的字段和数据库中的必填字段和非必填的映射关系传给后端
        try {
            const values = await form.validateFields();
            const data1 = {
                ...query,
                file: file,
                mapResult: JSON.stringify(values)
            }
            setLoding(true)
            setData1(data1)
            importCusTable(data1).then(res => {
                setLoding(false)
                if (res.code == '302') {
                    let arr = `${res.msg}`.split('-')
                    
                    setDeletionTime([arr[0], arr[1]])
                    setIsTimeModalOpen(true)
                } else if (res.code != 200) {
                    message.error(res.msg,2)
                } else {
                    onCancel(file.name)
                    message.success(res.msg,2)
                }
            })

        } catch (errorInfo) {
            
        }
    };
    const onCancelT = (name1) => {
        setIsTimeModalOpen(false)
        // 点击缺失日期的确认或者取消按钮时，自定义配置弹框消失
        setTimeout(() => {
            onCancel(name1 !== '-1' && name1 !== '取消'&&name1 !== ''?name1:'') 
        })
    }
    return (
        <Modal width={900} open={visible} title={
            <div className={style.title}>
                <div><span>自定义配置({name})</span></div>
                <div style={{ fontSize: '14px' }}>
                    <span>存在无法匹配的必填项字段？</span>
                    <span
                        className={style.downloadStyle}
                        onClick={() => importTemplateT({
                            moduleId: allModuled.filter(ele => ele.sheetName == name)[0].moduleId
                        }, `${name}模板.xlsx`)}
                    >下载模板</span>
                </div>
            </div>}
            onCancel={()=>onCancel('取消')}
            footer={
                <>
                    <Button onClick={()=>onCancel('取消')} type="ghost">取消</Button>
                    <Button onClick={onCheck} loading={loaing} type="primary">确定</Button>
                </>
            }
            maskClosable={false}
        >
            <div>
                <div style={{ height: '420px', overflow: 'auto' }}>
                    <div className={style.head}>
                        <div>数据库字段</div>
                        <div>表格字段</div>
                    </div>
                    <Form
                        name="wrap"
                        labelCol={{
                            flex: '200px',
                        }}
                        wrapperCol={{
                            flex: '240px',
                        }}
                        colon={false}
                        form={form}
                    >
                        {
                            allField?.map((item, index) => (
                                <Form.Item
                                    label={item.fieldName}
                                    name={item.fieldName}
                                    rules={[
                                        {
                                            required: item.isNeed == 1 ? true : false,
                                            message: '必填项不能为空'
                                        },
                                    ]}
                                    style={{ marginLeft: '56px' }}
                                    key={index}
                                >
                                    <Select style={{ marginLeft: '260px' }} size='small' options={options} onChange={handleChange} allowClear={true}/>
                                </Form.Item>
                            ))
                        }
                    </Form>
                </div>
            </div>
            <TimeModal 
                visible={isTimeModalOpen} 
                deletionTime={deletionTime} 
                onCancel={onCancelT}
                data1={data1}
                file={file}
            />
        </Modal>

    )
}
