/* eslint-disable no-undef */
import React,{useState,useEffect} from 'react'
import { Tabs,message } from 'antd';
import ReplenishmentConfig from './ReplenishmentConfig'
import ReprintConfig from './ReprintConfig'
import ChannelConfig from './ChannelConfig'
import { getDateSource } from '../../../api/config';
import { getALLQuery } from '../../../utils';
const TabPane = Tabs.TabPane;
export default function SystemConfig({change}) {
  const [ tabKey , setTabKey ] = useState('0')
  const [ isShow , setIsShow ] = useState(true)
  const userInfo = getALLQuery()

  useEffect(() => {
    // 获取数据方式是数据导入还是数据对接
    getDateSource({publiId:userInfo.publiId}).then(res=>{
        
        if(res.code == 200){
            setIsShow(res.data.dataType)
        }else{
          message.error(res.msg,2)
        }
    })
}, []);

  function handleTabChange(key){
    setTabKey(key)
    _maq.trigger(['_trackEvent', key == '0' ? "重印配置" : (key == '1' ? "补货配置" : '渠道配置')]); 
  }
  return (
    <div style={{marginLeft:'40px'}}>
      <Tabs activeKey={tabKey} onChange={handleTabChange}  tabBarGutter='100px' destroyInactiveTabPane>
            <TabPane tab="重印配置" key="0" >
              <div><ReprintConfig change={change}/></div>
            </TabPane>
            <TabPane tab="补货配置" key="1" >
              <div><ReplenishmentConfig change={change}/></div>
            </TabPane>
            {
            isShow == '1' 
            && <TabPane tab="渠道配置" key="2" >
                    <div><ChannelConfig /></div>
            </TabPane>
          }
        </Tabs>
    </div>
  )
}
