/* eslint-disable no-undef */
import React, { useState } from 'react';
import DataImport from './DataImport'
import ReprintSupervise from './ReprintSupervise'
import ReplenishmentSupervise from './ReplenishmentSupervise'
import SystemConfig from './SystemConfig'
import VarietyConfig from './VarietyConfig';
// import SystemConfig from './systemConfig'
// import ReprintSupervise from './ReprintSupervise'
// import ReplenishmentSupevise from './ReplenishmentSupevise'
import style from './ReprintReplenishment.module.css'
// import BreedMaintain from './BreedMaintain'     
import { Button, Tabs } from 'antd';
import { debounceT } from '../../utils/debounceT';
const TabPane = Tabs.TabPane;

export default function ReprintReplenishment() {

    const [tabKey, setTabKey] = useState('1')
    const [levelInfo,setLevelInfo] = useState()
     // 双击重印配置或者补货配置中的任何一个自定义分类跳转到重印维护页或者补货维护页，
    function handleTabChange(key,levelInfo) {
        // levelInfo为undefined时，说明不是通过双击重印配置或者补货配置中的任何一个自定义分类进行跳转tabs      
        setTabKey(key)
        setLevelInfo(levelInfo)
        let keyArr = ['补货预测', '重印预测', "数据导入", "系统配置" , "品种维护"]
        _maq.trigger(['_trackEvent', keyArr[key]]); 
    }
    return (

        <div className={style.container}>
            <div className={style.main}>
                <Tabs activeKey={tabKey} onChange={debounceT(handleTabChange)} tabBarGutter='100px' destroyInactiveTabPane>
                    <TabPane tab="重印预测" key="1" >
                        <div><ReprintSupervise /></div>
                    </TabPane>
                    <TabPane tab="补货预测" key="0" >
                        <div><ReplenishmentSupervise /></div>
                    </TabPane>
                    <TabPane tab="数据导入" key="2" >
                        <div className={style.content}><DataImport /></div>
                    </TabPane>
                    <TabPane tab="系统配置" key="3" >
                        <div className={style.content} ><SystemConfig change={handleTabChange}/></div>
                    </TabPane>
                    <TabPane tab="品种维护" key="4" >
                        <VarietyConfig levelInfo={levelInfo}/>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}