// /* 对于axios进行二次封装 */
import axios from "axios"
//1:利用axios对象的方法create，去创建一个axios实例
//2:request就是axios，我们进行一下配置
const requests = axios.create({
    //配置对象
    //基础路径，发送请求的时候，路径当中会出现api
    baseURL: "/api",
    //配置请求超时时间
    timeout: 30000000,
});
//请求拦截器：再发请求指示之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
//interceptors拦截器
requests.interceptors.request.use((config) => {
    //config:配置对象，对象里面有一个属性很重要，header请求头    
    return config;
});
//响应拦截器
requests.interceptors.response.use((res) => {
    //成功的回调函数：服务器响应数据回来以后，响应拦截器可以检测到，可以做一些事情
    return res.data;
}, (error) => {
    console.log(error, '请求错无')
    //响应失败的回调函数
    return Promise.reject(new Error('faile'));
}
)

const build = (url, params) => { // URL构建方法
    const ps = []
    if (params) {
        for (let p in params) {
            if (p) {
                ps.push(p + '=' + encodeURIComponent(params[p]));
            }
        }
    }
    return url + '?' + ps.join('&')
}
const exportFile = (url,params) => {
    return axios({ // 用axios发送post请求
        method: "get",
        url: build(url,params), // 请求地址
        responseType: "blob", // 表明返回服务器返回的数据类型
        headers: {
            "Content-Type": "application/json"
        }
    })
}


function exportFilePost(url,params){
    return axios({ // 用axios发送post请求
        method: "post",
        data:JSON.stringify(params),
        url: url, // 请求地址
        responseType: "blob", // 表明返回服务器返回的数据类型
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export {
    exportFile,
    exportFilePost,
    requests
}