/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import style from './index.module.css'
import { Button, Upload, message, DatePicker } from 'antd';
import InCustomCol from '../../../../components/InCustomCol';
import { ExcelRenderer } from 'react-excel-renderer';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TimeModal from '../../../../components/TimeModal'
import { databaseField, importLastTime, getAllModuled, importTemplate } from '../../../../api/importData'
import moment from 'moment';
import { getALLQuery } from '../../../../utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import { downFile } from '../../../../utils'
const { RangePicker } = DatePicker;

export default function DataImport({isSwitch}) {
    const [name, setName] = useState('')
    const [time, setTime] = useState()
    const [timestr, setTimestr] = useState()
    const [isOpenModal, setIsOpenModal] = useState(false) // 控制自定义配置弹框是否打开
    const [userColumn, setUserColumn] = useState([])// 用户导入得excel表得所有字段
    const arr = [{name:'库存数据',module:'kc'}, {name:'品种数据',module:'pz'}]
    const [arrNeed, setArrNeed] = useState([]) // 内部数据中必填字段
    const [allField, setAllField] = useState() // 数据库字段（必填+非必填）
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false); // 控制时间提示弹框是否展示
    const [lastTime, setLastTime] = useState({ kc: '', dd: '', pz: '' })
    const [file, setFile] = useState([])
    const [allModuled, setAllModuled] = useState([]) // 全部模块ID。
    const [deletionTime, setDeletionTime] = useState('') // 设置缺失日期时间
    const [ignore, setIgnore] = useState('0') // 是否忽略缺失时间-库存
    const [query, setQuery] = useState({}) // 是否要校验库存时间
    const [module, setModule] = useState()// 储存每种数据的moduleId,如点击了 书目信息数据 的导入按钮后，就会获得 书目信息数据 对应的唯一标识pz
    const [moduleIndex, setModuleIndex] = useState()// 每种数据对应标识的位置，如pz在allMoudled中的位置是1

    const userInfo = getALLQuery()

    // 获取上一次导入数据截止日期
    // 获取全部模块ID
    useEffect(() => {
        getLastTime();
    }, []);
    useEffect(() => {
        getAllModuled().then(res => {
            let module = res.rows.map(item => {
                return {
                    sheetName: item.sheetName,
                    moduleId: item.moduleId,
                    fileName: '' // 当文件上传成功时，才会在导入按钮下面显示对应的文件名字，一旦失败或者切换渠道。文件名字都会消失
                }
            })
            setAllModuled(module)
        })
        setTime()
    }, [isSwitch]);

    const getLastTime = () => {
        importLastTime(userInfo.publiId).then(res => {
            if (res.code == 200) {
                setLastTime(res.data)
            }
        })
    }

    // 点击导入的相关操作
    async function handleClick(item) {
        let singleModule = allModuled.filter(ele => ele.sheetName == item)[0].moduleId
        let index = allModuled.findIndex(item => item.moduleId === singleModule)
        setModule(singleModule)
        setModuleIndex(index)
        let obj = {}
        setName(item)
        let moduleResult = allModuled.filter(ele => ele.sheetName == item)
        await databaseField(moduleResult[0]?.moduleId).then(res => {
            let needField = res.rows.filter((item) => {
                return item.isNeed == 1
            }).map((item) => {
                return item.fieldName
            })

            let DatabaseAllField = res.rows.map((item) => {
                return {
                    fieldId: item.fieldId,
                    fieldName: item.fieldName,
                    isNeed: item.isNeed
                }
            })
            setArrNeed(needField)
            setAllField(DatabaseAllField)
            for (var i = 0; i < needField.length; i++) {
                obj[needField[i]] = needField[i]
            }
        })
        if (singleModule == 'kc') {
            setQuery({
                userId: userInfo.userId,
                moduleId: singleModule,
                publiId: userInfo.publiId,
                businessDate: timestr ? timestr : '',
                lastDate: singleModule == 'kc' ? (lastTime && lastTime.kc ? lastTime.kc : '2023-01-01') : (lastTime && lastTime.pz ? lastTime.pz : '2023-01-01'),
                ignore: !!lastTime.kc ? ignore : '1',
            })
        } else if (singleModule == 'pz') {
            setQuery({
                userId: userInfo.userId,
                moduleId: singleModule,
                publiId: userInfo.publiId,
                lastDate: singleModule == 'kc' ? (lastTime && lastTime.kc ? lastTime.kc : '2023-01-01') : (lastTime && lastTime.pz ? lastTime.pz : '2023-01-01'),
                ignore: !!lastTime.pz ? '0' : '1',
            })
        }
    }

    const handleChange = (info) => {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
            if (info.file.response.code == 302) {
                setIsTimeModalOpen(true)
                message.error(info.file.response.msg, 3)
            } else if (info.file.response.code != 200) {
                message.error(info.file.response.msg, 3)
                _maq.trigger(['_trackEvent', `导入${module == 'kc' ? '库存' : '书目信息'}文件失败`]);
                allModuled[moduleIndex].fileName = ''
                setAllModuled([...allModuled])
            } else {
                setFile([info])
                getLastTime();
                allModuled[moduleIndex].fileName = info.file.name
                setAllModuled([...allModuled])
                message.success(!!info.file.response.msg ? (info.file.response.msg == 500 ? '导入成功' : info.file.response.msg) : '导入成功', 3);
                _maq.trigger(['_trackEvent', `导入${module == 'kc' ? '库存' : '书目信息'}成功`]);
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败`, 3);
            allModuled[moduleIndex].fileName = ''
            setAllModuled([...allModuled])
            _maq.trigger(['_trackEvent', `导入${module == 'kc' ? '库存' : '书目信息'}文件失败`]);
        }
    };
    const handleBeforeUpload = async (info) => {
        let isMatch = true
        let fileObj = info
        // 当上传文件为.csv的文件时，相关操作如下
        if ((info.name).indexOf('.csv') > -1) {
            const reader = new FileReader();
            // 将上传的文件读取为文本
            reader.readAsText(info, 'utf-8');
            reader.onload = async function () {
                var data = reader.result;
                const json = await csvToJson().fromString(data);
                let field = Object.keys(json[0])
                for (var i = 0; i < arrNeed.length; i++) {
                    // 但凡要求的必填字段中有一个不存在于csv文件中，就需要弹出自定义配置对话框
                    if (field.indexOf(arrNeed[i]) == -1) {   // 结果只要有一个满足为-1，for循环停止，弹出自定义配置Modal
                        setIsOpenModal(true)
                        message.error(`${info.name} 文件导入失败，请先进行自定义配置`, 3);
                        setMatch(false)
                        setUserColumn(field)
                        break;
                    }
                }
            };
        } else {
            // 如果文件是 .xlsx文件或者 .xls文件时。相关操作如下
            if ((info.name).indexOf('.xlsx') > -1 || (info.name).indexOf('.xls') > -1) {
                await ExcelRenderer(fileObj, (err, resp) => {
                    if (err) {
                        
                    }
                    else {
                        setUserColumn(resp.rows[0])
                        // 下面if else 作用是，防止有的excel表的第一行是一个标题，第二行才是各个字段名
                        if (resp.rows[0].length == 1) {
                            for (var i = 0; i < arrNeed.length; i++) {
                                // 当导入的文件第一行为大标题的时候，我们需要判断第二行所有字段中是否都有导入时要求的必填字段
                                if (resp.rows[1].indexOf(arrNeed[i]) == -1) {   // 结果只要有一个满足为-1，for循环停止，弹出自定义配置Modal
                                    message.error(`${info.name} 文件导入失败，请先进行自定义配置`, 3);
                                    isMatch = false
                                    setUserColumn(resp.rows[1])
                                    setIsOpenModal(true)
                                    break;
                                }
                            }
                        }
                        else {
                            // 当导入的文件第一行不是大标题，即第一行就是该文件所包含的所有字段
                            for (let i = 0; i < arrNeed.length; i++) {
                                if (resp.rows[0].indexOf(arrNeed[i]) == -1) {
                                    message.error(`${info.name} 文件导入失败，请先进行自定义配置`, 3);
                                    isMatch = false
                                    setIsOpenModal(true)
                                    break;
                                }
                            }
                        }
                    }
                });
            } else {
                message.warning('导入的文件格式不正确！', 3)
            }
        }
        setFile([info]) // 获取文件流传给子组件
        return isMatch || Upload.LIST_IGNORE;
    };

    const uprops = {
        name: 'file',
        action: '/api/importFieldMapping/importFile',
        headers: {
            authorization: 'authorization-text',
        },
        data: query,
        multiple: true,
        onChange: handleChange,
        beforeUpload: handleBeforeUpload
    };
    // 时间选择范围相关操作   
    const handleTime = (time, timestr) => {
        if (!!lastTime.kc && moment(time).diff(moment(lastTime.kc), 'days') > 1) {
            if (moment(time).diff(moment(lastTime.kc), 'days') == 2) {
                setDeletionTime(moment(lastTime.kc).add('1', 'days').format('YYYY-MM-DD'))
            } 
            else {
                setDeletionTime([timestr, moment(lastTime.kc).add('1', 'days').format('YYYY-MM-DD')])
            }

            setIsTimeModalOpen(true)
        }
        setTime(time)
        setTimestr(timestr)
    };
    // 关闭莫太快，缺失日期对话框，TimeModal 点击取消时，操作如下 
    const onCancel = (val) => {
        console.log(val);
        if (val == '0') {
            handleTime(undefined, undefined)
        }
        if (val == '取消') {
            setTime()
        }
        setIgnore(val);
        setIsTimeModalOpen(false)
    }
    // 下载模板
    const handleDownTemp = (name) => {
        importTemplate({'moduleId': name == '库存数据' ? 'kc' : 'pz'}).then((res) => {
            message.success('请耐心等待下载完成',2)
            downFile(res)
        })
    }
    return (
        <>
            <div style={{ marginTop: '40px', paddingBottom: '300px' }}>
                {
                    arr.map((item,index)=>{return (
                        <div key={index}>
                            <div style={{ color: '#808080' }}>
                                {
                                    !!lastTime[item.module] ? (
                                        <>导入{item.name == '库存数据'?item.name:'书目信息数据'}的截至日期为{lastTime[item.module]}，本次建议导入<span style={{ color: '#1A76D2' }}>{moment(lastTime[item.module]).add(1, 'day').format('YYYY-MM-DD')}开始</span>的数据</>
                                    ) : `当前未导入${item.name == '库存数据'?item.name:'书目信息数据'}`
                                }
                            </div>
                            <div className={style.contentStyle}>
                                <span className={style.spanStyle}>{item.name == '库存数据'?item.name:'书目信息数据'}</span>
                                <div style={{
                                    width:'110px',display:'flex',flexWrap:'wrap'
                                }}>
                                    <Upload
                                        accept='.xls,.xlsx'
                                        maxCount={1}
                                        {...uprops}
                                        showUploadList={false}
                                    >
                                        <Button type="primary" ghost style={{ width: '110px' }} onClick={() => handleClick(item.name)} disabled={time == undefined&&item.name=='库存数据' ? true : false}>导入</Button>
                                    </Upload>
                                    {
                                        allModuled.length>0&&allModuled.filter(ele=>ele.sheetName == item.name)[0].fileName !== '' ? 
                                            <div style={{marginTop:'10px'}}>
                                                <CheckCircleOutlined style={{color:'#54BCBD',marginRight:'10px'}}/>
                                                <span style={{color:'#A9A9A9'}}>{allModuled.filter(ele=>ele.sheetName == item.name)[0].fileName}</span>
                                            </div> : null
                                    }
                                </div>
                                {
                                    item.name == '库存数据'?
                                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '22px' }}>
                                            <DatePicker value={time} onChange={handleTime} style={{ marginLeft: '20px', marginTop: '12px' }} />
                                            <div className={style.timeIcon}>
                                                <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px' }} />
                                                <span>请选择数据的日期后再导入</span>
                                            </div>
                                        </div>:null
                                }
                                <Button type='link' onClick={() => handleDownTemp(item.name)}>下载模板</Button>
                            </div>
                        </div>
                    )})
                }
            </div>
            <InCustomCol
                visible={isOpenModal}
                name={name}
                onCancel={(name) => {
                    if(allModuled.length>0){
                        allModuled[moduleIndex].fileName = name
                    }
                    setAllModuled([...allModuled])
                    getLastTime();
                    setIsOpenModal(false)
                }}
                userColumn={userColumn}
                allField={allField}
                file={file}
                allModuled={allModuled}
                query={query}
            />
            <TimeModal
                visible={isTimeModalOpen}
                isPost={false}
                deletionTime={deletionTime}
                onCancel={(val) => onCancel(val)}
            />
        </>
    )
}
