/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Button, Radio, Upload, message, DatePicker } from 'antd';
import style from './index.module.css'
import OutCustomCol from '../../../../components/OutCustomCol';
import { ExcelRenderer } from 'react-excel-renderer';
import { ExclamationCircleFilled ,CheckCircleOutlined} from '@ant-design/icons';
import { databaseField, importLastTime, getAllModuled, getAllChannel, importTemplate } from '../../../../api/importData'
import TimeModal from '../../../../components/TimeModal'
import { getALLQuery, downFile } from '../../../../utils';
import csvToJson from 'csvtojson';
import moment from 'moment';
const { RangePicker } = DatePicker;


export default function ExternalImport({isSwitch}) {
    const [value, setValue] = useState(); // 控制默认渲染的页面
    const [isOpenModal, setIsOpenModal] = useState(false) // 控制自定义配置弹框是否打开
    const [userColumn, setUserColumn] = useState([]) // 用户导入得excel表得所有字段
    const [name, setName] = useState('') // Modal弹出框的标题是需要动态变化的
    const [time, setTime] = useState()
    const [time1, setTime1] = useState()
    // 后端返回的每个渠道下每种数据导入时必须包含的必填字段，如果导入的数据对应的表格没有全部包括这些必填字段，则弹出自定义配置对话框
    const [arrNeed, setArrNeed] = useState([]) 
    const [allField, setAllField] = useState() // 数据库字段（必填+非必填）
    const [lastTime, setLastTime] = useState() // 保存每种渠道每种数据的上次导入的时间
    const [allModuled, setAllModuled] = useState([]) // 全部模块ID。
    const [timestr, setTimestr] = useState()
    // const [mapResult, setMapResult] = useState()
    const [file, setFile] = useState() // 储存导入的文件的信息
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false) // 缺失时间弹窗
    const [query, setQuery] = useState({}) // 调用通用上传表格接口的时候，作为携带的参数
    const [deletionTime, setDeletionTime] = useState('') // 设置缺失日期时间
    const [ignore, setIgnore] = useState('0') // 设置是否跳过校验
    const [ismatch, setMatch] = useState(true) // 设置isMatch是当导入的表格的字段无法完全匹配必填字段的时候，阻止默认上传行为
    const [module, setModule] = useState() // 储存每种数据的moduleId,如点击了 京东库存数据 的导入按钮后，就会获得 京东库存数据 对应的唯一标识jd_kc
    const arrList = [{ name: '京东库存数据', id: 'jd_kc' }, { name: '京东销售数据', id: 'jd_xs' }]
    const [moduleIndex, setModuleIndex] = useState() // 每种数据对应标识的位置，如jd_xs在allMoudled中的位置是4
    const fileProps = {
        name: 'file',
        action: '/api/importFieldMapping/importFile',
        showUploadList:false,
        headers: {
            authorization: 'authorization-text',
        },
    }
    const [channelList, setChannelList] = useState([]) // 渲染后端返回的所有打开的渠道
    const [renderList, setRenderList] = useState() // 进行所有渠道下方渲染
    const [isPost, setIsPost] = useState(true)
    const [perChannel, setPerChannel] = useState() // 看61行注释
    const userInfo = getALLQuery()

    useEffect(() => {
        getLastTime();
        // 获取所有渠道      
        getAllChannel({ publiId: userInfo.publiId }).then(res => {
            let channelList = res.rows.filter(item => item.isOpen == '1')
            setValue(channelList[0].channel)
            console.log(channelList);
            // 若，后端返回的数组的第一个元素为凤凰渠道，channelList[0].channel为"fh4"，但在renderList中凤凰的标识为fh4_xs和fh4_kc,索引需要先储存fh4,再分别渲染销售和库存渲染
            setPerChannel(channelList[0].channel) 
            setChannelList([...channelList])    
            setRenderList([...channelList])   
        })
    }, []);
    useEffect(() => {
        getAllModuled().then(res => {
            let module = res.rows.map(item => {
                return {
                    sheetName: item.sheetName,
                    moduleId: item.moduleId,
                    fileName: '' // 6.8
                }
            })
            setAllModuled(module)
        })
        setValue(channelList[0]?.channel)
    }, [isSwitch]);

    // 切换渠道时，操作如下
    function handleChange(e) {
        setValue(e.target.value);
        setTimestr(undefined);
        setTime1(undefined)
        setTime(undefined)
        setFile([]); // 切换的时候应该清空文件列表   
        allModuled.forEach(item=>item.fileName = '')
        setAllModuled([...allModuled])

        let filterList = channelList.filter(item => item.channel == e.target.value)
        setPerChannel(filterList[0].channel)
        setRenderList([...filterList])
    }
    // 点击导入按钮时，操作如下
    async function handleClick(item) {
        console.log(allModuled);
        let singleModule = allModuled.filter(ele => ele.sheetName == item)[0].moduleId
        setModule(singleModule)
        setName(item)
        let moduleResult = allModuled.filter(ele => ele.sheetName == item)
        await databaseField(moduleResult[0].moduleId).then(res => {
            let resData = [...res.rows].map((item) => {
                if (`${renderList[0].channel}`.indexOf('qt') != -1) { // 其他渠道要做特殊处理
                    // 要满足该字段为必填， 标中有销售日期， 这个字段不是
                    if (renderList[0].isSaleDate != 1 && item.fieldName == '销售日期') {
                        item.isNeed = 0
                    } 
                }
                return item
            })
            // 筛选出数据库要求的必填字段
            let needField = resData.filter((item) => item.isNeed == 1).map((item) => item.fieldName)
            // 数据库必填+非必填
            let DatabaseAllField = resData.map((item) => {
                return {
                    fieldId: item.fieldId,
                    fieldName: item.fieldName,
                    isNeed: item.isNeed
                }
            })
            setArrNeed(needField)  
            setAllField(DatabaseAllField) // 
            let obj = {}
            for (var i = 0; i < needField.length; i++) {
                obj[needField[i]] = needField[i]
            }
            // setMapResult(obj)
            let data = {
                userId: userInfo.userId,
                moduleId: singleModule,
                publiId: userInfo.publiId,
                channel: item.indexOf('京东') == -1 && item.indexOf('当当') == -1 ? renderList[0].channel : '' // 京东和当当不传channel         
            }
            if (singleModule == 'jd_xs') {
                setQuery({
                    ...data,
                    lastDate: lastTime && lastTime.jd_xs ? lastTime.jd_xs : '2023-01-01',
                    ignore: !!lastTime.jd_xs ? '0' : '1',
                })
            } else if (singleModule == 'jd_kc') {
                setQuery({
                    ...data,
                    lastDate: lastTime && lastTime.jd_kc ? lastTime.jd_kc : '2023-01-01',
                    ignore: !!lastTime.jd_kc ? '0' : '1'
                })
            } else {
                console.log(renderList[0].isMerge);
                if (renderList[0].isMerge == '1') {
                    let query = {
                        ...data,
                        startDate: !!timestr ? timestr[0] : '',
                        endDate: !!timestr ? timestr[1] : '',
                        lastDate: lastTime && lastTime[perChannel] ? lastTime[perChannel] : '2023-01-01',
                        ignore: !!lastTime[perChannel] ? ignore : '1',
                    }
                    if (item == '当当数据') {
                        setQuery({ ...query })
                    } else {
                        setQuery({
                            ...query,
                            isMerge: '1'
                        })
                    }
                } else {
                    if (item.indexOf('库存') > -1) {
                        setQuery({
                            ...data,
                            lastDate: lastTime && lastTime[perChannel+'_kc'] ? lastTime[perChannel+'_kc'] : '2023-01-01',
                            ignore: !!lastTime[perChannel+'_kc'] ? '0' : '1',
                            isMerge: '0',
                            startDate: item == '其他2库存数据' && timestr ? timestr : '',
                            endDate: item == '其他2库存数据' && timestr ? timestr : '',
                        })
                    } else {
                        setQuery({
                            ...data,
                            lastDate: lastTime && lastTime[perChannel+'_xs'] ? lastTime[perChannel+'_xs'] : '2023-01-01',
                            ignore: !!lastTime[perChannel+'_xs'] ? '0' : '1',
                            isMerge: '0',
                            startDate:timestr ? timestr[0] : '',
                            endDate:  timestr ? timestr[1] : '',
                            // startDate: item == '凤凰销售数据' && timestr ? timestr[0] : '',
                            // endDate: item == '凤凰销售数据' && timestr ? timestr[1] : '',
                        })
                    }
                }
            }
        })
    }

    const handleChangeUpload = (info) => {
        console.log(allModuled);
        if (ismatch == false) {
            return false;
        }
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
            if (info.file.response.code == 302) {
                setIsTimeModalOpen(true)
                setIsPost(false)
                let arr = `${info.file.response.msg}`.split('-');
                arr.filter(item => !!item);
                setDeletionTime(arr.length == 1 ? arr[0] : [arr[0], arr[1]])
                _maq.trigger(['_trackEvent', `导入${allModuled[moduleIndex]?.sheetName}文件失败`]);
            } else if (info.file.response.code != 200) {
                message.error(info.file.response.msg, 3)
                allModuled[moduleIndex].fileName = ''
                setAllModuled([...allModuled])
                _maq.trigger(['_trackEvent', `导入${allModuled[moduleIndex]?.sheetName}文件失败`]);
            } else {
                getLastTime()
                allModuled[moduleIndex].fileName = info.file.name
                setAllModuled([...allModuled])
                message.success(!!info.file.response.msg ? info.file.response.msg : '导入成功', 3);
                _maq.trigger(['_trackEvent', `导入${allModuled[moduleIndex]?.sheetName}文件成功`]);
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败`, 3);
            allModuled[moduleIndex].fileName = ''
            setAllModuled([...allModuled])
        }
    };

    const handleBeforeUpload = async (info) => {
        // 点击导入获得该数据在 allModuled 中的位置，如jd_xs对应的位置是4
        let index = allModuled.findIndex(item => item.moduleId == module) 
        setModuleIndex(index)
        setFile(info) // 获取文件流传给子组件    
        let isMatch = true // 设置isMatch是当导入excel字段无法完全匹配必填字段的时候，阻止默认上传行为
        let fileObj = info
        // 当当的数据都是csv文件,所以处理方法只能用csvToJson ,注意导入的csv文件不能改名字      
        if ((info.name).indexOf('.csv') > -1) {
            const reader = new FileReader();
            // 将上传的文件读取为文本
            reader.readAsText(info, 'utf-8');
            reader.onload = async function () {
                var data = reader.result;
                const json = await csvToJson().fromString(data);
                let field = Object.keys(json[0])
                for (var i = 0; i < arrNeed.length; i++) {
                    // 但凡要求的必填字段中有一个不存在于csv文件中，就需要弹出自定义配置对话框
                    if (field.indexOf(arrNeed[i]) == -1) {   // 结果只要有一个满足为-1，for循环停止，弹出自定义配置Modal
                        setIsOpenModal(true)
                        message.error(`${info.name} 文件导入失败，请先进行自定义配置`, 3);
                        setMatch(false)
                        setUserColumn(field)
                        break;
                    }
                }
            };
        } else {
            // 如果文件是 .xlsx文件或者 .xls文件时。相关操作如下
            if ((info.name).indexOf('.xlsx') > -1 || (info.name).indexOf('.xls') > -1) {
                await ExcelRenderer(fileObj, (err, resp) => {
                    if (err) {
                        
                    }
                    else {
                        // 当导入的文件第一行为大标题的时候，我们需要判断第二行所有字段中是否都有导入时要求的必填字段        
                        if (resp.rows[0].length == 1) {
                            for (var i = 0; i < arrNeed.length; i++) {
                                if (resp.rows[1].indexOf(arrNeed[i]) == -1) {   // 结果只要有一个满足为-1，for循环停止，弹出自定义配置Modal
                                    setIsOpenModal(true)
                                    message.error(`${info.name} 文件导入失败，请先进行自定义配置`, 3);
                                    isMatch = false
                                    setUserColumn(resp.rows[1])
                                    break;
                                }
                            }
                        }
                        // 当导入的文件第一行不是大标题，即第一行就是该文件所包含的所有字段
                        else {
                            for (let i = 0; i < arrNeed.length; i++) {
                                if (resp.rows[0].indexOf(arrNeed[i]) == -1) {
                                    message.error(`${info.name} 文件导入失败，请先进行自定义配置`, 3);
                                    setIsOpenModal(true)
                                    isMatch = false
                                    setUserColumn(resp.rows[0])
                                    break;
                                }
                            }
                        }
                    }
                });
            } else {
                message.warning('导入的文件格式不正确！', 3)
            }
        }
        return isMatch || Upload.LIST_IGNORE;
    }

    const uprops = {
        ...fileProps,
        data: query,
        onChange: handleChangeUpload,
        beforeUpload: handleBeforeUpload
    };

    // 控制导入按钮必须在选完时间范围后才能点击      
    const handleTime = (time, timestr) => {
        let lastTimeT = renderList[0].isMerge == '1' ? lastTime&&lastTime[perChannel] : lastTime&&lastTime[perChannel+'_kc']
        let useTime = renderList[0].isMerge == '1' ? moment(timestr[0]) : moment(timestr)
        let useTimeStr = renderList[0].isMerge == '1' ? timestr[0] : timestr
        if (!!lastTimeT && useTime.diff(lastTimeT, 'days') > 1) {
            if (useTime.diff(lastTimeT, 'days') == 2) {
                setDeletionTime(moment(lastTimeT).add('1', 'days').format('YYYY-MM-DD'))
            } else {
                setDeletionTime([useTimeStr, moment(lastTimeT).add('1', 'days').format('YYYY-MM-DD')])
            }
            setIsTimeModalOpen(true)
            setIsPost(false)
        }
        setTime(time)
        setTimestr(timestr)
    };
    const handleTime1 = (time1, timestr) => {
        let lastTimeT = lastTime[perChannel+'_xs']
        if (!!lastTimeT && moment(timestr[0]).diff(lastTimeT, 'days') > 1) {
            if (moment(timestr[0]).diff(lastTimeT, 'days') == 2) {
                setDeletionTime(moment(lastTimeT).add('1', 'days').format('YYYY-MM-DD'))
            } else {
                setDeletionTime([timestr[0], moment(lastTimeT).add('1', 'days').format('YYYY-MM-DD')])
            }
            setIsTimeModalOpen(true)
            setIsPost(false)
        }
        setTime1(time1)
        setTimestr(timestr)
    };
    // 获取上一次导入数据截止日期    
    const getLastTime = () => {
        importLastTime(userInfo.publiId).then(res => {
            
            setLastTime(res.data);
            setIgnore(0); // 忽视时间校验上传成功后重新开启时间校验   
        })
    }
    const handleDisabled = (data, type) => {
        // type 为1，则是对当当，文轩，博库，其它1这四个渠道进行操作，type为2，则是对凤凰，其它2这两个渠道进行操作
        let isDisabled = true //针对当当，文轩，博库，其它1
        let isDisabled1 = true // 针对凤凰和其它2
        if (type == '1') {
            if (time == undefined) {
                if (data.channelName == '博库') {
                    isDisabled = false
                }
                if (data.channelName == '其他1' && data.isSaleDate == '1') {
                    isDisabled = false
                }
            }
            else {
                isDisabled = false
            }
            return isDisabled
        } else {
            
            if (time1) {
                isDisabled1 = false
            } else {
                isDisabled1 = renderList[0].isSaleDate == '1' ? false : true
            }
            return isDisabled1
        }
    }

    const handleDownTemp = (name) => {
        console.log(name, '模块id')
        let singleModule = allModuled.filter(ele => ele.sheetName == name)[0].moduleId
        importTemplate({'moduleId': singleModule }).then((res) => {
            message.success('请耐心等待下载完成',2)
            downFile(res)
        })
    }
    return (
        <>  
            {
                renderList?.length>0?
                <div style={{ marginTop: '40px' }}>
                <div style={{ marginBottom: '40px' }}>
                    <Radio.Group onChange={handleChange} value={value} >
                        {
                            channelList.map(item => (
                                <Radio value={item.channel} style={{ marginRight: '50px' }} key={item.channel}>{item.channelName}</Radio>
                            ))
                        }
                    </Radio.Group>
                </div>

                {value === 'jd' ?
                    <>
                        {
                            arrList.map((item, index) => {
                                let time = lastTime ? lastTime[item.id] : undefined
                                return (
                                    <div key={index}>
                                        <div style={{ color: '#808080' }}>
                                            {
                                                time
                                                    ? <p>导入{item.name.slice(2,6)}的截至日期为{time}，本次建议导入<span style={{ color: '#1A76D2' }}>{moment(time).add('1', 'day').format('YYYY-MM-DD')}开始</span>的数据</p>
                                                    : `当前未导入${item.name}`
                                            }
                                        </div>
                                        <div className={style.contentStyle} key='2'>
                                            <span className={style.spanStyle}>{item.name}</span>
                                            <div style={{
                                                width:'200px',display:'flex',flexWrap:'wrap'
                                            }}>
                                                <Upload {...uprops} accept='.xls,.xlsx' maxCount={1} >
                                                    <Button type="primary" ghost style={{ width: '110px' }} onClick={() => handleClick(`${item.name}`)}>导入</Button>
                                                </Upload>
                                                <Button type="link" onClick={() => handleDownTemp(`${item.name}`)}>下载模板</Button>
                                                {
                                                    allModuled.length>0 && allModuled.filter(ele=>ele.sheetName == item.name)[0].fileName !== '' ? 
                                                    <div style={{marginTop:'10px'}}>
                                                        <CheckCircleOutlined style={{color:'#54BCBD',marginRight:'10px'}}/>
                                                        <span style={{color:'#A9A9A9'}}>{allModuled.filter(ele=>ele.sheetName == item.name)[0].fileName}</span>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </> :
                    <>
                        {
                            renderList&&renderList[0].isMerge == '1' ? <>
                                <div style={{ color: '#808080' }}>
                                    {
                                        lastTime&&!!lastTime[perChannel]
                                            ? <p>导入销售及库存日期截至到{lastTime[perChannel]}，本次建议导入
                                                <span style={{ color: '#1A76D2' }}>{moment(lastTime[perChannel]).add('1', 'days').format('YYYY-MM-DD')}开始</span>的数据
                                            </p>
                                            : '当前未导入' + renderList[0].channelName + '销售及库存数据'
                                    }
                                    <div className={style.contentDDStyle}>
                                        <span className={style.spanStyle}>销售及库存数据</span>
                                        <div style={{
                                            width:'110px',display:'flex',flexWrap:'wrap'
                                        }}>
                                            <Upload {...uprops} accept='.xls,.xlsx,.csv' maxCount={1}>
                                                <Button type="primary" ghost style={{ width: '110px' }}
                                                    onClick={() => handleClick(renderList[0].channelName + '数据')}
                                                    disabled={handleDisabled(renderList[0], '1')}
                                                >导入
                                                </Button>
                                            </Upload>
                                            
                                            {
                                                allModuled.length>0&&allModuled.filter(ele=>ele.sheetName == renderList[0].channelName + '数据')[0].fileName !== '' ? 
                                                    <div style={{marginTop:'10px'}}>
                                                        <CheckCircleOutlined style={{color:'#54BCBD',marginRight:'10px'}}/>
                                                        <span style={{color:'#A9A9A9'}}>{allModuled.filter(ele=>ele.sheetName == renderList[0].channelName + '数据')[0].fileName}</span>
                                                    </div> : null
                                            }
                                        </div>
                                        {
                                            renderList[0].isSaleDate !== '1' && renderList[0].channelName != '博库' ?
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <RangePicker size='small' style={{ marginLeft: '20px', marginTop: '28px' }} onChange={handleTime} value={time} />
                                                    <div className={style.timeIcon}>
                                                        <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px' }} />
                                                        <span>请选择数据的日期范围后再导入</span>
                                                    </div>
                                                </div> : null
                                        }
                                        <Button type="link" onClick={() => handleDownTemp(renderList[0].channelName + '数据')}>下载模板</Button>
                                    </div>
                                </div>
                            </> :
                                <>
                                    <div style={{ color: '#808080' }}>
                                        {
                                            lastTime&&!!lastTime[perChannel + '_kc']
                                                ? <p>导入库存数据的截至日期为{lastTime[perChannel + '_kc']}，本次建议导入<span style={{ color: '#1A76D2' }}>{moment(lastTime[perChannel + '_kc']).add('1', 'day').format('YYYY-MM-DD')}开始</span>的数据</p>
                                                : '当前未导入' + renderList[0].channelName + '库存数据'
                                        }
                                    </div>
                                    <div className={style.contentStyle} key='2'>
                                        <span className={style.spanStyle}>库存数据</span>
                                        <div style={{
                                            width:'110px',display:'flex',flexWrap:'wrap'
                                        }}>
                                            <Upload {...uprops} accept='.xls,.xlsx' maxCount={1} >
                                                <Button type="primary" ghost style={{ width: '110px' }} onClick={() => handleClick(renderList[0].channelName + '库存数据')}>导入</Button>
                                            </Upload>
                                            
                                            {
                                                allModuled.length>0&&allModuled.filter(ele=>ele.sheetName == renderList[0].channelName + '库存数据')[0].fileName !== '' ? 
                                                    <div style={{marginTop:'10px'}}>
                                                        <CheckCircleOutlined style={{color:'#54BCBD',marginRight:'10px'}}/>
                                                        <span style={{color:'#A9A9A9'}}>{allModuled.filter(ele=>ele.sheetName == renderList[0].channelName + '库存数据')[0].fileName}</span>
                                                    </div> : null
                                            }
                                            
                                        </div>
                                        {
                                            renderList[0].channelName + '库存数据' == '其他2库存数据' ?
                                                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '22px' }}>
                                                    <DatePicker value={time} onChange={handleTime} style={{ marginLeft: '20px', marginTop: '12px' }} />
                                                    <div className={style.timeIcon}>
                                                        <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px' }} />
                                                        <span>请选择数据的日期后再导入</span>
                                                    </div>
                                                </div> : null
                                        }
                                        <Button type="link" onClick={() => handleDownTemp(renderList[0].channelName + '库存数据')}>下载模板</Button>
                                    </div>
                                    <div style={{ color: '#808080' }}>
                                        {
                                            lastTime&&!!lastTime[perChannel + '_xs']
                                                ? <p>导入销售数据的截至日期为{lastTime[perChannel + '_xs']}，本次建议导入<span style={{ color: '#1A76D2' }}>{moment(lastTime[perChannel + '_xs']).add('1', 'day').format('YYYY-MM-DD')}开始</span>的数据</p>
                                                : '当前未导入' + renderList[0].channelName + '销售数据'
                                        }
                                    </div>
                                    <div className={style.contentStyle} key='1'>
                                        <span className={style.spanStyle}>销售数据</span>
                                        <div style={{
                                            width:'110px',display:'flex',flexWrap:'wrap'
                                        }}>
                                            <Upload {...uprops} accept='.xls,.xlsx' maxCount={1} >
                                                <Button type="primary" ghost style={{ width: '110px' }}
                                                    disabled={handleDisabled(renderList[0], '2')}
                                                    onClick={() => handleClick(renderList[0].channelName + '销售数据')}>导入</Button>
                                            </Upload>
                                            {
                                                allModuled.length>0&&allModuled.filter(ele=>ele.sheetName == renderList[0].channelName + '销售数据')[0].fileName !== '' ? 
                                                    <div style={{marginTop:'10px'}}>
                                                        <CheckCircleOutlined style={{color:'#54BCBD',marginRight:'10px'}}/>
                                                        <span style={{color:'#A9A9A9'}}>{allModuled.filter(ele=>ele.sheetName == renderList[0].channelName + '销售数据')[0].fileName}</span>
                                                    </div> : null
                                            }
                                        </div>
                                        {
                                            (renderList[0].channelName + '销售数据' == '其他2销售数据' && renderList[0].isSaleDate == '0') || renderList[0].channelName + '销售数据' == '凤凰销售数据' ?
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <RangePicker size='small' style={{ marginLeft: '20px', marginTop: '28px' }} onChange={handleTime1} value={time1} />
                                                    <div className={style.timeIcon}>
                                                        <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px' }} />
                                                        <span>请选择数据的日期范围后再导入</span>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <Button type="link" onClick={() => handleDownTemp(renderList[0].channelName + '销售数据')}>下载模板</Button>
                                    </div>
                                </>
                        }
                    </>
                }
            </div>:null
            }
            <OutCustomCol
                visible={isOpenModal}
                name={name}
                onCancel={(name) => { 
                    setIsOpenModal(false); 
                    getLastTime() 
                    if(allModuled.length>0){
                        allModuled[moduleIndex].fileName = name
                    }
                    setAllModuled([...allModuled])
                }}
                userColumn={userColumn} onChange={() => { }}
                allField={allField}
                allModuled={allModuled}
                file={file}
                query={query}
            />
            <TimeModal
                visible={isTimeModalOpen}
                isPost={isPost}
                deletionTime={deletionTime}
                onCancel={(val) => {
                    setIgnore(val);
                    setIsTimeModalOpen(false);
                    if (val == '取消') {
                        setTime()
                        setTime1()
                    }
                }}
                file={file}
                data1={query}
            />
        </>
    )
}
