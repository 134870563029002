import React, { useState } from "react";

function EditableTable(props) {
    const [data, setData] = useState(props.data);
    const [editableCell, setEditableCell] = useState(null);

    const handleEditStart = (rowIndex, columnIndex) => {
        setEditableCell({ rowIndex, columnIndex });
    };

    const handleEditEnd = (value) => {
        const newData = [...data];
        newData[editableCell.rowIndex][editableCell.columnIndex] = value;
        setData(newData);
        setEditableCell(null);
    };

    return (
        <table>
            <thead>
                <tr>
                    <th>Column 1</th>
                    <th>Column 2</th>
                    <th>Column 3</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, columnIndex) => (
                            <td key={columnIndex}>
                                {columnIndex === props.editableColumn &&
                                    editableCell?.rowIndex === rowIndex &&
                                    editableCell?.columnIndex === columnIndex ? (
                                    <input
                                        type="text"
                                        value={cell}
                                        onChange={(e) => handleEditEnd(e.target.value)}
                                        onBlur={() => setEditableCell(null)}
                                        autoFocus
                                    />
                                ) : (
                                    <div onClick={() => handleEditStart(rowIndex, columnIndex)}>
                                        {cell}{" "}
                                        {columnIndex === props.editableColumn && (
                                            <span style={{ cursor: "pointer" }}>✏️</span>
                                        )}
                                    </div>
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default EditableTable;