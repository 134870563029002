/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import ExternalImport from './ExternalImport'
import InternalImport from './InternalImport'
import { getALLQuery } from '../../../utils';
import { getDateSource } from '../../../api/replenishmentSupervise';

import { Tabs, message } from 'antd';
const TabPane = Tabs.TabPane;

export default function DataImport() {
    const [tabKey, setTabKey] = useState('0')
    const [isShow, setIsShow] = useState(true)
    const userInfo = getALLQuery()

    useEffect(() => {
        // 获取数据方式是数据导入还是数据对接
        getDateSource({ publiId: userInfo.publiId }).then(res => {
            if (res.code == 200) {
                setIsShow(res.data.dataType)
            }
        })
    }, []);


    function handleTabChange(key) {
        _maq.trigger(['_trackEvent', key == '0' ? "内部数据导入" : "外部数据导入"]); 
        setTabKey(key)
        
    }
    
    return (
        <div style={{ marginLeft: '40px' }}>
            <Tabs activeKey={tabKey} onChange={handleTabChange} tabBarGutter='100px'>
                <TabPane tab="内部数据导入" key="0" >
                    <div><InternalImport isSwitch={tabKey}/></div>
                </TabPane>
                {
                    isShow == '1' && 
                        <TabPane tab="外部渠道数据导入" key="1" >
                            <div><ExternalImport isSwitch={tabKey}/></div>
                        </TabPane>
                }
            </Tabs>
        </div>
    )
}