// 获取地址栏参数
export const debounceT = (fun, wait = 500, ...args)=> {
    let timeout = undefined
    return function (e) {
        if (timeout) {//如果存在定时器就清空
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            fun(e, ...args)
        }, wait)
    }
  
  }