/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react'
import style from './index.module.css'
import { Input, Select, Button, Table, Form, Row, Col, Popover, message, Tooltip, InputNumber } from 'antd';
import { ExclamationCircleFilled, EditOutlined, CloseOutlined, CheckOutlined, ForwardOutlined, DownCircleTwoTone,QuestionCircleFilled } from '@ant-design/icons';
import CustomTable from '../../../components/CustomTable';
import HistoryRecord from '../../../components/HistoryRecord';
import {cloneDeep} from 'loadsh'
import { 
    publiSystemConfig, 
    replenishmentCal, 
    showList, 
    reprintExport, 
    historyRecord, 
    exportHistoryPrint,
    appCountBatch,
    updatePrintNum,
} from '../../../api/reprintSupervise'
import { importLastTime } from '../../../api/importData';
import { zjyyFieldDetail, initial, updateTable } from '../../../api/customTable'
import { downFile, getALLQuery } from '../../../utils';
import moment from 'moment';
import EditableTable from './editabletable';
import { getAllChannel } from '../../../api/config';

export default function PrintNumberSupervise() {
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const userInfo = getALLQuery();
    let [isInit, setIsInit] = useState(true); // 是否是初次进入
    let [changeFlag, setChangeFlag] = useState(true); // 表单项改变标志
    const [historyList, setHistoryList] = useState(''); // 历史批次列表
    const [historyListPag, setHistoryListPag] = useState({ current: 1, pageSize: 10, total: 0 }); // 历史批次列表页脚
    const [batchSaleDate, setBatchSaleDate] = useState([]); // 销售统计周期
    const [pzList, setData] = useState([])
    const [isEdit, setEdit] = useState(false) // 控制建议印数该列输入框是否显示
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenHisModal, setIsOpenHisModal] = useState(false)
    const [columns, setColumns] = useState([])
    const [batchId, setBatchId] = useState() // 历史批次
    const [batchName, setBatchName] = useState('') // 导出历史名称  
    const [startTime, setStartTime] = useState()  // 当前时间减去统计周期
    const [optionsCate, setOptionsCate] = useState([])
    const [isNeedCate, setIsNeedCate] = useState()  // 将查询品种分类接口返回得isNeedCate保存，以便计算数据接口作为参数使用
    const [selectedValue, setSelectedValue] = useState('all')  // 当选择了最上面得品种分类后，将value值保存
    const [lastTime, setLastTime ] = useState()
    const [isDisabled, setIsDisabled] = useState(false) // 导出结果是否禁用
    const [isLoading, setIsLoading] = useState(false) // 在table展示出数据之前有一个加载效果
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 }) // 展示列表分页
    const [jisuanLoading, setJisuanLoading] = useState(false)
    const [optionsFunnel, setOptionsFunnel] = useState([])
    const ref1 = useRef(pzList);
    const ref2 = useRef('')
    const ref = useRef();
    const column = [
        {
            title: '书目信息',
            align: 'left',
            isOpen: '1',
            width: 300,
            render: (_, record) => (
                <div>
                    <div style={{ color: '#1A76D2' }}>{record.prodName}</div>
                    <div>
                        <span style={{ marginRight: '10px' }}>ISBN:{record.isbn}</span>
                        <span>定价：{record.price}</span>
                    </div>
                    {/* <div>出版日期：{record.publiDate}</div>
                    <div>作者:{record.authorName}</div> */}
                </div>
            )
        },
        {
            title: (
                <span>
                    当前库存总数
                    <Tooltip placement="top" title={<p className="u-tips">内部数据和外部渠道数据的库存相加之和</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'allInvNum',
            align: 'center',
            isOpen: '1',
            width: 200,
        },
        {
            title: (
                <span>
                    库存周转天数
                    <Tooltip placement="top" title={<p className="u-tips">当前总库存数/日均销售总数</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'allTurnoverDay',
            align: 'center',
            isOpen: '1',
            width: 200,
        },
        {
            title: (
                <span>
                    日均销售总数
                    <Tooltip placement="top" title={<p className="u-tips">销售总数/统计周期的天数</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'avgSaleNum',
            align: 'center',
            isOpen: '1',
            width: 180,
        },
        {
            title: (
                <span>
                    销售总数
                    <Tooltip placement="top" title={<p className="u-tips">统计周期内的外部渠道数据的销售数据相加之和</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'saleNum',
            align: 'center',
            isOpen: '1',
            width: 150,
        },
        {
            title: (
                <span>
                    销售天数
                    <Tooltip placement="top" title={<p className="u-tips">统计周期内该品种在所有渠道中存在销售的天数</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'saleDay',
            align: 'center',
            isOpen: '1',
            width: 150,
        },
        {
            title: '上次重印日期及数量',
            align: 'center',
            isOpen: '1',
            width: 300,
            render: (_, record) => (
                <div>
                    <div style={{ paddingLeft: '27px' }}>日期：{record.lastPrintDate}</div>
                    <div>数量：{record.lastPrintNum}本</div>
                </div>

            ),
        },
        {
            title: '是否需要重印',
            dataIndex: 'isNeed',
            align: 'center',
            isOpen: '1',
            width: 180,
            render: (value, record) => {
                return (
                    <>
                        {
                            record.printNum < record.minPrintNum ? '否' : record.isNeed == '1' ? '是' : '否'
                        }
                    </>
                )
            }
        },
        {
            title: (
                <span>
                    本次印数
                    <Tooltip placement="top" title={<p className="u-tips">系统估算出本次需要重印的数量</p>}>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'printNum',
            align: 'center',
            isOpen: '1',
            width: 150,
            render: (text, record, index) => {
                
                return (
                    <>
                        {
                            // 每行数据自带的record.isOpen控制的是，点击该行编辑图标的时候不至于每一行输入框都显示 
                            record.edit == true ? <div className={style.printNumStyle}>
                                <InputNumber onChange={handleClick} style={{ width: '80px' }} defaultValue={text}/>
                                <CloseOutlined onClick={() => cancelModifyNum(index)} />
                                <CheckOutlined onClick={() => yesModifyNum(record.id, index)} />
                            </div>
                            : <div className={style.editStyle}>
                                <div style={{ color: '#1A76D2', marginRight: '5px' }}>{text}本</div>
                                {
                                    ref2.current == ''  ? <EditOutlined onClick={() => modifyPrintNum(record, index)} /> : ''
                                }
                            </div>
                        }
                    </>
                )
            }
        },
    ]
    const popoverContent1 = (
        <div className={style.font}>
            <p>库存数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!lastTime?.kc ? lastTime?.kc : '--'}</span></p>
            <p>书目信息更新日期：<span style={{ color: '#A9A9A9' }}>{!!lastTime?.pz ? lastTime?.pz: '--'}</span></p>
        </div>
    );
    const popoverContent2 = (
        <div className={style.font}>
            {
                optionsFunnel.filter(item=>item.label!='全部渠道').map((item)=>{
                    const channel = item.value
                    return (
                        item.isMerge == '1' ?<p>{item.label}库存及销售数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!(lastTime&&lastTime[channel]) ? lastTime[channel]: '--'}</span></p>:
                        <>
                            <p>{item.label}销售数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!(lastTime&&lastTime[channel+'_xs'])? lastTime[channel+'_xs']: '--'}</span></p>
                            <p>{item.label}库存数据更新日期：<span style={{ color: '#A9A9A9' }}>{!!(lastTime&&lastTime[channel+'_kc'])? lastTime[channel+'_kc']: '--'}</span></p>
                        </>
                       
                    )
                })
            }
        </div>
    );
    useEffect(() => {
        if (isInit == false) {
            if (batchId) {
                getHistoryListT()
            } else {
                getReprintList()
            }
        } else {
            setIsInit(false)
        }
    }, [pagination.current, batchId, changeFlag])

    // 查询系统配置中重印配置的信息
    useEffect(() => {
        getHistoryList();// 获取历史列表
        getPzSelectList();// 获取品种下拉列表数据
        getCol(); // 获取表头配置
        getLastTime(); // 获取截止日期
        getSaleChannelSelectList(); // 获取销售渠道下拉列表数据  
    }, [])

    const getSaleChannelSelectList = () => {
        getAllChannel({publiId:userInfo.publiId}).then(res => {
            if(res.code == 200){
                const funnel = [...res.rows].filter(item => item.isOpen == '1').map((item) => {
                    return (
                        {
                            label: item.channelName,
                            value: item.channel,
                            isMerge : item.isMerge
                        }
                    )
                })
                setOptionsFunnel([{
                    label: '全部渠道',
                    value: 'all',
                }, ...funnel])
            }else{
                message.error(res.msg,2)
            }
        })
    }

    // 刷新整个页面
    const refresh = () => {
        getHistoryList();// 获取历史列表
        getPzSelectList();// 获取品种下拉列表数据
        getCol(); // 获取表头配置
        getLastTime(); // 获取截止日期
        if(pagination.current != 1) setPagination({...pagination, current: 1})
        else getReprintList();
        form.resetFields()
        form2.resetFields()
    }

    const getLastTime = () => {
        importLastTime(userInfo.publiId).then(res => {
            if (res.code == 200 && JSON.stringify(res.data) != '{}') {
                let arr = res.data;
                let arr1 = [!!arr.kc ? moment(arr.kc) : '', !!arr.pz ? moment(arr.pz) : ''].filter(item => !!item)
                let outArr = Object.assign({}, arr)
                delete outArr.kc
                delete outArr.pz
                let arr2 = Object.values(outArr).map(item=>moment(item)).filter(item => !!item)
                let max1 = [...arr1].length == 0 ? undefined : moment.max(arr1).format('YYYY-MM-DD')
                let max2 = [...arr2].length == 0 ? undefined : moment.max(arr2).format('YYYY-MM-DD')
                let arr3 = Object.assign(arr, {max1: max1}, {max2: max2})
                setLastTime(arr3)
            }
        })
    }
    // 读取表头配置
    const getCol = () => {
        zjyyFieldDetail({
            userId: userInfo.userId,
            module: 'app_print',
            publiId: userInfo.publiId
        }).then(async resO => {
            let colArr = [];
            if (resO.result_code == 200 && resO.totalDataNum == 0) {
                await initial({
                    module: 'app_print'
                }).then(res => {
                    colArr = res.data
                })
            } else {
                colArr = resO.data
            }
            let morenArr = ['书目信息', '当前库存总数', '库存周转天数' ,'日均销售总数', '销售总数', '销售天数', '上次重印日期及数量', '是否需要重印', '本次印数']
            let arrT = [...colArr].map((ele, idx )=> {
                if (morenArr.indexOf(ele.fieldName) != -1) {
                    console.log('el', ele)
                    colArr.splice(idx , 1);
                    [...column].map(item => {
                        let title
                        if(typeof(item.title) != 'string'){
                            title = item.title.props.children[0]
                        }else{
                            title = item.title
                        }
                        if (ele.fieldName == title) {
                            item.align = ele.align == '1' ? 'left' : ele.align == '2' ? 'center' : 'right'
                            item.isOpen = ele.isOpen
                            item.fixed = ele.isFreeze == '1' ? 'left' : ele.align == '2' ? 'right' : undefined
                            item.orderId = ele.orderId
                            item.width = ele.size + 'px'
                        }
                    })
                } else {
                    return {
                        title: ele.fieldName,
                        dataIndex: ele.fieldCode,
                        align: ele.align == '1' ? 'left' : ele.align == '2' ? 'center' : 'right',
                        isOpen: ele.isOpen,
                        fixed: ele.isFreeze == '1' ? 'left' : ele.align == '2' ? 'right' : undefined,
                        orderId: ele.orderId,
                        width: ele.size + 'px'
                    }
                }
            });
            let cloneArr = [...column, ...arrT].filter(item => !!item && item.isOpen == 1);
            cloneArr.sort((a, b) => a.orderId - b.orderId)
            setColumns(cloneArr)
        })
    }

    const getHistoryList = () => {
        appCountBatch({
            pageNum: historyListPag.current,
            pageSize: 10,
            configType: '1',
            publiId: userInfo.publiId
        }).then(res => {
            if (res.code == 200) {
                setHistoryList(res.rows)
                setHistoryListPag({
                    ...historyListPag,
                    total: res.total
                })
                
            }
        })
    }
    
    const getPzSelectList = () => {
        publiSystemConfig({
            publiId: userInfo.publiId,
            configType: '1',
            channel: '0',
        }).then(res => {
            if (res.msg == '查询成功') {
                const cate = res.rows.map((item) => {
                    return (
                        {
                            label: item.levelName,
                            value: item.levelId,
                        }
                    )
                })
                setOptionsCate( cate.length==1&&cate[0].label=='全部品种'?[...cate]: [{
                    label: '全部品种',
                    value: 'all',
                }, ...cate])
                if(cate.length==1&&cate[0].label=='全部品种'){
                    form2.setFieldsValue({
                        category:cate[0].value
                    })
                    form.setFieldsValue({
                        levelId:cate[0].value
                    })
                }
                setIsNeedCate(res?.rows[0]?.isNeedCate)
                const startDate = new Date().getTime() - res?.rows[0]?.countDay * 86400000
                setBatchSaleDate([moment(startDate).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
                setStartTime(startDate)
            }
        })
    }

    const getListByBatchIdT = (record) => {
        setBatchId(record.batchId);
        setBatchName(record.batchName);
        setBatchSaleDate([record.startDate, record.endDate]);
        ref2.current = record.batchName
    }

    // 获取历史展示列表
    const getHistoryListT = () => {
        historyRecord({
            pageNum: pagination.current,
            batchId
        }).then(res => {
            if (res.msg == '查询成功') {
                _maq.trigger(['_trackEvent', `重印预测-查询历史重印数据-批次id: ${batchId}-第${pagination.current}页`]); 
                setData(res.rows)
                setPagination({...pagination, total: res.total})
            }
        })
    }

    const handleClick = (val) => {
        ref.current = val
    }

    // 当点击建议印数该列的编辑图标时相关操作
    const modifyPrintNum = (record, idx) =>  {
        let arr = cloneDeep(ref1.current)
        arr[idx].edit = true 
        ref.current = arr[idx].printNum
        setData([...arr])
    }

    // 当自定义列表弹框进行相应修改后，提印预测页面中table进行相应变化
    const change = () => {
        getCol()
    }
    // 修改建议印数
    function cancelModifyNum(idx) {
        let arr = cloneDeep(ref1.current)
        arr[idx].edit = false 
        ref.current = arr[idx].printNum
        setData([...arr])
    }

    const yesModifyNum = (id, idx) => {
        if (!/^[+]{0,1}(\d+)$/.test(ref.current)) {
            message.error('请输入正整数',2)
            return false;
        }
        let arr = cloneDeep(ref1.current)
        updatePrintNum({
            id,
            printNum: ref.current,
            isNeed: ref.current < arr[idx].minPrintNum ? '0' : '1'
        }).then(res => {
            if (res.code == 200) {
                arr[idx].edit = false;
                arr[idx].printNum = ref.current;
                arr[idx].isNeed = ref.current < arr[idx].minPrintNum ? '0' : '1';
                _maq.trigger(['_trackEvent', `重印预测-更新本次印数: ${ref.current}`]); 
                // getReplenishment()
            } else {
                arr[idx].edit = false;
                message.error(res.msg,2)
            }
            setData(cloneDeep(arr))
            ref1.current = [...arr]
            message.success(res.msg,2)
        })
    }

    // 点击重印预测按钮后开始计算
    const startSupervise = () => {
        setJisuanLoading(true)
        setIsDisabled(false)
        let leveIdAll = optionsCate.map(item => item.value);
        let values = form2.getFieldsValue();
        let parmas = {
            publiId: userInfo.publiId,
            configType: '1',
            creator: userInfo.userId,
            leveIdList: values.category == 'all' ? leveIdAll.filter(item => item != 'all') : [values.category],
            isNeedCate: isNeedCate,
            channel: '1' 
        }
        replenishmentCal(parmas).then((res) => {
            setJisuanLoading(false)
            if (res.code == '200') {
                _maq.trigger(['_trackEvent', `重印预测-查询条件: ${JSON.stringify(parmas)}`]); 
                // 重印预测成功后，table表单联动   
                form.setFieldsValue({ levelId: values.category })
                setIsLoading(true)
                setSelectedValue(values.category)
                setPagination({...pagination, current: 1})
                getReprintList()
            } else {
                message.error(res.msg,2)
            }
        })
    }

    // 获取重印展示列表
     const getReprintList = async () => {
        const values = await form.getFieldsValue()
        showList({
            pageNum: pagination.current,
            pageSize: 10,
            isNeed: values?.isNeed == '3' ? '' : values?.isNeed,
            levelId: values?.levelId == 'all' ? null : values?.levelId,
            creator: userInfo.userId,
            publiId: userInfo.publiId,
            prodName: values?.prodName
        }).then(res => {
            setIsLoading(false)
            if (res.code == '200') {
                let arr = [...res.rows]
                arr.map((item) => item.edit = false)
                setData(arr)
                ref1.current = arr;
                setPagination({
                    ...pagination,
                    total: res.total
                })
            } else {
                message.error(`${res.msg}`,2);
            }
        })
    }

    async function handleExport() {
        setIsDisabled(true)
        const values = await form.getFieldsValue()
        let pzObj = optionsCate.filter(item => item.value == values.levelId)
        let levelIdStr = values.levelId == 'all' ? '' : values?.levelId;
        let parmas  = {
            ...values,
            publiId: userInfo.publiId,
            levelId: levelIdStr,
            creator: userInfo.userId,
            creatorName: userInfo.userName,
            configType: '1',
            prodName: !!values.prodName ? values.prodName : '',
            startDate: batchSaleDate[0],
            endDate: batchSaleDate[1],
            levelName: pzObj[0].label,
            isNeed: values.isNeed == '3' ? '' : values.isNeed
        }
        reprintExport(parmas).then(res => {
            if (res.status == 200) {
                _maq.trigger(['_trackEvent', `重印预测-导出重印结果-条件: ${JSON.stringify(parmas)}`]); 
                downFile(res)
                refresh()
            } else {
                message.error(res.msg,2)
            }
        })
    }
    // 导出历史结果
    function exportHistoryRecord() {
        exportHistoryPrint({
            batchId: batchId
        }).then(res => {
            if (res.status == 200) {
                _maq.trigger(['_trackEvent', `重印预测-导出历史结果: ${batchId}`]); 
                downFile(res)
            } else {
                message.error(res.msg,2)
            }
        })
    }

    // 返回重印预测页面
    const goBack = () => {
        form.resetFields();
        setBatchId('');
        setBatchName('');
        setData([]);
        setPagination({current: 1, pageSize: 10, total: 0})
        setBatchSaleDate([moment(startTime).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
        setSelectedValue('all');
        setIsInit(true)
        ref2.current = ''
    }

    // 重印展示列via哦分页器   
    const onPageChange = (page) => {
        setPagination({...pagination, current: page})
    }

    // 表单发生更改时
    const onFormChange = (props, changedValues, allValues) => {
        setPagination({...pagination, current: 1})
        setChangeFlag(!changeFlag)
    }
    
    return (
        <>
            <div className={style.reprintTop}>
                <div className={style.form}>
                    <Form initialValues={{ category: 'all' }} labelCol={{ style: { width: 100, textAlign: 'left' } }} layout="inline" form={form2}>
                        <Row>
                            <Col>
                                <Form.Item name='category' label="品种分类 :" style={{ fontWeight: 'bold' }}>
                                    {/* <Select style={{ width: 140 }} options={optionsCate} /> */}
                                    <Select style={{ width: 140 }} options={
                                            optionsCate.filter(item=>!( item.label.includes('全部品种-')))
                                        
                                    } />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Button 
                        loading={jisuanLoading}
                        type="primary" 
                        style={{ marginRight: '15px', marginLeft: '40px' }} 
                        onClick={startSupervise} 
                        disabled={batchName !== ''}
                    >重印预测</Button>
                    {
                        batchName !== '' 
                        ? <Button type="primary" ghost onClick={goBack}>返回</Button> 
                        : undefined
                    }
                </div>
                <div className={style.time}>
                    <div style={{ marginRight: '50px', marginBottom: '20px' }}>
                        <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px' }} />
                        <span>最近重印：<span style={{ color: '#1A76D2', cursor: 'pointer' }}>{historyList[0]?.batchName}<ForwardOutlined /></span></span>
                        <span onClick={() => {setIsOpenHisModal(!isOpenHisModal); _maq.trigger(['_trackEvent', `重印预测-查询更多历史记录`]); }} style={{ color: '#A9A9A9', cursor: 'pointer' }}>(点击展示更多记录)</span>
                    </div>
                    <div style={{ marginRight: '50px', marginBottom: '20px' }}>内部数据更新日期:
                        {
                            lastTime?.max1 ? (
                                <>
                                    <span style={{ color: '#A9A9A9', paddingLeft: '5px', paddingRight: '5px' }}>{!!lastTime?.max1 ? lastTime?.max1 : ''}</span>
                                    <Popover placement="bottom" content={popoverContent1} trigger="click">
                                        <DownCircleTwoTone />
                                    </Popover>
                                </>
                            ) : '--'
                        }
                    </div>
                    <div style={{ marginBottom: '20px' }}>外部数据更新日期:
                        {
                           lastTime?.max2 ? (
                               <>
                                    <span style={{ color: '#A9A9A9', paddingLeft: '5px', paddingRight: '5px' }}>{lastTime?.max2}</span>
                                    <Popover placement="bottom" content={popoverContent2} trigger="click">
                                        <DownCircleTwoTone />
                                    </Popover>
                               </>
                            ) : '--'
                        }
                    </div>
                </div>
            </div>
            <div className={style.reprintMiddle}>
                <div style={{ padding: '10px', marginBottom: '10px',display:'flex',flexWrap:'wrap',flex:'1',alignItems:'baseline' }}>
                    <span style={{ marginRight: '20px', fontWeight: 'bold',marginBottom:'10px' }}>品种展示 (销售统计周期：
                        <span style={{ color: '#A9A9A9' }}>{batchSaleDate[0]} 至 {batchSaleDate[1]}</span>)
                    </span>
                    <span style={{marginRight:'20px'}}>{batchName}</span>
                    <Button type="primary" ghost onClick={() => {setIsOpenModal(true);_maq.trigger(['_trackEvent', `重印预测-自定义列`]); }} >
                        自定义列
                    </Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-around',width:'750px' }}>
                    <Form 
                        initialValues={{ levelId: 'all', isNeed: '1' }} 
                        form={form} 
                        layout="inline" 
                        onValuesChange={onFormChange}
                    >
                        <Form.Item label="是否需要重印 :" name='isNeed' style={{ marginLeft: '10px' }}>
                            <Select 
                                style={{ width: 120 }}
                                disabled={!batchName == '' || isDisabled}
                                options={[
                                    { value: '3', label: '全部' },
                                    { value: '1', label: '需要' },
                                    { value: '0', label: '不需要' },
                                ]} 
                            />
                        </Form.Item>
                        <Form.Item name='levelId' label="" style={{ fontWeight: 'bold', marginLeft: '14px', marginRight: '14px' }} >
                            <Select 
                                style={{ width: 150 }} 
                                disabled={!batchName == '' || isDisabled}
                                options={selectedValue == 'all' ? optionsCate : optionsCate.filter(item => item.value == selectedValue)} 
                            />
                        </Form.Item>
                        <Form.Item name='prodName' style={{ marginRight: '14px' }} >
                            <Input 
                                placeholder="请输入品种名称或ISBN" 
                                style={{ width: '180px' }} 
                                disabled={!batchName == '' || isDisabled}
                            />
                        </Form.Item>
                        {
                            batchName == '' ?
                                <Button 
                                    type="primary" 
                                    style={{ marginRight: '15px' }} 
                                    onClick={handleExport} 
                                    disabled={isDisabled || pzList.length == 0}
                                >
                                    导出结果
                                </Button> :
                                <Button type="primary" style={{ marginRight: '15px' }} onClick={() => exportHistoryRecord()}>
                                    导出历史结果
                                </Button>
                        }
                    </Form>
                    
                </div>
            </div>
            <div className={style.reprintBottom}>
                <Table 
                    rowKey='id'
                    columns={columns} 
                    dataSource={pzList} 
                    pagination={{...pagination, showSizeChanger: false, onChange: (page) => onPageChange(page)}}
                    bordered={true} 
                    scroll={{ x: true }}
                    loading={isLoading}
                >
                </Table>
            </div>
            <CustomTable visible={isOpenModal}  change={change} onCancel={() => setIsOpenModal(false)} module={'app_print'} />
            {
                isOpenHisModal && (
                    <HistoryRecord 
                        visible={isOpenHisModal} 
                        configType={'1'}
                        onCancel={() => setIsOpenHisModal(false)}
                        getListByBatchId={(record) => {
                            setIsOpenHisModal(false);
                            getListByBatchIdT(record)
                        }} 
                        onPostLastItem={(item) => {setHistoryList([item])}}
                        name={'print'} 
                    />
                )
            }
        </>
    )
}
