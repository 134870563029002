import { message, Modal } from 'antd'
import React from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { importCusTable} from '../../api/importData'

export default function index({ visible, onCancel, data1, deletionTime, isPost, file }) {
    console.log(deletionTime,typeof(deletionTime));
    // 当日期输入框为一个范围的时候，如当当数据后面的输入框就有开始时间和截至时间，此时deletionTime的数据类型为数组
    // 当日期输入框不是一个范围的时候，如内部数据中库存数据后面的输入框只能提填一个时间，此时deletionTime的数据类型为string
    let c = typeof(deletionTime) == 'string' ? deletionTime : deletionTime[0]
    // 
    if(c){
        if(typeof(deletionTime) == 'object'){
            // deletionTime 为数组的时候，有['2023-08-23', '2023-08-01']（如点击当当数据后面的日期范围时） 或者 
            // ['2023年05月15日', '2023年07月29日'](在点击自定义配置弹框的确定按钮的时候可能会弹出缺失日期弹框)两种格式
            // 下面处理时，保证数组的第二个元素代表的日期始终大于第一个
            if(c.indexOf('年') == -1 ){
                let a = deletionTime[0]
                deletionTime[0] = deletionTime[1]
                deletionTime[1] = a
            }
        }
    }
    function b() {
        if (isPost == false) {
            onCancel('1')
        } else {
            importCusTable({
                ...data1,
                file: file,
                ignore: '1'
            }).then(res => {
                onCancel(file.name)
                if (res.code != 200) {
                    message.error(res.msg,2)
                } else {
                    message.success({
                        content: `${res.msg}`
                    })
                }
            })
        }
    }
    return (
        <div>
            <Modal title="提示" width={600} open={visible} onOk={() => {b()}} onCancel={() => onCancel('取消')} okText='确定' cancelText='取消' maskClosable={false}>
                <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px', marginLeft: '20px', fontSize: '18px' }} />
                <span>缺失日期为{typeof deletionTime == 'string' ? deletionTime : `${deletionTime[0]} - ${deletionTime[1]}`}的数据，请确定是否继续导入</span>
            </Modal>
        </div>
    )
}
