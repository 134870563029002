/* eslint-disable no-undef */
import { Button, message, Modal, Table, Popconfirm } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import React, { useState } from 'react';
import { getDateTime } from '../../utils/Supervise.js'
import { useEffect } from 'react';
import { appCountBatch } from '../../api/replenishmentSupervise.js'
import { getALLQuery } from '../../utils/index.js';
import { exportHistoryPrint, delHistoryPrint } from '../../api/reprintSupervise.js';
import { exportHistoryStock, delHistoryStock } from '../../api/replenishmentSupervise.js'
import { downFile } from '../../utils/index.js';


export default function ReprintRecord({ visible, getListByBatchId, onCancel, configType, onPostLastItem,name, isRefresh }) {
    const columns = [
        {
            title: '历史导出',
            dataIndex: 'batchName',
            align: 'center',
            render: (text, record) => {
                return (
                    <div 
                        style={{ color: '#1A76D2', marginRight: '5px', cursor: 'pointer' }} 
                        onClick={() => getListByBatchId(record)}
                    >
                        {text}
                    </div>
                )
            }
        },
        {
            title: '操作人',
            dataIndex: 'creatorName',
            align: 'center'
        },
        {
            title: '操作',
            dataIndex: 'action',
            align: 'center',
            render: (_, record) => {
                return (
                    <div 
                        style={{ color: '#1A76D2', marginRight: '5px', cursor: 'pointer' }}
                        
                    >
                        <Button type="link" onClick={() => exportHistoryF(record.batchId)}>导出历史记录</Button>
                        <Popconfirm
                            title={<p style={{ textAlign: 'center' }}>是否确认删除该条历史记录？<br /></p>}
                            icon={<ExclamationCircleFilled style={{ color: '#1A76D2' }} />}
                            onConfirm={() => delHistoryF(record.batchId)}
                        >
                            <Button type="link">删除历史记录</Button>
                        </Popconfirm>
                        
                    </div>
                )
            }
        },
    ]
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const [list, setList] = useState();
    const [downingList, setDowning] = useState([])

    useEffect(() => {
        const userInfo = getALLQuery()
        getList(userInfo)
    }, [pagination?.current,pagination?.total, isRefresh])

    const getList = (userInfo) => {
        appCountBatch({
            pageNum: pagination.current,
            pageSize: 10,
            configType: configType,
            publiId: userInfo.publiId
        }).then(res => {
            if (res.code == 200) {
                setList(res.rows)
                setPagination({
                    ...pagination,
                    total: res.total
                })
                onPostLastItem(res.rows[0])
            }
        })
    }
    // 改变分页
    const handlePageChange = (page) => {
        let cur = page.current
        setPagination({
            ...pagination,
            current: cur
        })
    }

    // 导出历史记录
    const exportHistoryF = (batchId) => {
        if(name == 'print'){
            exportHistoryPrint({batchId}).then((res) => {
                _maq.trigger(['_trackEvent', `导出历史重印记录-通过批次id: ${batchId}`]); 
                downFile(res)
            })
        }else{
            exportHistoryStock({batchId}).then((res) => {
                _maq.trigger(['_trackEvent', `导出历史补货记录-通过批次id: ${batchId}`]); 
                downFile(res)
            })
        }
        
    }

    // 删除历史记录
    const delHistoryF = (batchId) => {
        if(name == 'print'){
            delHistoryPrint({batchId}).then((res) => {
                _maq.trigger(['_trackEvent', `删除历史重印记录-通过批次id: ${batchId}`]); 
                if (res.code == '200') {
                    const userInfo = getALLQuery()
                    getList(userInfo)
                    message.success('删除成功')
                }
            })
        }else{
            delHistoryStock({batchId}).then((res) => {
                if (res.code == '200') {
                    const userInfo = getALLQuery()
                    getList(userInfo)
                    message.success('删除成功')
                }
                _maq.trigger(['_trackEvent', `删除历史补货记录-通过批次id: ${batchId}`]); 
            })
        }
    }

    return (
        <Modal width={1000} open={visible} title={name=='print'? '重印记录' : '补货记录'} onCancel={onCancel} footer={[]}>
            <Table 
                rowKey='batchId'
                columns={columns} 
                dataSource={list} 
                bordered={true} 
                pagination={pagination} 
                onChange={handlePageChange}
            />
        </Modal>
    )
}
