/* eslint-disable no-undef */
import { Modal, Pagination, Popconfirm, Table, message } from 'antd'
import React, { useEffect, useState } from 'react'
import style from './index.module.css'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { deleteCategory } from '../../api/config'

export default function DeleteFL({isOpenFl,onCancel,allFl}) {


    const [data,setData] = useState([])
    useEffect(()=>{
        let arr = allFl.map((item)=>{
            return ({
                key:item.levelId,
                name:item.levelName,
                action:'删除'
            })
        })
        setData([...arr])
    },[allFl])
    // 
    const columns = [
        {
            title: '分类名称',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            align: 'center',
            render:(value,record)=>{
                return (
                    <>
                        <Popconfirm
                            title={<p style={{textAlign: 'center'}}>是否确认删除该分类？<br/>
                                    <span style={{color:' #B9B9B9', fontSize: '12px'}}>
                                        删除后该分类品种将转移至暂未分类中
                                    </span>
                                </p>}
                            icon={<ExclamationCircleFilled style={{color: '#1A76D2'}}/>}
                            onConfirm={()=>handleOk(record.key)}
                            onCancel={handleCancel}
                        >
                            <span style={{color:'#1890ff'}}>{value}</span>
                        </Popconfirm>
    
                    </>
                )
            }
        },
  ]
  
  function handleOk(key){
    
    // 删除自定义分类
    deleteCategory({
        levelId:key
      }).then(res=>{
        if(res.msg == '操作成功'){
            message.success('删除成功',2)
            let newArr = data.filter(item=>{
                _maq.trigger(['_trackEvent', `删除${item.name}分类`]);
                return item.key != key
            })
            setData([...newArr]) 
        }
        if(res.code != 200){
            message.error('操作失败',2)
        }
      })
  };
  const handleCancel = () => {
    
  };
  return (
    <Modal open={isOpenFl} title="减少分类" width={600}  maskClosable={false} 
        onCancel={onCancel}
        footer={[]}
    >
        <Table columns={columns} dataSource={data} size='small' pagination={{
            defaultPageSize:6,
            pageSizeOptions:[6],
            showSizeChanger:true,
            total:allFl.length
        }}></Table>
    </Modal>
  )
}
