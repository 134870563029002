/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.css'
import { Button, Input, Radio, Tag, Tooltip, Modal, message, Upload, InputNumber } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { fieldConfig, publiSystemConfig, addCategory, deleteCategory, update,getAllChannel } from '../../../../api/config'
import { getALLQuery } from '../../../../utils/index';
import DeleteFL from '../../../../components/DeleteFL';

export default function ReprintConfig({ change }) {
    const [isGrade, setIsGrade] = useState(1);
    const [salesFunnel, setSalesFunnel] = useState('');

    const [field, setField] = useState([])
    const userInfo = getALLQuery()

    const symbol1 = [' + ', ' - ', ' * ', ' / ', ' > ', ' >= ', ' < ', ' <= ', ' = ', ' and ', ' or ']
    const symbol2 = [' ( ', ' ) ']
    const number = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
    const [indexNumber, setIndexNumber] = useState() // 控制用户不能连续点击 . ，但是可以连续点击两个一样的数字
    const [noClickField, setNoClickFild] = useState(false)
    const [noClickSym, setNoClickSym] = useState(false)
    const [noClickNum, setNoClickNum] = useState(false)
    const [configArr, setConfigArr] = useState([])
    const [basicID, setBasicID] = useState() // 基础信息自增ID
    const [allBreedID, setAllBreedID] = useState() // 全部品种的levelId
    // 保存时，校验失败弹出提示框
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenFl, setIsOpenFl] = useState(false) // 点击减少分类按钮后弹出减少分类对话框
    const [channelList, setChannelList] = useState([{label:'京东',value:'jd'},{label:'当当',value:'dd'}])

    // 获取字段
    useEffect(() => {
        fieldConfig({moduleId:'dws_stock',publiId:userInfo.publiId}).then(res => {
            setField(res.rows)
        })
        pubilc()
    }, [salesFunnel])

    // 获取所有渠道   
    useEffect(() => {
        getAllChannel({publiId:userInfo.publiId}).then(res=>{
            
            let channelList = []
            res.rows.forEach(element => {
                channelList.push({
                    label:element.channelName,
                    value:element.channel,
                    isOpen:element.isOpen
                })
            });
            channelList = channelList.filter(item=>item.isOpen == '1' && item.label != '线下')
            setSalesFunnel(channelList[0].value)
            setChannelList([...channelList])
        })
    }, [])


    const updateInfo = (query) => {
        update({
            ...query,
            modifier: userInfo.userId
        }).then(res => {
            if (res.code == 200) {
                if (!query.id) {
                    message.success('保存成功',2)
                }
                pubilc()
            }
            if (res.code == 301) {
                // 校验失败
                setIsOpen(true)
            }
        })
    }

    function pubilc() {
        // 查询基础信息配置与自定义分类规则      
        publiSystemConfig({
            publiId: userInfo.publiId,
            configType: '2',
            channel: salesFunnel,
            creator: userInfo.userId,
            modifier: userInfo.userId,
        }).then(res => {
            
            setConfigArr([...res.rows])
            
            setIsGrade(res?.rows[0]?.isNeedCate)
            setBasicID(res?.rows[0]?.id)
            setTags(res.rows.map(item => {
                return {
                    levelName: item.levelName,
                    levelId: item.levelId
                }
            }))
            if (res?.rows[0]?.isNeedCate == 1) {
                setTagsList(res.rows.map(item => {
                    return {
                        title: item.levelName,
                        levelId: item.levelId,
                        // 下面是正确的
                        arr: [{
                            title: '参与补货规则',
                            value: item.ruleSqlName ? item.ruleSqlName : '', result: item.ruleSql ? item.ruleSql : ''
                        },
                        { title: '补货算法公式', value: item.countSqlName ? item.countSqlName : '', result: item.countSql ? item.countSql : '' }]
                    }
                }))
            }
            else {
                setAllBreedID(res?.rows[0]?.levelId)
                setAllBreed([{
                    title: '参与补货规则', value: res.rows[0]?.ruleSqlName ? res.rows[0]?.ruleSqlName : '',
                    result: res.rows[0].ruleSql ? res.rows[0]?.ruleSql : ''
                }, {
                    title: '补货算法公式', value: res.rows[0]?.countSqlName ? res.rows[0]?.countSqlName : ''
                    , result: res.rows[0]?.countSql ? res.rows[0]?.countSql : ''
                }])
            }
        })
    }

    // antd组件库中tag组件的使用，功能是点击添加一个tag
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(undefined);
    const editInputRef = useRef(undefined);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            if(!(inputValue.includes('全部品种') || inputValue.includes('暂未分类品种'))){
                setTags([inputValue, ...tags]);
                // 为实现功能添加
                setTagsList([{ title: inputValue, arr: [{ title: '参与补货规则', value: '', result: '' }, { title: '补货算法公式', value: '', result: '' }] }, ...tagslist])
            }else{
                message.warn('新设置的品种分类的名字不能包括全部品种或者暂未分类品种')
            }
        }
        setInputVisible(false);
        setInputValue('');
        if (inputValue !== ''&& !(inputValue.includes('全部品种') || inputValue.includes('暂未分类品种'))) {
            addCategory({
                levelName: inputValue,
                configType: '2',
                publiId: userInfo.publiId,
                channel: undefined,
                isNeedCate: '1',
                ruleSql: undefined,
                ruleSqlName: undefined,
                countSql: undefined,
                countSqlName: undefined,
                channel: salesFunnel
            }).then(res => {
                _maq.trigger(['_trackEvent', `补货配置-添加${inputValue}分类`]); 
                pubilc()
            })
        }
    }

    // 品种是否需要分级操作
    function isGrading(e) {
        setIsGrade(e.target.value);
        let query = {
            id: basicID,
            isNeedCate: e.target.value
        }
        updateInfo(query)
    }
    // 销售渠道改变后相关操作
    function funnelChange(e) {
        setSalesFunnel(e.target.value)
    }

    // 下面是实现点击字段或者符号或者数字时，输入框会出现对应内容
    const [index, setIndex] = useState()
    const [indexInput, setIndexInput] = useState() // 和上面index的值时刻一样
    // 点击输入框的时候需要记住该输入框属于哪个数组，还需要记住索引，通过所以判断属于该数组的第几个元素
    const [name, setName] = useState()
    // 全部品种
    const [allBreed, setAllBreed] = useState([{ title: '参与补货规则', value: '', result: '' }, { title: '补货算法公式', value: '', result: '' }])
    const [tagslist, setTagsList] = useState([{ title: '暂未分类品种', arr: [{ title: '参与补货规则', value: '', result: '' }, { title: '补货算法公式', value: '', result: '' }] }])
    // 实现点击字段或符号或者数字就落到输入框得功能
    const addInputContent = (item, str) => {
        
        if (name == 'allBreed') {
            allBreed[index].value = allBreed[index].value + item
            allBreed[index].result = allBreed[index].result + str
            setAllBreed([...allBreed])
        }
        else {
            for (var i = 0; i < tagslist.length; i++) {
                if (tagslist[i].title == name) {
                    tagslist[i].arr[index].value = tagslist[i].arr[index].value + item
                    tagslist[i].arr[index].result = tagslist[i].arr[index].result + str
                    setTagsList([...tagslist])
                    break;
                }
            }
        }
    }

    // 清空输入框中的公式
    const clear = (index, selectedName, type) => {
        if (selectedName == 'allBreed') {
            allBreed[index].value = ''
            allBreed[index].result = ''
            setAllBreed([...allBreed])
            updateInfo({
                levelId: type,
                ruleSql: index == 0 ? allBreed[index]?.result : undefined,
                ruleSqlName: index == 0 ? allBreed[index]?.value : undefined,
                countSql: index == 1 ? allBreed[index]?.result : undefined,
                countSqlName: index == 1 ? allBreed[index]?.value : undefined,
            })
            _maq.trigger(['_trackEvent', `补货配置-清空${allBreed[index]?.result}`]); 
        }
        else {
            for (var i = 0; i < tagslist.length; i++) {
                if (tagslist[i].title == selectedName) {
                    tagslist[i].arr[index].value = ''
                    tagslist[i].arr[index].result = ''
                    setTagsList([...tagslist])
                    updateInfo({
                        levelId: type,
                        ruleSql: index == 0 ? tagslist[i].arr[index]?.result : undefined,
                        ruleSqlName: index == 0 ? tagslist[i].arr[index]?.value : undefined,
                        countSql: index == 1 ? tagslist[i].arr[index]?.result : undefined,
                        countSqlName: index == 1 ? tagslist[i].arr[index]?.value : undefined,
                    })
                    _maq.trigger(['_trackEvent', `补货配置-清空${tagslist[i].arr[index]?.result}`]); 
                    break;
                }
            }
        }
    }
    // 将校验输入框中字符串交给后端，由后端校验是否合格
    function validateStr(index, selectedName, type) {
        if (selectedName == 'allBreed') {
            
            updateInfo({
                levelId: type,
                ruleSql: index == 0 ? allBreed[index]?.result : undefined,
                ruleSqlName: index == 0 ? allBreed[index]?.value : undefined,
                countSql: index == 1 ? allBreed[index]?.result : undefined,
                countSqlName: index == 1 ? allBreed[index]?.value : undefined,
            })
            _maq.trigger(['_trackEvent', `补货配置-保存${allBreed[index]?.result}`]);
        }
        else {
            for (var i = 0; i < tagslist.length; i++) {
                if (tagslist[i].title == selectedName) {
                    
                    updateInfo({
                        levelId: type,
                        ruleSql: index == 0 ? tagslist[i].arr[index]?.result : undefined,
                        ruleSqlName: index == 0 ? tagslist[i].arr[index]?.value : undefined,
                        countSql: index == 1 ? tagslist[i].arr[index]?.result : undefined,
                        countSqlName: index == 1 ? tagslist[i].arr[index]?.value : undefined,
                    })
                    _maq.trigger(['_trackEvent', `补货配置-保存${tagslist[i].arr[index]?.result}`]); 
                    break;
                }
            }
        }
    }
    function handleChange(e, type) {
        if (type == 'countDay') {
            configArr[0].countDay = e
            setConfigArr([
                ...configArr,
            ])
        } else {
            configArr[0].exportDay = e
            setConfigArr([
                ...configArr,
            ])
        }
    }

    return (
        <div>
            <p>基础信息</p>
            <div className={style.basicTop}>
                <div style={{ display: 'flex', marginBottom: '40px', flexWrap: 'wrap', alignItems: 'baseline' }}>
                    <div className={style.a} style={{ flex: 1 }}>
                        <div className={style.left}>销售统计周期</div>
                        <span style={{ width: '60px', textAlign: 'center' }}>最近</span>
                        <InputNumber style={{ width: '100px', marginRight: '15px' }} value={configArr[0]?.countDay}
                            onChange={(e) => handleChange(e, 'countDay')}
                            onBlur={() => updateInfo({
                                publiId: userInfo.publiId,
                                configType: '2',
                                countDay: configArr[0]?.countDay
                            })} />天
                    </div>
                    <div className={style.a}
                        style={{ flex: 2 }}>
                        <div className={style.left}>导出数据设置</div>
                        <span style={{ width: '100px', textAlign: 'center' }}>导出的数据在</span>
                        <InputNumber style={{ width: '100px', marginRight: '15px' }} value={configArr[0]?.exportDay}
                            onChange={(e) => handleChange(e, 'exportDay')}
                            onBlur={() => updateInfo({
                                publiId: userInfo.publiId,
                                configType: '2',
                                exportDay: configArr[0]?.exportDay
                            })} />天内不参与运算
                    </div>
                </div>
                <div className={style.publicStyle}>
                    <div className={style.left} >销售渠道</div>
                    <Radio.Group options={channelList} onChange={funnelChange} value={salesFunnel} style={{ marginLeft: '15px' }} />
                </div>
                <div className={style.publicStyle}>
                    <div className={style.left} >品种是否需要分类</div>
                    <Radio.Group onChange={isGrading} value={isGrade} style={{ marginLeft: '15px' }}>
                        <Radio value={'1'} style={{ marginRight: '30px' }} >需要</Radio>
                        <Radio value={'0'}>不需要</Radio>
                    </Radio.Group>
                </div>

                {/* 选择需要分类时，需要展示分类的名称 */}
                {
                    // tags[0].levelName != '全部品种'增加该判断条件的原因是，每次由不需要分类切换到需要分类时，tag会先显示全部品种然后才会突然消失
                    isGrade == 1 && tags[0]?.levelName != '全部品种' ?
                        <div className={style.breedStyle}>
                            <div className={style.left}>品种分类</div>
                            <div>
                                {tags.map((tag, index) => {
                                    if (index == (tags.length - 1)) {
                                        return (
                                            <Button size='small' style={{ marginRight: '6px' }}
                                                onDoubleClick={() => {
                                                    tag.type = 'stock';
                                                    tag.channel = salesFunnel;
                                                    
                                                    change('4', tag)
                                                }}
                                            >{tag.levelName}</Button>
                                        )
                                    }
                                    const isLongTag = tag.levelName?.length > 20;
                                    const tagElem = (
                                        <Tag
                                            style={{
                                                userSelect: 'none', fontSize: '14px', marginLeft: "15px",cursor:'pointer'
                                                // border: tagIndex == index  ? '1px solid #1890ff' : undefined
                                            }}
                                            key={tag.levelId}
                                            onDoubleClick={() => {
                                                tag.type = 'stock';
                                                tag.channel = salesFunnel;
                                                change('4', tag)
                                            }}
                                        >
                                            <span style={{ cursor: 'pointer' }}>
                                                {isLongTag ? `${tag.levelName.slice(0, 20)}...` : tag.levelName}
                                            </span>
                                        </Tag>
                                    );
                                    return isLongTag ? (
                                        <Tooltip title={tag.levelName} key={tag.levelId}>
                                            {tagElem}
                                        </Tooltip>
                                    ) : (
                                        tagElem
                                    );
                                })}
                                {inputVisible && (
                                    <Input
                                        ref={inputRef}
                                        type="text"
                                        size="small"
                                        style={{ width: '78px', marginRight: '8px', verticalAlign: 'top' }}
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleInputConfirm}
                                        onPressEnter={handleInputConfirm}
                                    />
                                )}
                                {!inputVisible && (
                                    <>
                                        <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed', fontSize: '14px' }}>
                                            <PlusOutlined /> 添加分类
                                        </Tag>
                                    </>
                                )}
                                <Tag style={{ fontSize: '14px', background: '#1890ff', paddingBottom: '1px', color: 'white', paddingRight: '12px',cursor:'pointer' }}
                                    onClick={() => {setIsOpenFl(true);_maq.trigger(['_trackEvent', `补货配置-点击减少分类`])}}>
                                    <MinusOutlined /> 减少分类
                                </Tag>
                                <div style={{ color: '#C0C0C0', marginTop: '10px', marginLeft: '14px' }}>提示：双击分类后可进入该分类的品种维护页</div>
                            </div>
                        </div> : ''
                }

            </div>
            <p style={{ marginBottom: '15px', marginTop: '15px' }}>规则信息</p>
            <div style={{marginRight:'40px',marginBottom:'20px'}}>
                <div className={style.ruleTop} style={{ paddingLeft: "20px", backgroundColor: '#fafafa' }}>字段</div>
                <div className={style.ruleTop} style={{ display: 'flex', alignItems: 'center', lineHeight: '50px', flexWrap: 'wrap' }}>
                    {
                        field?.map((item, index) => (
                            <div key={index} className={style.fieldStyle}>
                                <Button
                                    onClick={() => {
                                        indexInput && setNoClickFild(true)
                                        setNoClickSym(false)
                                        indexInput && setNoClickNum(true)

                                        indexInput && addInputContent(item.fieldName, item.fieldId)
                                    }}
                                    // disabled={index == indexField ? true : false}>
                                    disabled={noClickField}>
                                    {item.fieldName}
                                </Button>
                            </div>
                        ))
                    }
                </div>
                <div className={style.ruleTop} style={{ paddingLeft: "20px", backgroundColor: '#fafafa' }}>符号</div>
                <div className={style.ruleTop} style={{ display: 'flex', alignItems: 'center', lineHeight: '50px', flexWrap: 'wrap' }}>
                    {
                        symbol1.map((item, index) => (
                            <div key={index} className={style.fieldStyle}>
                                <Button
                                    onClick={() => {
                                        indexInput && setNoClickSym(true)
                                        setNoClickFild(false)
                                        setNoClickNum(false)

                                        indexInput && addInputContent(item, item)
                                    }}
                                    //  disabled={(index == indexSymbol && (item != '(' || item != ')')) ? true : false}
                                    disabled={noClickSym}
                                >{item}</Button>
                            </div>
                        ))
                    }
                    {
                        symbol2.map((item, index) => (
                            <div key={index} className={style.fieldStyle} >
                                <Button
                                    onClick={() => {
                                        indexInput && addInputContent(item, item)
                                        setNoClickNum(false)
                                        setNoClickFild(false)
                                    }}
                                //  disabled={(index == indexSymbol && (item != '(' && item != ')')) ? true : false}
                                >{item}</Button>
                            </div>
                        ))
                    }
                </div>
                <div className={style.ruleTop} style={{ paddingLeft: "20px", backgroundColor: '#fafafa' }}>数字</div>
                <div className={style.ruleTop} style={{ display: 'flex', alignItems: 'center', lineHeight: '50px', flexWrap: 'wrap' }}>
                    {
                        number.map((item, index) => (
                            <div key={index} className={style.fieldStyle}>
                                <Button
                                    onClick={() => {
                                        setIndexNumber(index)
                                        indexInput && setNoClickFild(true)
                                        setNoClickSym(false)
                                        indexInput && addInputContent(item, item)
                                    }}
                                    disabled={noClickNum ? noClickNum : (index == indexNumber && item == '.') ? true : false}
                                >{item}</Button>
                            </div>
                        ))
                    }
                </div>
                <div className={style.ruleTop} style={{ borderBottom: '1px solid #C0C0C0', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div>
                            {
                                //如果选择需要分类的时候，展示自动分类规则       
                                isGrade == 1 ?
                                    <>
                                        {
                                            tagslist.map((item1, index1) => {
                                                return (
                                                    <div style={{ paddingLeft: "20px", marginTop: '26px', marginBottom: '34px', display: 'flex', alignItems: 'baseline' }} key={index1}>
                                                        <div style={{ marginRight: '16px', width: '100px' }}>{item1.title}</div>
                                                        <div>
                                                            {
                                                                item1.arr.map((item2, index2) => (
                                                                    <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '20px' }} key={index2} >
                                                                        <span>{item2.title}</span>
                                                                        <Input.TextArea value={item2.value} autoSize
                                                                            style={{
                                                                                width: item2.title == '参与补货规则' ? '300px' : '500px', marginLeft: '20px',
                                                                                border: name == item1.title && index == index2 ? '1px solid #1890ff' : undefined
                                                                            }}
                                                                            onClick={() => {
                                                                                setIndex(index2)
                                                                                setIndexInput(index2 + '')
                                                                                setName(item1.title)
                                                                            }}>

                                                                        </Input.TextArea>
                                                                        <Button type='primary' ghost size='small'
                                                                            style={{ marginLeft: item2.title == '参与补货规则' ? '240px' : '40px' }}
                                                                            onClick={() => {
                                                                                clear(index2, item1.title, item1.levelId)
                                                                                setNoClickSym(false)
                                                                                setNoClickNum(false)
                                                                                setNoClickFild(false)
                                                                                setIndexInput()
                                                                                setIndex()
                                                                            }}
                                                                        >清空</Button>
                                                                        <Button type='primary' ghost style={{ marginLeft: '20px' }} size='small' onClick={() => {
                                                                            validateStr(index2, item1.title, item1.levelId)
                                                                            setNoClickSym(false)
                                                                            setNoClickNum(false)
                                                                            setNoClickFild(false)
                                                                            setIndexInput()
                                                                            setIndex()
                                                                        }}>保存</Button>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <div style={{ paddingLeft: "20px", marginTop: '26px', marginBottom: '34px', display: 'flex', alignItems: 'baseline' }}>
                                        <div style={{ marginRight: '30px' }}>全部品种</div>
                                        <div>
                                            <>
                                                {
                                                    allBreed.map((item2, index2) => (
                                                        <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '20px' }} key={index2}>
                                                            <span>{item2.title}</span>
                                                            <Input.TextArea value={item2.value} autoSize
                                                                style={{
                                                                    width: item2.title == '参与补货规则' ? '300px' : '500px', marginLeft: '20px',
                                                                    border: name == 'allBreed' && index == index2 ? '1px solid #1890ff' : undefined
                                                                }}
                                                                onClick={() => {
                                                                    setIndex(index2)
                                                                    setIndexInput(index2 + '')
                                                                    setName('allBreed')
                                                                }}>
                                                            </Input.TextArea>
                                                            <Button type='primary' ghost style={{ marginLeft: item2.title == '参与补货规则' ? '240px' : '40px' }} size='small' onClick={() => {
                                                                clear(index2, 'allBreed', allBreedID)
                                                                setNoClickSym(false)
                                                                setNoClickNum(false)
                                                                setNoClickFild(false)
                                                                setIndexInput()
                                                                setIndex()
                                                            }}>清空</Button>
                                                            <Button type='primary' ghost style={{ marginLeft: '20px' }} size='small' onClick={() => {
                                                                validateStr(index2, 'allBreed', allBreedID)
                                                                setNoClickSym(false)
                                                                setNoClickNum(false)
                                                                setNoClickFild(false)
                                                                setIndexInput()
                                                                setIndex()
                                                            }}>保存</Button>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={isOpen} title="提示" width={300} onOk={() => { setIsOpen(false) }} okText='确定' maskClosable={false}
                onCancel={() => setIsOpen(false)}
                footer={[
                    // 定义右下角 按钮的地方 可根据需要使用 一个或者 2个按钮
                    <Button key="submit" type="primary" onClick={() => { setIsOpen(false) }} size='small'>
                        确定
                    </Button>]}
            >
                <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px', marginLeft: '20px', fontSize: '18px' }} />
                <span>该公式校验未通过，保存失败</span>
            </Modal>
            <DeleteFL isOpenFl={isOpenFl}
                onCancel={() => {
                    setIsOpenFl(false)
                    pubilc()
                }}
                allFl={tags.filter(item => {
                    console.log(tags);
                    // return item.levelName != '暂未分类品种'
                    return item.levelName?.indexOf('暂未分类品种') == -1
                })}
            ></DeleteFL>
        </div>
    )
}
