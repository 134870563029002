/* eslint-disable no-undef */
import { Button, Input, Modal, Radio, Select, Table, Pagination, message, Upload } from 'antd'
import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import style from './index.module.css'
import { importSearch, importProd, getAllFl,getAllChannel } from '../../api/importBreed';
import { useEffect } from 'react';
import { getALLQuery } from '../../utils'
import { getAllModuled, importLastTime, databaseField, importTemplate } from '../../api/importData'
import InCustomCol from '../InCustomCol'
import TimeModal from '../TimeModal'
import { ExcelRenderer } from 'react-excel-renderer';
import { downFile } from '../../utils'
const { Search } = Input;

export default function ImportBreed(props) {

    const { visible, onCancel, name } = props
    
    const [channelList, setChannelList] = useState([]) // 所有渠道
    const [importInfo, setImportInfo] = useState([]) // 表格需要渲染的数据
    const [inputValue, setInputValue] = useState(''); // 控制 请输入品种名称或ISBN输入框的值
    const [value1, setValue1] = useState('')
    const [value2, setValue2] = useState('')
    const [value3, setValue3] = useState('')
    const [total, setTotal] = useState() // 后端返回的数据总条数
    const [currentPage, setCurrentPage] = useState(1) // 控制表格的分页显示
    const [selectedFl, setSelectedFl] = useState() // 选中的分类的id
    const [selectedFunnel, setSelectedFunnel] = useState() // 选中的渠道对应的标识，如京东对应jd
    const [allFl, setAllFl] = useState([])

    // 以下为上传表格所需要的所有参数
    const [allModuled, setAllModuled] = useState([]) // 全部模块ID（如品种信息，内部库存数据...）
    const [arrNeed, setArrNeed] = useState([]) // 品种数据中必填字段
    const [allField, setAllField] = useState() // 数据库字段（必填+非必填）
    const [query, setQuery] = useState({}) // 是否要校验库存时间
    const [lastTime, setLastTime] = useState({ pz_fl: '' })
    const [deletionTime, setDeletionTime] = useState('') // 设置缺失日期时间
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false); // 控制时间提示弹框是否展示     
    const [userColumn, setUserColumn] = useState([])// 用户导入得excel表得所有字段
    const [isOpenModal, setIsOpenModal] = useState(false)// 控制自定义配置弹框是否打开   
    const [file, setFile] = useState(null) // 导入表格的所有信息
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 表格批量勾选
    const [fileName, setFileName] = useState(''); // 只有文件上传成功，才会在 上传表格 按钮下方显示 xxxx.xlsx

    const userInfo = getALLQuery()

    const dateSelect = []
    for (var i = 0; i < 60; i++) {
        dateSelect.push({
            label: i + 1,
            value: i + 1
        })
    }

    const columns = [
        {
            title: '排名',
            dataIndex: 'rank',
            align: 'center'
        },
        {
            title: '内部ID',
            dataIndex: 'publiProdId',
            align: 'center'
        },
        {
            title: '书名',
            dataIndex: 'prodName',
            align: 'center',
            width: 400
        },
        {
            title: 'ISBN',
            dataIndex: 'isbn',
            align: 'center',
            width: 150
        },
        {
            title: '作者',
            dataIndex: 'authorName',
            align: 'center'
        },
        {
            title: '定价',
            dataIndex: 'price',
            align: 'center'
        },

    ]

    const [selectValue, setSelectValue] = useState(1) // selectValue 值为1，表示查询维度为销售排行榜，值为2表示查询维度是单品查询

    useEffect(() => {
        if (selectValue == 2 && inputValue == '') {
            setImportInfo([])
        }
        if (!(selectValue == 2 && inputValue == '')) {
            handleSearch()
        }
    }, [currentPage, selectValue]);

    useEffect(() => {
        setFile(null)
        setFileName('')
        setValue1('')
        setValue2('')
        setValue3('')
        setImportInfo([])
        setInputValue('')
        setSelectValue(1)
        setCurrentPage(1)
        setTotal()
        setSelectedFl()
        setSelectedFunnel()
        setSelectedRowKeys([])
        if (name == 'print') {
            pubilc()
        }
        // 获取所有渠道
        if(name === 'stock'){
            getAllChannel({publiId:userInfo.publiId}).then(res=>{
                
                let channelList = []
                res.rows.forEach(element => {
                    channelList.push({
                        label:element.channelName,
                        value:element.channel,
                        isOpen:element.isOpen
                    })
                });
                channelList = channelList.filter(item=>item.isOpen == '1')
                setChannelList([...channelList])
            })
        }
    }, [visible]);
    useEffect(() => {
        setValue1('')
        setValue2('')
        setValue3('')
        setImportInfo([])
        setInputValue('')
        setSelectValue(1)
        setCurrentPage(1)
        setTotal()
    }, [selectedFl]);

    // 获取全部模块ID
    useEffect(() => {
        getLastTime();
        getAllModuled().then(res => {
            let module = res.rows.map(item => {
                return {
                    sheetName: item.sheetName,
                    moduleId: item.moduleId,
                }
            })
            setAllModuled(module)
        })
    }, []);

    // 获取上一次导入数据截止日期
    const getLastTime = () => {
        importLastTime(userInfo.publiId).then(res => {
            
            if (res.code == 200) {
                setLastTime(res.data)
            }
        })
    }

    function pubilc(value) {
        // 查询所有自定义分类
        getAllFl({
            publiId: userInfo.publiId,
            configType: name == 'print' ? '1' : '2',
            channel: value, // 如果value不为undefined，则说明是渠道改变后，再次调用public()，目的是获得对应渠道的所有分类
            creator: userInfo.userId,
            modifier: userInfo.userId,
        }).then(res => {
            
            let arr = res.rows.map(item => {
                return {
                    levelName: item.levelName,
                    levelId: item.levelId
                }
            })
            console.log(res.rows);
            setAllFl([...arr])
        })
    }

    // 查询操作
    async function handleSearch(e, b) {
        
        importSearch({
            pageSize: '10',
            pageNum: currentPage,
            publiId: userInfo.publiId,
            type: selectValue,
            recentDate: selectValue == 1 ? value1 : undefined,
            startRank: selectValue == 1 ? value2 : undefined,
            endRank: selectValue == 1 ? value3 : undefined,
            prodInfo: selectValue == 1 ? undefined : inputValue,
            channel: selectedFunnel,
            configType: name == 'print' ? '1' : '2'
        }).then(res => {
            if (res.code == 200) {
                setImportInfo(res.rows)
                setTotal(res.total)
            } else {
                message.error('查询失败',2)
            }
        })
    }
    // 点击圆圈，切换查询维度
    function handleChange(e) {
        
        setSelectValue(e.target.value)
        setCurrentPage(1) // 切换维度的时候，分页器从第一页开始
        setTotal()
    }
    // 分类改变时相关操作
    function handleFlChange(e) {
        setSelectedFl(e.target.value)
        setFileName('')
    }
    // 渠道改变时相关操作    
    function handleFunnelChange(e) {
        setSelectedFunnel(e.target.value)
        pubilc(e.target.value)
        setSelectedFl()
        setFileName('') // 切换渠道时，上传表格下方之前导入成功的文件名字不显示
    }
    // 全部导入
    async function handleImport() {
        _maq.trigger(['_trackEvent', `品种维护-添加品种-${name == 'print' ? '重印' : '补货'}-全部导入`]); 
        let recentDate = selectValue == 1 ? value1 : undefined
        let startRank = selectValue == 1 ? value2 : undefined
        let endRank = selectValue == 1 ? value3 : undefined
        let prodInfo = selectValue == 1 ? undefined : inputValue
        if (selectedRowKeys.length <=0 ) {
            message.error('请先勾选需要导入的品种', 2);
            return;
        }
        importProd({
            publiId: userInfo.publiId,
            type: selectValue,
            recentDate: recentDate,
            startRank: startRank,
            endRank: endRank,
            prodInfo: prodInfo,
            channel: selectedFunnel,
            creator: userInfo.userId,
            levelId: selectedFl?selectedFl:allFl[0],
            prodList:selectedRowKeys.length?selectedRowKeys+'':undefined,
            configType: name == 'print' ? '1' : '2'
        }).then(res => {
            if (res.msg == '操作成功') {
                message.success('导入成功',2)
                onCancel('ok')
            }
        })
    }
    // 分页变化
    function handleTableChange(e) {
        setCurrentPage(e)
    }

    // 以下为上传表格相关操作
    // 点击上传导入的相关操作
    async function handleClick(item) {
        _maq.trigger(['_trackEvent', `品种维护-添加品种-${name == 'print' ? '重印' : '补货'}-上传表格`]); 
        let singleModule = allModuled.filter(ele => ele.sheetName == item)[0].moduleId
        let moduleResult = allModuled.filter(ele => ele.sheetName == item)
        await databaseField(moduleResult[0]?.moduleId).then(res => {
            let needField = res.rows.filter((item) => {
                return item.isNeed == 1
            }).map((item) => {
                return item.fieldName
            })

            let DatabaseAllField = res.rows.map((item) => {
                return {
                    fieldId: item.fieldId,
                    fieldName: item.fieldName,
                    isNeed: item.isNeed
                }
            })
            setArrNeed(needField)
            setAllField(DatabaseAllField)
        })
        setQuery({
            userId: userInfo.userId,
            moduleId: singleModule,
            publiId: userInfo.publiId,
            lastDate: lastTime && lastTime.pz_fl ? lastTime.pz_fl : '2023-01-01',
            ignore: '1',
            levelId: selectedFl
        })
    }
    const uprops = {
        name: 'file',
        action: '/api/importFieldMapping/importFile',
        headers: {
            authorization: 'authorization-text',
        },
        data: query,
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                
                if (info.file.response.code == 302) {
                    setIsTimeModalOpen(true)
                    message.error(info.file.response.msg,2)
                } else if (info.file.response.code != 200) {
                    message.error(info.file.response.msg,2)
                    // setFile(null)
                    setFileName('')
                } else {
                    getLastTime();
                    console.log(info);
                    setFileName(info.file.name)
                    // setFile([info.file])
                    message.success(!!info.file.response.msg ? (info.file.response.msg == 500 ? '导入成功' : info.file.response.msg) : '导入成功',2);
                }
            } else if (info.file.status === 'error') {
                message.success(`${info.file.name} 上传失败`,2);
                // setFile(null)
                setFileName('')
            }
        },
        async beforeUpload(info) {
            let isMatch = true
            let fileObj = info
            // 若导入的表格后缀为 .csv 相关操作如下
            if ((info.name).indexOf('.csv') > -1) {
                const reader = new FileReader();
                // 将上传的文件读取为文本
                reader.readAsText(info, 'utf-8');
                reader.onload = async function () {
                    var data = reader.result;
                    const json = await csvToJson().fromString(data);
                    let field = Object.keys(json[0])
                    for (var i = 0; i < arrNeed.length; i++) {
                        // 但凡要求的必填字段中有一个不存在于csv文件中，就需要弹出自定义配置对话框
                        if (field.indexOf(arrNeed[i]) == -1) {   // 结果只要有一个满足为-1，for循环停止，弹出自定义配置Modal
                            setIsOpenModal(true)
                            message.error(`${info.name} 文件导入失败，请先进行自定义配置`,2);
                            setMatch(false)
                            setUserColumn(field)
                            break;
                        }
                    }

                };
            } else {
                // 如果文件是 .xlsx文件或者 .xls文件时。相关操作如下
                if((info.name).indexOf('.xlsx') > -1 || (info.name).indexOf('.xls') > -1){
                    await ExcelRenderer(fileObj, (err, resp) => {
                        if (err) {   }
                        else {
                            // 当导入的文件第一行为大标题的时候，我们需要判断第二行所有字段中是否都有导入时要求的必填字段
                            if (resp.rows[0].length == 1) {
                                for (var i = 0; i < arrNeed.length; i++) {
                                    if (resp.rows[1].indexOf(arrNeed[i]) == -1) {   // 结果只要有一个满足为-1，for循环停止，弹出自定义配置Modal
                                        setIsOpenModal(true)
                                        message.error(`${info.name} 文件导入失败，请先进行自定义配置`,2);
                                        isMatch = false
                                        setUserColumn(resp.rows[1])
                                        break;
                                    }
                                }
                            }
                            // 当导入的文件第一行不是大标题，即第一行就是该文件所包含的所有字段
                            else {
                                for (let i = 0; i < arrNeed.length; i++) {
                                    if (resp.rows[0].indexOf(arrNeed[i]) == -1) {
                                        message.error(`${info.name} 文件导入失败，请先进行自定义配置`,2);
                                        setIsOpenModal(true)
                                        isMatch = false
                                        setUserColumn(resp.rows[0])
                                        break;
                                    }
                                }
                            }
                        }
                    });
                }else{
                    message.warning('导入的文件格式不正确！',2)
                }
            }
            setFile([info]) // 获取文件流传给子组件           
            return isMatch || Upload.LIST_IGNORE;
        }
    };
    // 关闭莫太快
    const onCancel1 = (val) => {
        setIsTimeModalOpen(false)
    }

    // 表格批量勾选
    const onSelectChange = (selectKeys) => { 
        setSelectedRowKeys([...selectKeys])
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
    };

    const handleDownTemp = () => {
        importTemplate({'moduleId': 'pz'}).then((res) => {
            message.success('请耐心等待下载完成',2)
            downFile(res)
        })
    }
    return (
        <Modal 
            maskClosable={false} 
            width={1000} 
            open={visible} 
            title='增加品种' 
            onCancel={onCancel} 
            okText='全部导入' 
            cancelText='取消'
            onOk={() => handleImport()}
            destroyOnClose={true}
        >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', position: 'relative' }}>
                <div style={{ flex: 4 }}>
                    {
                        name == 'stock' ?
                            <div className={style.publicStyle}>
                                <Radio.Group options={channelList} onChange={handleFunnelChange} value={selectedFunnel} />
                                <div style={{ color: '#C0C0C0', marginLeft: '14px' }}>提示：请选择渠道及分类后再上传或查询品种</div>
                            </div> : null
                    }
                    {
                        name == 'print' ?
                            <div className={style.publicStyle}>
                                <Radio.Group size='small' onChange={handleFlChange} value={selectedFl}>
                                    {
                                        allFl?.map((item, idx) => (
                                            <Radio.Button 
                                                key={idx} 
                                                value={item.levelId} 
                                                disabled={`${item.levelName}`.includes('暂未分类品种') || `${item.levelName}`.includes('全部品种')}
                                                style={{ height: '34px', lineHeight: '34px' }}
                                            >
                                                {item.levelName}
                                            </Radio.Button>
                                        ))
                                    }
                                </Radio.Group>
                                <div style={{ color: '#C0C0C0', marginLeft: '14px' }}>提示：请选择分类后再上传或查询品种</div>
                            </div> : (
                                selectedFunnel ?
                                    <div className={style.publicStyle}>
                                        <Radio.Group size='small' onChange={handleFlChange} value={selectedFl}>
                                            {
                                                allFl?.map((item,idx) => (
                                                    <Radio.Button 
                                                        disabled={`${item.levelName}`.includes('暂未分类品种') || `${item.levelName}`.includes('全部品种')}
                                                        value={item.levelId} 
                                                        key={idx} 
                                                        style={{ height: '34px', lineHeight: '34px' }}
                                                    >
                                                        {item.levelName}
                                                    </Radio.Button>
                                                ))
                                            }
                                        </Radio.Group>
                                    </div> : null
                            )
                    }
                    <div className={style.publicStyle}>
                        <span>查询品种库：</span>
                        <Radio.Group style={{ marginLeft: '15px' }} onChange={handleChange} value={selectValue}>
                            <Radio value={1} style={{ marginRight: '30px' }}>销售排行榜</Radio>
                            <Radio value={2}>单品查询</Radio>
                        </Radio.Group>
                    </div>
                    {
                        selectValue == 1 ?
                            <div style={{ display: 'flex', alignItems: "center" }}>
                                <div className={style.div}>最近</div>
                                <div>
                                    <Select options={dateSelect} size='small' style={{ width: '60px' }} onBlur={() => { handleSearch() }}
                                        onChange={(e) => { setValue1(e) }}
                                        value={value1}
                                        disabled={name == 'print' ? (selectedFl ? false : true) : (selectedFunnel && selectedFl ? false : true)}
                                    />
                                </div>
                                <div className={style.div} >天本社</div>
                                <div>销售</div>
                                <div className={style.div}>排行第</div>
                                <div>
                                    <Input style={{ width: '50px', height: '24px' }} onBlur={() => { handleSearch() }} onChange={(e) => { setValue2(e.target.value) }}
                                        value={value2}
                                        disabled={name == 'print' ? (selectedFl ? false : true) : (selectedFunnel && selectedFl ? false : true)}
                                    >
                                    </Input></div>
                                <div className={style.div}>-</div>
                                <div>
                                    <Input style={{ width: '50px', height: '24px' }} onBlur={() => { handleSearch() }} onChange={(e) => { setValue3(e.target.value) }}
                                        value={value3}
                                        disabled={name == 'print' ? (selectedFl ? false : true) : (selectedFunnel && selectedFl ? false : true)}
                                    >
                                    </Input>
                                </div>
                                <div style={{ marginLeft: '8px' }}>未分类品种</div>
                            </div> :
                            <div>
                                <Search
                                    placeholder="请输入品种名称或ISBN"
                                    onSearch={() => { handleSearch() }}
                                    value={inputValue}
                                    disabled={name == 'print' ? (selectedFl ? false : true) : (selectedFunnel && selectedFl ? false : true)}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    size='middle'
                                    style={{
                                        width: 200,
                                    }}
                                />
                            </div>
                    }
                </div>
                <div 
                    style={{
                        position: 'absolute', 
                        right: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    }}
                >
                    <div>
                        <Upload
                            accept='.xls,.xlsx'
                            maxCount={1}
                            showUploadList={false}
                            {...uprops}
                        >
                            <Button type="primary" style={{ width: '110px'}}
                                disabled={name == 'print' ? (selectedFl ? false : true) : (selectedFunnel && selectedFl ? false : true)}
                                onClick={() => handleClick('品种分类数据')}
                            >上传表格</Button>
                        </Upload>
                        <Button type='link' onClick={handleDownTemp}>下载模板</Button>
                        {
                            fileName !== '' ? <div style={{marginTop:'10px'}}>
                                <CheckCircleOutlined style={{color:'#54BCBD',marginRight:'10px'}}/>
                                <span style={{color:'#A9A9A9'}}>{fileName}</span>
                            </div> : null
                        }
                    </div>

                    <p style={{color: '#B9B9B9', fontSize: '14px',marginTop:'6px'}}>注意：导入表格后将直接添加至该分类中</p>
                </div>
            </div>
            <Table columns={columns} dataSource={importInfo} bordered={true} pagination={false} size='small' 
                rowSelection={rowSelection} 
                rowKey={record=>record.prodId}
            />
            <div className={style.pagination}>
                <Pagination total={total} hideOnSinglePage={total ? false : true} current={currentPage} defaultPageSize={10} pageSizeOptions={[10]} showSizeChanger={true}
                    onChange={(e) => (handleTableChange(e))} />
            </div>
            <InCustomCol
                visible={isOpenModal}
                name='品种分类数据'
                onCancel={(val) => {
                    console.log(val);
                    // setFile([val]);
                    setFileName(val)
                    setIsOpenModal(false)
                }}
                userColumn={userColumn}
                allField={allField}
                file={file}
                allModuled={allModuled}
                query={query}
            />
            <TimeModal
                visible={isTimeModalOpen}
                isPost={false}
                deletionTime={deletionTime}
                onCancel={(val) => onCancel1(val)}
            />
        </Modal>
    )
}