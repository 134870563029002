import { requests, exportFile, exportFilePost } from "../utils/http";

export const getVarietyList = (query) => {
    return requests({
        url: "/prodDetail/selectList",
        method: 'get',
        params: query
    })
}

export const deleteItemByIds = (query) => {
    return requests({
        url: "/mapping/deleteByIds",
        method: 'get',
        params: query,
        headers: {"Content-Type":"multipart/form-data"},
    })
}

export const exportsResult = (query) => {
    return exportFile("/api/prodDetail/export", query)
}

export const update = (data) => {
    return requests({
        url: "/prodDetail/update",
        method: 'post',
        data,
        headers: {"Content-Type":"application/json"},
    })
}

export const allDel = (data) => {
    return requests({
        url: "/mapping/deleteByLevelId",
        method: 'post',
        data,
        headers: {"Content-Type":"application/json"},
    })
}