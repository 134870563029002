/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.css'
import { Button, Input, Radio, Tag, Tooltip, Modal, message, Upload, InputNumber, Checkbox } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { fieldConfig, publiSystemConfig, addCategory, deleteCategory, update } from '../../../../api/config'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getALLQuery } from '../../../../utils/index';
import DeleteFL from '../../../../components/DeleteFL'

export default function ReprintConfig({ change }) {
    const [isGrade, setIsGrade] = useState(1); // isGrade为'1'，表示需要分类，'0'不需要分类，控制品种分类的动态显示
    const userInfo = getALLQuery() // 获取出版端中金易云通过iframe框架传过来的地址栏中参数
    const [field, setField] = useState([]) // 控制规则信息中字段部分的展示
    const symbol1 = [' + ', ' - ', ' * ', ' / ', ' > ', ' >= ', ' < ', ' <= ', ' = ', ' and ', ' or ']
    const symbol2 = [' ( ', ' ) ']
    const number = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
    const [indexNumber, setIndexNumber] = useState() // 控制用户不能连续点击 . ，但是可以连续点击两个一样的数字
    const [noClickField, setNoClickFild] = useState(false)
    const [noClickSym, setNoClickSym] = useState(false)
    const [noClickNum, setNoClickNum] = useState(false)
    const [configArr, setConfigArr] = useState([]) // 保存的是重印配置页面所有的参数信息
    const [basicID, setBasicID] = useState() // 基础信息自增ID     
    const [allBreedID, setAllBreedID] = useState() // 当查询系统基础配置信息，得到不需要分类时，需要单独记一下 全部品种 这个分类的levelId
    const [isOpenFl, setIsOpenFl] = useState(false) // 点击减少分类按钮后弹出减少分类对话框
    const [isOpen, setIsOpen] = useState(false)// 保存时，校验失败弹出提示框
    const [minPrint, setMinPrint ] = useState([]) // 起印数数组
    const [isRounds, setIsRounds ] = useState([])

    // 获取规则信息中所有的字段
    useEffect(() => { // 'dws_print'
        fieldConfig({moduleId:'dws_print',publiId:userInfo.publiId}).then(res => {
            setField(res.rows)
        })
        pubilc()
    }, [])

    // 页面任何地方的修改都会调用updataInfo方法

    const updateInfo = (query, type) => {
        update({
            ...query,
            modifier: userInfo.userId
        }).then(res => {
            if (res.code == 200) {
                message.success({
                    content: '保存成功',
                    className: 'u-minPrint-message',
                    duration: 2
                })
                if (type != 'isRound') {
                    pubilc()
                }
                
            }
            if (res.code == 301) {
                // 校验失败    
                message.success('修改失败',2)
                setIsOpen(true)
            }
        })
    }

    // 查询基础信息配置与自定义分类规则
    function pubilc() {
        publiSystemConfig({
            publiId: userInfo.publiId,
            configType: '1',
            channel: '0',
            creator: userInfo.userId,
            modifier: userInfo.userId,
        }).then(res => {
            setConfigArr([...res.rows])
            console.log(res.rows, '收到得配置')
            setIsGrade(res?.rows[0]?.isNeedCate) // 品种是否需要分类
            setBasicID(res?.rows[0]?.id) // 修改基础信息中品种是否需要分类时，调用修改接口需要传入id，所以在此处获取id
            setTags(res.rows.map(item => { // 获取基础信息中品种分类所需要渲染的所有分类的数据
                return {
                    levelName: item.levelName,
                    levelId: item.levelId
                }
            }))
            if (res?.rows[0]?.isNeedCate == 1) {
                setTagsList(res.rows.map(item => {
                    return {
                        title: item.levelName,
                        levelId: item.levelId,
                        arr: [
                            {
                                title: '参与重印规则',
                                value: item.ruleSqlName ? item.ruleSqlName : '', result: item.ruleSql ? item.ruleSql : ''
                            },
                            { title: '重印算法公式', value: item.countSqlName ? item.countSqlName : '', result: item.countSql ? item.countSql : '' },
                        ]
                    }
                }))
                setMinPrint(res?.rows.map(item => item.minPrintNum))
                setIsRounds(res?.rows.map(item => `${item.isRound}`))
                console.log(isRounds, minPrint, '99999999')
            }
            else {
                // 当查询基础信息系统配置接口返回不需要分类时，res.rows里面只有全部品种
                setAllBreedID(res?.rows[0]?.levelId)
                setAllBreed([{
                    title: '参与重印规则', value: (res?.rows[0]?.ruleSqlName) ? (res?.rows[0]?.ruleSqlName) : '',
                    result: (res?.rows[0]?.ruleSql) ? (res?.rows[0]?.ruleSql) : ''
                }, {
                    title: '重印算法公式', value: (res?.rows[0]?.countSqlName) ? (res?.rows[0]?.countSqlName) : ''
                    , result: (res?.rows[0]?.countSql) ? (res?.rows[0]?.countSql) : ''
                }])
                setMinPrint([res?.rows[0].minPrintNum])
                setIsRounds([res?.rows[0].isRound])
                console.log(isRounds, '8888888')
            }
        })
    }

    // antd组件库中tag组件的使用，功能是点击添加图标增加一个tag，每个变量的使用都可以去看antd中tag组件的文档
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(undefined);
    const editInputRef = useRef(undefined);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => { 
        if (inputValue && tags.indexOf(inputValue) === -1) {
            if(!(inputValue.includes('全部品种') || inputValue.includes('暂未分类品种'))){
                setTags([inputValue, ...tags]);
                // 为实现功能添加      
                setTagsList([{ title: inputValue, arr: [{ title: '参与重印规则', value: '', result: '' }, { title: '重印算法公式', value: '', result: '' }] }, ...tagslist])
            }else{
                message.warn('新设置的品种分类的名字不能包括全部品种或者暂未分类品种')
            }
        }
        setInputVisible(false);
        setInputValue('');
        // 如果点击添加分类按钮后，在动态显示的输入框中输入的内容不能为空，且新起的分类的名字中不能包含全部品种或者暂未分类品种字眼，
        // 否则无法调用添加分类的接口
        if (inputValue !== '' && !(inputValue.includes('全部品种') || inputValue.includes('暂未分类品种'))) {
            addCategory({
                levelName: inputValue,
                configType: '1',
                publiId: userInfo.publiId,
                channel: undefined,
                isNeedCate: '1',
                ruleSql: undefined,
                ruleSqlName: undefined,
                countSql: undefined,
                countSqlName: undefined,
            }).then(res => {
                _maq.trigger(['_trackEvent', `重印配置-添加${inputValue}分类`]); 
                pubilc()
            })
        }
    }


    // 品种是否需要分类操作
    function isGrading(e) {
        setIsGrade(e.target.value);
        let query = {
            id: basicID,
            isNeedCate: e.target.value
        }
        updateInfo(query)
    }

    // 下面是实现点击字段或者符号或者数字时，输入框会出现对应内容
    const [index, setIndex] = useState()  // 参与重印规则或者重印算法公式对应输入框的索引，值为0或者1
    const [indexInput, setIndexInput] = useState() // 和上面index的值时刻一样
    // 点击输入框的时候需要记住该输入框属于哪个数组，还需要记住索引，通过所以判断属于该数组的第几个元素
    const [name, setName] = useState()
    // 全部品种
    const [allBreed, setAllBreed] = useState([{ title: '参与重印规则', value: '', result: '' }, { title: '重印算法公式', value: '', result: '' }])
    const [tagslist, setTagsList] = useState([{ title: '暂未分类品种', arr: [{ title: '参与重印规则', value: '', result: '' }, { title: '重印算法公式', value: '', result: '' }] }])
    // 实现点击字段或符号或者数字就落到输入框得功能
    const addInputContent = (item, str) => {
        if (name == 'allBreed') {
            allBreed[index].value = allBreed[index].value + item
            allBreed[index].result = allBreed[index].result + str
            setAllBreed([...allBreed])
        }
        else {
            for (var i = 0; i < tagslist.length; i++) {
                if (tagslist[i].title == name) {
                    tagslist[i].arr[index].value = tagslist[i].arr[index].value + item
                    tagslist[i].arr[index].result = tagslist[i].arr[index].result + str
                    setTagsList([...tagslist])
                    break;
                }
            }
        }
    }

    // 清空输入框中的公式
    const clear = (index, selectedName, type) => {
        // 如果重印配置显示不需要分类的时候，此时只有全部品种
        if (selectedName == 'allBreed') {
            allBreed[index].value = ''
            allBreed[index].result = ''
            
            setAllBreed([...allBreed])
            updateInfo({
                levelId: type, // 需要清空的分类的levelId
                // 清空参与重印规则或者重印算法公式中任何一个的时候，没被清空的那一个都传undefined,被清空的那个都传''
                ruleSql: index == 0 ? '' : undefined,
                ruleSqlName: index == 0 ? '' : undefined,
                countSql: index == 1 ? '' : undefined,
                countSqlName: index == 1 ? '' : undefined,
            })
            _maq.trigger(['_trackEvent', `重印配置-清空${allBreed[index]?.result}`]); 
        }
        else {
            for (var i = 0; i < tagslist.length; i++) {
                if (tagslist[i].title == selectedName) {
                    tagslist[i].arr[index].value = ''
                    tagslist[i].arr[index].result = ''
                    setTagsList([...tagslist])
                    updateInfo({
                        levelId: type,
                        ruleSql: index == 0 ? '' : undefined,
                        ruleSqlName: index == 0 ? '' : undefined,
                        countSql: index == 1 ? '' : undefined,
                        countSqlName: index == 1 ? '' : undefined,
                    })
                    _maq.trigger(['_trackEvent', `重印配置-清空${tagslist[i].arr[index]?.result}`]); 
                    break;
                }
            }
        }
    }
    // 点击保存按钮的时候，将输入框中字符串交给后端，由后端校验是否合格  
    function validateStr(index, selectedName, type) {
        if (selectedName == 'allBreed') { // 当分类只有全部品种时，操作如下
            if (minPrint[0] == '0') {
                return false
            }
            updateInfo({
                levelId: type,
                // 保存参与重印规则或者重印算法公式中任何一个的时候，没被修改的那一个不需要点击保存，且都传undefined,
                // 需要保存的的那个都传输入框中的值
                ruleSql: index == 0 ? allBreed[index]?.result : undefined,
                ruleSqlName: index == 0 ? allBreed[index]?.value : undefined,
                countSql: index == 1 ? allBreed[index]?.result : undefined,
                countSqlName: index == 1 ? allBreed[index]?.value : undefined,
                minPrintNum: minPrint[0]
            })
            _maq.trigger(['_trackEvent', `重印配置-保存${allBreed[index]?.result}`]); 
        }
        else {

            for (var i = 0; i < tagslist.length; i++) {
                if (tagslist[i].title == selectedName) {
                    if (minPrint[i] == '0') {
                        return false
                    }
                    updateInfo({
                        levelId: type,
                        ruleSql: index == 0 ? tagslist[i].arr[index]?.result : undefined,
                        ruleSqlName: index == 0 ? tagslist[i].arr[index]?.value : undefined,
                        countSql: index == 1 ? tagslist[i].arr[index]?.result : undefined,
                        countSqlName: index == 1 ? tagslist[i].arr[index]?.value : undefined,
                        minPrintNum: minPrint[i]
                    })
                    break;
                }
            }
            _maq.trigger(['_trackEvent', `重印配置-保存${tagslist[i].arr[index]?.result}`]); 
        }
        
    }
    
    // 当销售统计周期和导出数据设置中的值改变的时候，操作如下
    function handleChange(e, type) {
        if (type == 'countDay') {
            configArr[0].countDay = e
            setConfigArr([
                ...configArr,
            ])
        } else {
            configArr[0].exportDay = e
            setConfigArr([
                ...configArr,
            ])
        }
    }

    // 切换是否需要四舍五入
    const onChangeRounding = (e, idx, id) => {
        let arr = [...isRounds]
        arr[idx] = e.target.checked
        setIsRounds([...arr])
        updateInfo({
            levelId: id,
            isRound: e.target.checked == true ? '1' : '0'
        }, 'isRound')
    }

    const onChangeMinPrint =(val, index1) => {
        let minArr = [...minPrint];
        minArr[index1] = val;
        setMinPrint(minArr)
    }

    const handleKeyPress = (event) => {
        const invalidChars = ['-', '+', 'e', '.', 'E'];
        if (invalidChars.indexOf(event.key) !== -1) {
            event.preventDefault()
        }
    }
    return (
        <div>
            <p>基础信息</p>
            <div className={style.basicTop}>
                <div style={{ display: 'flex', marginBottom: '40px', flexWrap: 'wrap', alignItems: 'baseline' }}>
                    <div className={style.a} style={{ flex: 1 }}>
                        <div className={style.left}>销售统计周期</div>
                        <span style={{ width: '60px', textAlign: 'center' }}>最近</span>
                        <InputNumber style={{ width: '100px', marginRight: '15px' }} value={configArr[0]?.countDay}
                            onChange={(e) => handleChange(e, 'countDay')}
                            onBlur={() => updateInfo({
                                publiId: userInfo.publiId,
                                configType: '1',
                                countDay: configArr[0]?.countDay
                            })} 
                        />天
                    </div>
                    <div className={style.a}
                        style={{ flex: 2 }}>
                        <div className={style.left}>导出数据设置</div>
                        <span style={{ width: '100px', textAlign: 'center' }}>导出的数据在</span>
                        <InputNumber style={{ width: '100px', marginRight: '15px' }}
                            value={configArr[0]?.exportDay}
                            onChange={(e) => handleChange(e, 'exportDay')}
                            onBlur={() => updateInfo({
                                publiId: userInfo.publiId,
                                configType: '1',
                                exportDay: configArr[0]?.exportDay
                            })} 
                        />天内不参与运算
                    </div>
                </div>
                <div className={style.publicStyle}>
                    <div className={style.left} >品种是否需要分类</div>
                    <Radio.Group onChange={isGrading} value={isGrade} style={{ marginLeft: '15px' }}>
                        <Radio value={'1'} style={{ marginRight: '30px' }} >需要</Radio>
                        <Radio value={'0'}>不需要</Radio>
                    </Radio.Group>
                </div>

                {/* 选择需要分类时，需要展示分类的名称 */}
                {
                    isGrade == 1 && tags[0]?.levelName != '全部品种' ?
                        <div className={style.breedStyle}>
                            <div className={style.left}>品种分类</div>
                            <div>
                                {tags.map((tag, index) => {
                                    if (index == (tags.length - 1)) {
                                        return (
                                            <Button size='small' style={{ marginRight: '6px' }} key={tag.levelId}
                                               // 双击某一个分类的时候跳转到品种维护的重印页面 
                                                onDoubleClick={() => {
                                                    tag.type = 'print'
                                                    change('4', tag)
                                                }}
                                            >{tag.levelName}</Button>
                                        )
                                    }
                                    const isLongTag = tag.levelName?.length > 20;
                                    const tagElem = (
                                        <Tag
                                            style={{
                                                userSelect: 'none', 
                                                fontSize: '14px',
                                                marginLeft: "15px",
                                                cursor:'pointer'
                                            }}
                                            key={tag.levelId}
                                            onDoubleClick={() => {
                                                tag.type = 'print'
                                                change('4', tag)
                                            }}
                                        >
                                            <span style={{cursor: 'pointer'}}>
                                                {isLongTag ? `${tag.levelName.slice(0, 20)}...` : tag.levelName}
                                            </span>
                                        </Tag>
                                    );
                                    return isLongTag ? (
                                        <Tooltip title={tag.levelName} key={tag.levelId}>
                                            {tagElem}
                                        </Tooltip>
                                    ) : (
                                        tagElem
                                    );
                                })
                                }
                                {inputVisible && (
                                    <Input
                                        ref={inputRef}
                                        type="text"
                                        size="small"
                                        style={{ width: '78px', marginRight: '8px', verticalAlign: 'top' }}
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleInputConfirm}
                                        onPressEnter={handleInputConfirm}
                                    />
                                )}
                                {!inputVisible && (
                                    <>
                                        <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed', fontSize: '14px' }}>
                                            <PlusOutlined /> 添加分类
                                        </Tag>
                                    </>
                                )}
                                <Tag style={{ fontSize: '14px', background: '#1890ff', paddingBottom: '1px', color: 'white', paddingRight: '12px',cursor:'pointer' }}
                                    onClick={() => {setIsOpenFl(true); _maq.trigger(['_trackEvent', `重印配置-点击减少分类`]);}}>
                                    <MinusOutlined /> 减少分类
                                </Tag>
                                <div style={{ color: '#C0C0C0', marginTop: '10px', marginLeft: '14px' }}>提示：双击分类后可进入该分类的品种维护页</div>
                            </div>
                        </div> : ''
                }
            </div>
            <p style={{ marginBottom: '15px', marginTop: '15px' }}>规则信息</p>
            <div style={{marginRight:'40px',marginBottom:'20px'}}>
                <div className={style.ruleTop} style={{ paddingLeft: "20px", backgroundColor: '#fafafa' }}>字段</div>
                <div className={style.ruleTop} style={{ display: 'flex', alignItems: 'center', lineHeight: '50px', flexWrap: 'wrap' }}>
                    {
                        field?.map((item, index) => (
                            <div key={index} className={style.fieldStyle}>
                                <Button
                                    onClick={() => {
                                        // 必须先选中参与重印规则或者重印算法公式中的输入框，即indexInput必须有值，
                                        // 此时，一旦点击了字段中某一个值，该字段中所有值都得禁用，因为不能连续点击两个字段中的值
                                        // 且数字中所有值都得禁用，因为不能字段+数字的公式明显错误，如（当前库存数 4 ）
                                        // 符号领域不需要禁用，所以 setNoClickSym(false)
                                        indexInput && setNoClickFild(true)
                                        setNoClickSym(false)
                                        indexInput && setNoClickNum(true)
                                        indexInput && addInputContent(item.fieldName, item.fieldId)
                                    }}
                                    disabled={noClickField}
                                >
                                    {item.fieldName}
                                </Button>
                            </div>
                        ))
                    }
                </div>
                <div className={style.ruleTop} style={{ paddingLeft: "20px", backgroundColor: '#fafafa' }}>符号</div>
                <div className={style.ruleTop} style={{ display: 'flex', alignItems: 'center', lineHeight: '50px', flexWrap: 'wrap' }}>
                    {
                        symbol1.map((item, index) => (
                            <div key={index} className={style.fieldStyle}>
                                <Button
                                    onClick={() => {
                                        // 点击了除 '(' 和 ')' 以外的符号之后，所有符号都需要禁用
                                        indexInput && setNoClickSym(true)
                                        setNoClickFild(false)
                                        setNoClickNum(false)
                                        indexInput && addInputContent(item, item)
                                    }}
                                    disabled={noClickSym}
                                >{item}</Button>
                            </div>
                        ))
                    }
                    {
                        symbol2.map((item, index) => (
                            <div key={index} className={style.fieldStyle} >
                                <Button
                                    onClick={() => { 
                                        indexInput && addInputContent(item, item)
                                        // 点击了 '(' 或 ')' 中任何一个符号，除了这两个符号以外的其他符号都得禁用，但是所有字段和数字都不禁用
                                        setNoClickNum(false)
                                        setNoClickFild(false)
                                    }}
                                >{item}</Button>
                            </div>
                        ))
                    }
                </div>
                <div className={style.ruleTop} style={{ paddingLeft: "20px", backgroundColor: '#fafafa' }}>数字</div>
                <div className={style.ruleTop} style={{ display: 'flex', alignItems: 'center', lineHeight: '50px', flexWrap: 'wrap' }}>
                    {
                        number.map((item, index) => (
                            <div key={index} className={style.fieldStyle}>
                                <Button
                                    onClick={() => {
                                        setIndexNumber(index) // 保存 . 对应的索引，当index == indexNumber && item == '.'就禁用 . ,因为不能同时连续点击两个 .
                                        setNoClickSym(false)
                                        indexInput && setNoClickFild(true)
                                        indexInput && addInputContent(item, item)
                                    }}
                                    disabled={noClickNum ? noClickNum : (index == indexNumber && item == '.') ? true : false}
                                >{item}</Button>
                            </div>
                        ))
                    }
                </div>
                <div className={style.ruleTop} style={{ borderBottom: '1px solid #C0C0C0', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div>
                            {
                                //如果选择需要分类的时候，展示自动分类规则       
                                isGrade == 1 ?
                                    <>
                                        {
                                            tagslist.map((item1, index1) => {
                                                return (
                                                    <div style={{ paddingLeft: "20px", marginTop: '26px', marginBottom: '34px', display: 'flex', alignItems: 'baseline' }} key={index1}>
                                                        <div style={{ marginRight: '16px', width: '100px' }}>{item1.title}</div>
                                                        <div>
                                                            {
                                                                item1.arr.map((item2, index2) => (
                                                                    <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '20px' }} key={index2} >
                                                                        <span>{item2.title}</span>
                                                                        <Input.TextArea value={item2.value} autoSize
                                                                            style={{
                                                                                width: item2.title == '参与重印规则' ? '300px' : '500px', marginLeft: '20px',
                                                                                border: name == item1.title && index == index2 ? '1px solid #1890ff' : undefined
                                                                            }}
                                                                            onClick={() => {
                                                                                setIndex(index2)
                                                                                setIndexInput(index2 + '')
                                                                                setName(item1.title)
                                                                            }}>

                                                                        </Input.TextArea>
                                                                        {
                                                                            item2.title == '参与重印规则' &&
                                                                            <div style={{ width: '200px', marginLeft: '30px' }}>
                                                                                <span style={{ marginRight: "10px" }}>最小起印数</span>
                                                                                <InputNumber
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={minPrint[index1]}
                                                                                    onKeyPress={handleKeyPress}
                                                                                    onChange={(val) =>onChangeMinPrint(val,index1)}
                                                                                />
                                                                                <div 
                                                                                    style={{
                                                                                        marginTop: '4px', 
                                                                                        color: 'red',
                                                                                        display: minPrint[index1] == '0' ? 'block' : 'none'
                                                                                    }}
                                                                                >最小起印数不能小于等于0</div>
                                                                            </div>
                                                                        }
                                                                        <Button type='primary' ghost size='small'
                                                                            style={{ marginLeft: item2.title == '参与重印规则' ? '10px' : '40px' }}
                                                                            onClick={() => {
                                                                                clear(index2, item1.title, item1.levelId)
                                                                                // 点击清空按钮后，字段，数字，符号都不再禁用
                                                                                setNoClickSym(false)
                                                                                setNoClickNum(false)
                                                                                setNoClickFild(false)
                                                                                setIndexInput()
                                                                                setIndex()
                                                                            }}
                                                                        >清空</Button>
                                                                        <Button type='primary' ghost style={{ marginLeft: '20px' }} size='small' onClick={() => {
                                                                            validateStr(index2, item1.title, item1.levelId)
                                                                            // 点击保存按钮后，字段，数字，符号都不再禁用，且参与重印规则或重印算法公示的输入框不在高亮
                                                                            setNoClickSym(false)
                                                                            setNoClickNum(false)
                                                                            setNoClickFild(false)
                                                                            setIndexInput()
                                                                            setIndex()
                                                                        }}>保存</Button>
                                                                    </div>
                                                                ))
                                                            }
                                                            <div style={{ display: "block" }}>
                                                                <span>预测数显</span>
                                                                <Checkbox 
                                                                    style={{ marginLeft: "50px" }} 
                                                                    checked={isRounds[index1] == '0' ? false : true}
                                                                    onChange={(val) => onChangeRounding(val, index1, item1?.levelId)}
                                                                >四舍五入到百位</Checkbox>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <div style={{ paddingLeft: "20px", marginTop: '26px', marginBottom: '34px', display: 'flex', alignItems: 'baseline' }}>
                                        <div style={{ marginRight: '30px' }}>全部品种</div>
                                        <div>
                                            <>
                                                {
                                                    allBreed.map((item2, index2) => (
                                                        <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '20px' }} key={index2}>
                                                            <span>{item2.title}</span>
                                                            <Input.TextArea value={item2.value} autoSize
                                                                style={{
                                                                    width: item2.title == '参与重印规则' ? '300px' : '500px', marginLeft: '20px',
                                                                    border: name == 'allBreed' && index == index2 ? '1px solid #1890ff' : undefined
                                                                }}
                                                                onClick={() => {
                                                                    setIndex(index2)
                                                                    setIndexInput(index2 + '')
                                                                    setName('allBreed')
                                                                }}>
                                                            </Input.TextArea>
                                                            {
                                                                item2.title == '参与重印规则' &&
                                                                <div style={{ width: '200px', marginLeft: '30px' }}>
                                                                    <span style={{ marginRight: "10px" }}>最小起印数</span>
                                                                    <InputNumber
                                                                        min="0"
                                                                        type="number"
                                                                        value={minPrint[0]}
                                                                        onKeyPress={handleKeyPress}
                                                                        onChange={(val) =>onChangeMinPrint(val, 0)}
                                                                    />
                                                                    <div 
                                                                        style={{
                                                                            marginTop: '4px',
                                                                            color: 'red',
                                                                            display: minPrint[0] == '0' ? 'block' : 'none'
                                                                        }}
                                                                    >最小起印数不能小于等于0</div>
                                                                </div>
                                                            }
                                                            <Button type='primary' ghost style={{ marginLeft: item2.title == '参与重印规则' ? '10px' : '40px' }} size='small' onClick={() => {
                                                                clear(index2, 'allBreed', allBreedID)
                                                                setNoClickSym(false)
                                                                setNoClickNum(false)
                                                                setNoClickFild(false)
                                                                setIndexInput()
                                                                setIndex()
                                                            }}>清空</Button>
                                                            <Button type='primary' ghost style={{ marginLeft: '20px' }} size='small' onClick={() => {
                                                                validateStr(index2, 'allBreed', allBreedID)
                                                                setNoClickSym(false)
                                                                setNoClickNum(false)
                                                                setNoClickFild(false)
                                                                setIndexInput()
                                                                setIndex()
                                                            }}>保存</Button>
                                                        </div>
                                                    ))
                                                }
                                                <div style={{ display: "block" }}>
                                                    <span>预测数显</span>
                                                    <Checkbox 
                                                        style={{ marginLeft: "50px" }}
                                                        checked={isRounds[0] == '0' ? false : true}
                                                        onChange={(val) => onChangeRounding(val, '0', allBreedID)}
                                                    >四舍五入到百位</Checkbox>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={isOpen} title="提示" width={300} onOk={() => { setIsOpen(false) }} okText='确定' maskClosable={false}
                onCancel={() => setIsOpen(false)}
                footer={[
                    // 定义右下角 按钮的地方 可根据需要使用 一个或者 2个按钮
                    <Button key="submit" type="primary" onClick={() => { setIsOpen(false) }} size='small'>
                        确定
                    </Button>]}
            >
                <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px', marginLeft: '20px', fontSize: '18px' }} />
                <span>该公式校验未通过，保存失败</span>
            </Modal>
            <DeleteFL isOpenFl={isOpenFl}
                onCancel={() => {
                    setIsOpenFl(false)
                    pubilc()
                }}
                allFl={tags.filter(item => {
                    return item.levelName != '暂未分类品种'
                })}
            ></DeleteFL>
        </div>
    )
}
