import { exportFile, requests } from "../utils/http";
// 查询品种分类
export const publiSystemConfig = (query) => {
    return requests({
        url: "/publiSystemConfig/list",
        method: 'get',
        params: query
    })
}

// 查询全部销售渠道(补货用)
export const dictData = () => {
    return requests({
        url: "/dictData/list",
        method: 'get',
        params: {
            dictType: 'publi_prod_channel'
        }
    })
}
// 补货预测
export const replenishmentCal = (data) => {
    return requests({
        url: "/appStockResult/predictCalculation",
        method: 'post',
        data: data,
        headers: { "Content-Type": "application/json" },
    })
}
// 展示列表
export const showList = (query) => {
    return requests({
        url: "/appStockResult/list",
        method: 'get',
        params: query,
    })
}
//  修改补货数
export const updateStock = (data) => {
    return requests({
        url: "/appStockResult/update",
        method: 'post',
        data,
        headers: { "Content-Type": "application/json" },
    })
}
//  查看历史批次
export const appCountBatch = (query) => {
    return requests({
        url: "/appCountBatch/list",
        method: 'get',
        params: query
    })
}
//  补货导出结果
export const stockExport = (query) => {
    return exportFile('/api/appStockResultHistory/export', query)
}
// 查看历史数据
export const historyRecord = (query) => {
    return requests({
        url: "/appStockResultHistory/list",
        method: 'get',
        params: query,
    })
}
// 导出历史结果
export const exportHistoryStock = (query) => {
    return exportFile('/api/appStockResultHistory/exportHistory', query)
}

export const delHistoryStock = (query) => {
    return requests({
        url: "/appStockResultHistory/delete",
        method: 'get',
        params: query,
    })
}

// 查询获取数据方式
export const getDateSource = (query) => {
    return requests({
        url: "/publiDataSourceConfig/dataType",
        method: 'get',
        params: query,
    })
}
