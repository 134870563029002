/* eslint-disable no-undef */
import { Button, InputNumber, Modal, Select, Table } from 'antd'
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {debounce} from 'loadsh';
import style from './index.module.css'
import { zjyyFieldDetail, initial, updateTable } from '../../api/customTable'
// 为实现拖曳引入依赖
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { getALLQuery } from '../../utils';
function debounceT(fun, wait = 1500, ...args) {
    let timeout = undefined
    return function (e) {
        if (timeout) {//如果存在定时器就清空
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            fun(e, ...args)
        }, wait)
    }

}
export default function CustomCol({ visible, change, onCancel, module }) {
    const [data, setData] = useState([])
    const userInfo = getALLQuery();

    useEffect(() => {
        zjyyFieldDetail({
            userId: userInfo.userId,
            module: module,
            publiId: userInfo.publiId
        }).then(res => {
            if (res.data.code == 200 && res.totalDataNum == 0) {
                initial({
                    module: module
                }).then(res => {
                    setData(res.data)
                })
            } else {
                setData(res.data)
            }
        })
    }, [])

    const handleChange = (e, name, records) => {
        
        records[name] = e
        setData([...data])
    }
    const columns = [
        {
            title: '序号',
            align: 'center',
            render: (_, record) => record.orderId
        },
        {
            title: '名称',
            dataIndex: 'fieldName',
            align: 'center'
        },
        {
            title: '是否启用',
            dataIndex: 'isOpen',
            align: 'center',
            render: (value, records) => {
                // 
                return (
                    <Select value={value} onChange={(e) => { handleChange(e, 'isOpen', records) }} options={[{
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '0'
                    }]}></Select>
                )
            }
        },
        {
            title: '是否冻结',
            dataIndex: 'isFreeze',
            align: 'center',
            render: (value, records) => <Select value={value} onChange={(e) => { handleChange(e, 'isFreeze', records) }}
                options={[{
                    label: '左冻结',
                    value: '1',
                },
                {
                    label: '右冻结',
                    value: '2'
                },
                {
                    label: '不冻结',
                    value: '0',
                }]}></Select>

        }, {
            title: '对齐方式',
            dataIndex: 'align',
            align: 'center',
            render: (value, records) => <Select style={{ width: 100 }} value={value} onChange={(e) => { handleChange(e, 'align', records) }}
                options={[{
                    label: '左对齐',
                    value: '1'
                },
                {
                    label: '右对齐',
                    value: '3'
                },
                {
                    label: '居中对齐',
                    value: '2'
                },
                ]}></Select>
        },
        {
            title: '宽度',
            dataIndex: 'size',
            align: 'center',

            render: (value, records) => <InputNumber value={value} onChange={
                debounceT(handleChange, 1500, 'size', records)
            } />
        },
    ]

    // 实现拖曳排序
    const type = 'DraggableBodyRow';
    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef(undefined);
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: (item) => {
                moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: {
                index,
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));
        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{
                    cursor: 'move',
                    ...style,
                }}
                {...restProps}
            />
        );
    };
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];
            let lastOrder = data[hoverIndex].orderId;
            data[hoverIndex].orderId = data[dragIndex].orderId;
            data[dragIndex].orderId = lastOrder;
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [data],
    );

    function updateSubmit() {
        data.forEach(item => {
            item.userId = userInfo.userId
        })
        updateTable({
            zjyyFieldDetailList: [...data]
        }).then((res) => {
            _maq.trigger(['_trackEvent', `重印预测-更新自定列-${data[0].module == 'app_print' ? '重印' : '补货'}: ${JSON.stringify(data)}`]); 
            change()
            onCancel()
        })
    }

    return (
        <Modal 
            width={1000} 
            open={visible} 
            okText='确定' 
            cancelText='取消' 
            title='自定义列' 
            destroyOnClose
            onOk={() => {
                debounceT(updateSubmit(), 1000) 
            }}
            onCancel={onCancel}
        >
            <div className={style.actionControl}>
                <div>
                    <ExclamationCircleFilled style={{ color: '#1A76D2', marginRight: '5px' }} />
                    <span style={{ fontSize: '16px' }}>可设置列表顺序及内容，鼠标拖动可以进行排序</span>
                </div>
                <div>
                    <Button 
                        onClick={() => {
                            //调用初始化接口
                            initial({
                                module: module
                            }).then(res => {
                                setData(res.data)
                            })
                        }} 
                        style={{ marginRight: '15px', backgroundColor: '#5F9EA0	', color: 'white', width: '90px' }} 
                    >初始化</Button>
                    <Button 
                        type="primary" 
                        onClick={() => {
                            data.forEach((item) => {
                                item.isOpen = '1'

                            })
                            setData([...data])
                        }} 
                        style={{ marginRight: '15px' }}
                    >全部启用</Button>
                    <Button 
                        onClick={() => {
                        data.forEach((item) => {
                            item.isOpen = '0'
                        })
                        setData([...data])
                    }}>取消全启用</Button>
                </div>
            </div>
            <DndProvider backend={HTML5Backend}>
                <Table columns={columns} dataSource={data} bordered={true} pagination={false} size='small' scroll={{ y: 300 }}
                    components={components}
                    onRow={(_, index) => {
                        const attr = {
                            index,
                            moveRow,
                        };
                        return attr;
                    }}
                />
            </DndProvider>
        </Modal>
    )
}
