import {requests, exportFile} from "../utils/http";
// 通用下载到入模板
export const importTemplate=(query)=>{
 //发请求
   return exportFile('/api/importModuleConfig/importTemplate', query)
}
// 数据库字段查询
export const databaseField=(query)=>{
   return requests({
      url:"/importFieldConfig/list",
      method:'get',
      params: {
        moduleId:query
      },
  })
}
// 获取上一次导入数据截止日期
export const importLastTime=(query)=>{
  return requests({
     url:"/importModuleConfig/importLastTime",
     method:'get',
     params: {
      publiId:query
     },
 })
}
// 通用接口上传表格
export const importCusTable=(data)=>{
  return requests({
     url:"/importFieldMapping/importFile",
     method:'post',
     data,
     headers: {"Content-Type":"multipart/form-data"},
 })
}
// 查询上一次映射关系
export const importFieldMapping=(query)=>{
  return requests({
     url:"/importFieldMapping/list",
     method:'get',
     params: query
 })
}
// 全部模块ID
export const getAllModuled=()=>{
  return requests({
     url:"/importModuleConfig/list",
     method:'get',
     headers: {"Content-Type":"multipart/form-data"},
 })
}
// 查询渠道列表
export const getAllChannel=(query)=>{
  return requests({
     url:"/importChannelConfig/selectList",
     method:'get',
     params:query
 })
}
