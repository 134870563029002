import {requests} from "../utils/http";
// 字段查询
export const fieldConfig=(query)=>{
   return requests({
      url:"/importFieldConfig/list",
      method:'get',
      params: query
  })
}
// 查询基础信息配置与自定义分类规则
export const publiSystemConfig=(query)=>{
   return requests({
      url:"/publiSystemConfig/list",
      method:'get',
      params:query
  })
}
// 新增自定义分类
export const addCategory=(data)=>{
   return requests({
      url:"/publiLevelConfig/add",
      method:'post',
      data,
      headers: {"Content-Type":"application/json"},
  })
}
// 删除自定义分类
export const deleteCategory=(data)=>{
   return requests({
      url:"/publiLevelConfig/delete",
      method:'post',
      data,
      headers: {"Content-Type":"multipart/form-data"},
  })
}
// 修改基础信息+清空+保存
export const update=(data)=>{
   return requests({
      url:"/publiSystemConfig/update",
      method:'post',
      data,
      headers: {"Content-Type":"application/json"},
  })
}
// 查询渠道列表   
export const getAllChannel=(query)=>{
   return requests({
      url:"/importChannelConfig/selectList",
      method:'get',
      params:query,
      headers: {"Content-Type":"multipart/form-data"},
  })
 }
 // 修改渠道
export const updateChannel=(data)=>{
   return requests({
      url:"/importChannelConfig/updateChannel",
      method:'post',
      data,
      headers: {"Content-Type":"application/json"},
  })
}
  // 查询获取数据方式
  export const getDateSource=(query)=>{
   return requests({
      url:"/publiDataSourceConfig/dataType",
      method:'get',
      params:query,
  })
 }
 