/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Tabs, Form, Row, Col, Select, Input, Button, Table, Popconfirm, message, Modal, InputNumber , Tooltip } from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import {compact, cloneDeep} from 'loadsh';
import { publiSystemConfig, dictData } from '../../../api/replenishmentSupervise';
import { downFile, getALLQuery } from '../../../utils';
import ImportBreed from '../../../components/ImportBreed';
import style from './index.module.css';
import { getAllChannel } from '../../../api/config';
import { getVarietyList, deleteItemByIds, exportsResult, update, allDel } from '../../../api/varietyConfig';
const TabPane = Tabs.TabPane;
const userInfo = getALLQuery();

export default function VarietyConfig(props) {
    const [form] = Form.useForm();
    let [key, setKey] = useState('0');
    let [channel, setChannel] = useState(''); // 设置渠道
    // let [pzOptions, setPzOptions] = useState([{label: '全部品种', value: '',}]); // 品种下拉列表框数据源
    let [pzOptions, setPzOptions] = useState([]); // 品种下拉列表框数据源
    let [channelOptions, setChannelOptions] = useState([]); // 销售渠道下拉列表框数据源
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 表格选中项
    const [editKey, setEditKey] = useState([]); // 表格选中项
    const [minEditKey, setMinEditKey] = useState([]); // 表格选中项
    const [list, setList] = useState([]); // 表格分页参数
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0}); // 表格分页参数
    const [confirmOpen, setConfirmOpen] = useState(false); // 批量删除确认弹框
    const [confirmOpenT, setConfirmOpenT] = useState(false); // 全部清空确认弹框
    const [max, setMax] = useState(''); // 上限input
    const [min, setMin] = useState(''); // xia限input
    const [breedOpen, setBreedOpen] = useState(false); // xia限input
    const [flag, setFlag] = useState(true); // 是否初次加载，用于控制品种下拉列表采用props或者列表的值
    const [loading, setLoading] = useState(false);
    const [ids, setIds] = useState(false); // 要删除的列的mappId
    const [disabledClear, setDisabledClear] = useState(false)
    const [disabledClearT, setDisabledClearT] = useState(false)

     // props.levelInfo双击重印配置或者补货配置中的任何一个自定义分类跳转到重印维护页或者补货维护页时带过来的参数propsCopy
    const [propsCopy, setPropsCopy] = useState(props?.levelInfo); 
    
    let col = [
        {
            title: '品种分类',
            dataIndex: 'levelName',
            align: 'center',
        },
        {
            title: '品种名称',
            dataIndex: 'prodName',
            align: 'center',
            width: 400
        },
        {
            title: 'ISBN',
            dataIndex: 'isbn',
            align: 'center',
        },
        {
            title: '定价',
            dataIndex: 'price',
            align: 'center',
        },
        {
            title: (
                <span>
                    内部库存上限
                    <Tooltip 
                        placement="topRight"
                        overlayInnerStyle={{width:'380px'}} 
                        title={<p className="u-tips">
                            <span>含义：针对不同品种单独定制重印的内部库存预警上限值<br/></span>
                            <span>
                                举例：如参与重印规则确定为”当【内部库存】低于【内部库存上限】时提醒重印“，
                                假设将品种A上限设为1000本，品种B为5000本。那么品种A【内部库存】低于1000本触发重印， 
                                品种B【内部库存】低于5000本触发重印。
                                <br/>
                                【内部库存下限】规则同理，并且可两者相结合实际情况自定义设置重印规则。
                            </span>
                            <span>重印规则配置界面：系统配置-&gt;重印配置-&gt;规则信息。</span>
                        </p>
                    }>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'saleInvUpLimit',
            align: 'center',
            render: (val, record, idx) => {
                return (
                    <>
                        {
                            editKey == record.prodId
                            ?  
                            <div className={style.editStyle}>
                                <InputNumber defaultValue={val || 0} style={{ width: '80px' }} onChange={(e) => onChangInput(e, '0')} /> 
                                <CloseOutlined onClick={() => cancelUpdate('0')} />
                                <CheckOutlined onClick={() => yesUpdate(record, '0')} />
                            </div>
                            :  
                            <div className={style.editStyle}>
                                <div style={{ color: '#1A76D2', marginRight: '5px' }}>{val || '0'}</div>
                                <EditOutlined onClick={() => setEditKey(record.prodId)} />
                            </div>
                        }
                    </>
                )
            }
        },
        {
            title: (
                <span>
                    内部库存下限
                    <Tooltip 
                        placement="topRight"
                        overlayInnerStyle={{width:'380px'}} 
                        title={<p className="u-tips">
                            <span>含义：针对不同品种单独定制重印的内部库存预警下限值<br/></span>
                            <span>
                                举例说明：将参与重印规则定义为“当【内部库存】低于【内部库存下限】时提醒重印”。
                                举例：如参与重印规则确定为”当【内部库存】低于【内部库存下限】时提醒重印“，
                                假设将品种A下限设为1000本，品种B为5000本。那么品种A【内部库存】低于1000本触发重印， 
                                品种B【内部库存】低于5000本触发重印。
                                <br/>
                                【内部库存上限】规则同理，并且可两者相结合实际情况自定义设置重印规则。
                            </span>
                            <span>重印规则配置界面：系统配置-&gt;重印配置-&gt;规则信息。</span>
                        </p>
                    }>
                        <QuestionCircleFilled style={{color:'#9eabb2',marginLeft:'4px'}}/>    
                    </Tooltip>
                </span>
            ),
            dataIndex: 'saleInvDownLimit',
            align: 'center',
            render: (val, record, idx) => {
                return (
                    <>
                        {
                            minEditKey == record.prodId
                            ?  
                            <div className={style.editStyle}>
                                <InputNumber defaultValue={val || 0} style={{ width: '80px' }} onChange={(e) => onChangInput(e,'1')}/> 
                                <CloseOutlined onClick={() => cancelUpdate('1')} />
                                <CheckOutlined onClick={() => yesUpdate(record, '1')} />
                            </div>
                            :  
                            <div className={style.editStyle}>
                                <div style={{ color: '#1A76D2', marginRight: '5px' }}>{val || '0'}</div>
                                <EditOutlined onClick={() => setMinEditKey(record.prodId)} />
                            </div>
                        }
                    </>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            align: 'center',
            render: (text, record) => (
                <Popconfirm
                    disabled={!record.mappingId}
                    title={<p className={style.popconfirm}>是否确认将该品种移出当前分类<br/>
                                <span>移出后该品种将转移至暂未分类中</span>
                            </p>
                        }
                    icon={<ExclamationCircleFilled style={{ color: '#1B76D2' }} />}
                    onConfirm={() => deleteItem(record.mappingId)}
                    cancelText="取消"
                    okText="确认"
                >
                    <Button type='link' disabled={!record.mappingId} href="#">移出当前分类</Button>
                </Popconfirm> 
            )  
        },
    ]
    let colT = [
        {
            title: '销售渠道',
            dataIndex: 'channelName',
            align: 'center',
        },
        {
            title: '品种分类',
            dataIndex: 'levelName',
            align: 'center',
        },
        {
            title: '品种名称',
            dataIndex: 'prodName',
            align: 'center',
            width: 400
        },
        {
            title: 'ISBN',
            dataIndex: 'isbn',
            align: 'center',
        },
        {
            title: '定价',
            dataIndex: 'price',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            align: 'center',
            render: (text, record) => (
                <Popconfirm
                    disabled={!record.mappingId}
                    title={<p className={style.popconfirm}>是否确认将该品种移出当前分类<br/>
                                <span>移出后该品种将转移至暂未分类中</span>
                            </p>
                        }
                    icon={<ExclamationCircleFilled style={{ color: '#1B76D2' }} />}
                    onConfirm={() => deleteItem(record.mappingId)}
                    cancelText="取消"
                    okText="确认"

                >
                    <Button type='link' disabled={!record.mappingId} href="#">移出当前分类</Button>
                </Popconfirm> 
            ) 
        },
    ]

    useEffect(() => {
        let type = (!!propsCopy?.type && propsCopy?.type == 'stock') ? '1' :  '0';
        let channel = !!propsCopy?.channel ? propsCopy?.channel : ''
        setKey(type)
        setChannel(channel)
        getSaleChannelSelectList();
        form.setFieldsValue({
            channel: channel
        })
    }, [])

    useEffect(() => {
        getPzSelectList(); // 获取品种分类下拉列表框
    }, [key])

    useEffect(() => {
        getPzSelectList('1'); // 获取品种分类下拉列表框
    }, [channel])

    useEffect(() => {
        setList([]);
        if (pagination.current != 1) setPagination({current: 1, pageSize: 10, total: 0});
        // if (pagination.current == 1) getVarietyListT();
        setSelectedRowKeys([])
        setIds([])
    }, [key])

    useEffect(() => {
        setList([]);
        getVarietyListT()
    }, [pagination.current, flag])

    const getVarietyListT = () => {
        setLoading(true)
        let values = form.getFieldsValue();
        let filterObj = pzOptions.filter(item => item.value == values.levelId);
        let obj = filterObj.length>0?filterObj:[{label:'全部品种',value:''}]
        let parmas = {
            ...values,
            // channel:values.channel == ''?'0':values.channel,
            levelId: !!propsCopy?.levelId ? propsCopy?.levelId : values.levelId,
            configType: key == '0' ? '1' : '2',
            levelName: !!propsCopy?.levelName ? propsCopy?.levelName : obj[0]?.label,
            publiId: userInfo.publiId,
            pageNum: pagination.current,
            pageSize: 10,
        }
        _maq.trigger(['_trackEvent', `查询${key == '0' ? '重印' : '补货'}列表-查询条件: ${JSON.stringify(parmas)}`]); 
        getVarietyList(parmas).then((res) => {
            setLoading(false)
            setPropsCopy({})
            if (res.code == 200) {
                setList(cloneDeep(res.rows))
                setPagination({...pagination, total: res.total})
            } else {
                message.error(res.msg,2)
                setPagination({...pagination, total: res.total})
            }

            setDisabledClear(`${obj[0]?.label}`.includes('暂未分类品种')  || [...res.rows].length == 0)
            setDisabledClearT(`${obj[0]?.label}`.includes('暂未分类品种') || `${obj[0]?.label}`.includes('全部品种'))
        })
    }

    const getPzSelectList = (type) => { // 传1表示不用重新查询列表   
        publiSystemConfig({
            publiId: userInfo.publiId,
            configType: key == '0' ? '1' : '2',
            channel: key == '0' ? '0' :  (channel ?channel:'0'),
        }).then(res => {
            if (res.code == 200) {
                const cate = res.rows.map((item) => {
                    return (
                        {
                            label: item.levelName,
                            value: item.levelId,
                        }
                    )
                })
                let arr =  [{
                    label: '全部品种',
                    value: '',
                }, ...cate]
                // setPzOptions([...arr])
                // form.setFieldsValue({
                //     levelId: !!propsCopy?.levelId ? propsCopy?.levelId : '',
                // }) // 下面为zh改

                let isNeed = res?.rows[0]?.isNeedCate;
                let values = form.getFieldsValue()
                if(values.channel == ''){  // 渠道为全部渠道的时候，品种分类中要去掉包含 '全部品种-' 的所有分类
                    let result1 = arr.filter(item=>!(item.label.includes('全部品种-')))
                    console.log(result1);
                    setPzOptions([...result1])
                    form.setFieldsValue({
                        levelId: !!propsCopy?.levelId ? propsCopy?.levelId : '',
                    })
                }else if(isNeed == '0') { // 当渠道为具体的某一个渠道，且不需要分类，如京东，此时京东渠道若不需要分类，则品种分类下拉框以及默认值都是全部品种-京东
                    console.log(isNeed);
                    console.log(arr);
                    let result2 = arr[1]
                    setPzOptions([result2])
                    form.setFieldsValue({
                        levelId: !!propsCopy?.levelId ? propsCopy?.levelId : result2.value,
                    })
                }else{ //当渠道为具体的某一个渠道,且需要分类得时候，如京东，此时京东渠道需要分类，则品种分类下拉框以及默认值都是全部品种-京东
                    let length = arr.length
                    arr[0].label = arr[0].label + arr[length-1].label.slice(6)
                    setPzOptions([...arr])
                    form.setFieldsValue({
                        levelId: !!propsCopy?.levelId ? propsCopy?.levelId : arr[0].value,
                    })
                }

                if (type != '1') {
                    setFlag(!flag)
                }
            }
        })
    }

    const getSaleChannelSelectList = () => {

        getAllChannel({publiId:userInfo.publiId}).then(res => {

            const funnel = [...res.rows].filter(item => item.isOpen == '1' && item.channelName != '线下').map((item) => {
                return (
                    {
                        label: item.channelName,
                        value: item.channel,
                    }
                )
            })
            setChannelOptions([{
                label: '全部渠道',
                value: '',
            }, ...funnel])
        })
    }

    const onChangeChannel = (val) => {
        form.setFieldsValue({channel: val, levelId: ''})
        setPropsCopy({})
        setChannel(val)
    }

    const reset = () => {
        form.resetFields();
        setChannel('') // zh加
        form.setFieldsValue({
            // levelId: pzOptions[0].value
            levelId:'' // zh改
        })
        getVarietyListT();
        _maq.trigger(['_trackEvent', `重置${key == '0' ? '重印' : '补货'}的查询`]); 
    } 

    const searchListByForm = () => {
        setPagination({...pagination, current: 1})
        setFlag(!flag)
        // getVarietyListT()  
    }

    // 分页器改变
    const handlePageChange = (page) => {
        setPagination({...pagination, current: page.current})
    }

    // 移出品种
    const deleteItem = (ids) => {
        let parmas = {
            ids: ids
        }
        deleteItemByIds(parmas).then(res => {
            if (res.code == 200) {
                message.success('移出成功',2);
                _maq.trigger(['_trackEvent', `品种维护-${key == '0' ? '重印' : '补货'}-移出品种：${JSON.stringify(parmas)}`]); 
                let delLen = selectedRowKeys == 0 ? 1 : selectedRowKeys.length
                let totalPage = Math.ceil((pagination.total - delLen) / pagination.pageSize);
                let cur = pagination.current > totalPage ? (totalPage == 0 ? 1 : totalPage) : pagination.current
                setPagination({
                    ...pagination,
                    current: cur,
                })
                getVarietyListT()
            }
        })
    }

    // 表格批量勾选  
    const onSelectChange = (selectKeys) => {
        let arr = [];
        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < [...selectKeys].length; j++) {
                if (list[i].prodId == selectKeys[j]) {
                    arr.push(list[i].mappingId)
                }
            }
        }
        compact(arr)
        
        setIds([...new Set(arr)])
        setSelectedRowKeys([...selectKeys])
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
        getCheckboxProps: (record) => ({
            disabled: !record.mappingId, //禁用的条件
        }),
    };

    // 批量移除品种
    const batchDel = () => {
        let arr = [...new Set(ids)].join(',')
        deleteItem(arr)
        setConfirmOpen(false);
    }

    const openConfirm = () => {
        if(compact(ids).length == 0) {
            message.warning('请先勾选一些要移出的品种',2)
        } else {
            setConfirmOpen(true)
        }
    }
    // 导出
    const exportList = () => {
        let values = form.getFieldsValue();
        let obj = pzOptions.filter(item => item.value == values.levelId);
        let parmas = {
            ...values,
            configType: key == '0' ? '1' : '2',
            levelName: obj[0].label,
            publiId: userInfo.publiId,
        }
        exportsResult(parmas).then((res) => {
            downFile(res);
            _maq.trigger(['_trackEvent', `品种维护-${key == '0' ? '重印' : '补货'}-导出表格`]); 
        })
    }

    // 取消更新 0-上限 1-下限
    const onChangInput = (val, type) => {
        if (type == '0') setMax(val)
        else setMin(val)
    }
    const cancelUpdate = (type) => {
        if (type == '0') {
            setEditKey('');
            setMax('')
        } 
        else {
            setMinEditKey('')
            setMin('')
        } 
    }

    const yesUpdate = (record, type) => {
        if (!/^[+]{0,1}(\d+)$/.test(min) && type == 1) {
            message.error('内部库存下限请输入正整数',2)
            return false;
        }
        if (!/^[+]{0,1}(\d+)$/.test(max) && type == 0) {
            message.error('内部库存上限请输入正整数',2)
            return false;
        }
        if (type == 0 && max < record.saleInvDownLimit) {
            message.warning('内部库存上限要大于内部库存下限',2)
            return false;
        }
        if (type == 1 && min > record.saleInvUpLimit) {
            message.warning('内部库存下限要小于内部库存上限',2)
            return false;
        }
        let parmas = {
            prodId: record.prodId,
            saleInvUpLimit: type == 0 ? max : null,
            saleInvDownLimit: type == 1 ? min : null
        }
        update(parmas).then((res) => {
            if(res.code == 200) {
                message.success(`修改内部库存${type == 0 ? '上限' : '下限' }成功！`,2)
                _maq.trigger(['_trackEvent', `修改内部库存${type == 0 ? `上限为${max}` : `下限为${min}` }` ])
                if (type == '0') {
                    setEditKey('');
                    setMax('')
                } 
                else {
                    setMinEditKey('')
                    setMin('')
                } 
                getVarietyListT()
            } else {
                message.success(`修改内部库存${type == 0 ? '上限' : '下限' }失败！`,2)
            }
        })
    }

    // 全部清空
    const openConfirmT = () => {
        let values = form.getFieldsValue();
        let obj = pzOptions.filter(item => item.value == values.levelId);
        setConfirmOpenT({open: true, levelName: obj[0].label })
        // allDel({levelId: values.levelId}).then((res) => {

        // })
    }

    const allDelPz = () => {
        let values = form.getFieldsValue();
        console.log(values?.levelId, 'oooooooo')
        let leveIdAll = pzOptions.map(item => item.value);
        let parmas = {
            levelIds: !!values?.levelId  ? [values?.levelId] : leveIdAll.filter(item => item != '')
        }
        allDel(parmas).then((res) => {
            if (res.code == '200') {
                message.success('删除成功', 2)
                _maq.trigger(['_trackEvent', `品种维护-${key == '0' ? '重印' : '补货'}-全部清空：${JSON.stringify(parmas)}`]); 
                setPagination({
                    ...pagination,
                    current: 1,
                })
                getVarietyListT()
                setConfirmOpenT(false)
            }
        })
    }

    return (
        <>      
            <div className={style.content}>
                <Tabs 
                    destroyInactiveTabPane 
                    activeKey={key} 
                    onChange={(key) => {
                        setKey(key); 
                        setPropsCopy({}); 
                        setChannel('')
                        form.resetFields();
                        _maq.trigger(['_trackEvent', `切换tab${key == '0' ? '重印' : '补货'}` ])
                    }}  
                    tabBarGutter='100px'
                >
                    <TabPane tab="重印" key="0" ></TabPane>
                    <TabPane tab="补货" key="1" ></TabPane>
                </Tabs>
                <Form
                    form={form}
                    labelCol={{ style: { width: 80, textAlign: 'left' } }} 
                    initialValues={{channel: '', levelId: '', prodName: '' }}
                    onFinish={searchListByForm}
                >
                    <Row>
                        {
                            key == '1' && (
                                <Col span={5}>
                                    <Form.Item name='channel' label="销售渠道 :" style={{ fontWeight: 'bold' }}>
                                        <Select 
                                            options={channelOptions} 
                                            style={{width: '180px'}} 
                                            onChange={onChangeChannel} 
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                        <Col span={5}>
                            <Form.Item name='levelId' label="品种分类 :" style={{ fontWeight: 'bold' }}>
                                <Select options={pzOptions} style={{width: '180px'}} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name='prodName' label="单品信息 :" style={{ fontWeight: 'bold' }}>
                                <Input placeholder="请输入品种名称或ISBN" style={{ width: '180px' }} autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button type="ghost" style={{marginLeft: '10px'}} onClick={reset}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className={style.content}>
                <div className={style.head}>
                    <span>品种展示</span>
                    <div>
                        <Button 
                            type="primary" 
                            disabled={disabledClearT}
                            onClick={() => {setBreedOpen(true); 
                            _maq.trigger(['_trackEvent', `品种维护-${key == '0' ? '重印' : '补货'}-添加品种`]); }}
                        >添加品种</Button>
                        <Button style={{marginLeft: '10px'}} onClick={openConfirm}>批量移出品种</Button>
                        <Button type="primary" ghost style={{marginLeft: '10px'}} onClick={exportList}>导出结果</Button>
                        <Button 
                            type="primary" 
                            ghost 
                            style={{marginLeft: '10px'}} 
                            onClick={openConfirmT}
                            disabled={disabledClear}
                        >全部清空</Button>
                    </div>
                </div>
                <Table 
                    key={key}
                    bordered
                    rowKey="prodId"
                    columns={key == '0' ? col : colT}
                    rowSelection={rowSelection}
                    dataSource={list}
                    loading={loading}
                    pagination={{...pagination, showSizeChanger: false}}
                    onChange={handlePageChange}
                />
                {
                    confirmOpen &&
                    <Modal
                        title="批量移出品种"
                        open={confirmOpen}
                        okText="确认"
                        cancelText="取消"
                        onOk={batchDel}
                        onCancel={() => setConfirmOpen(false)}
                    >
                        <p className={style.modalConfirm}>
                            <ExclamationCircleFilled style={{ color: '#1B76D2', fontSize: '24px', marginRight: '8px' }} />
                            是否确认将所勾选的全部品种移出当前分类？
                        </p>
                        <p style={{fontSize: '14px', color: '#B9B9B9', textAlign: 'center'}}>移出后该品种将转移至暂未分类中</p>
                    </Modal>
                }
                {
                    confirmOpenT.open &&
                    <Modal
                        title="全部清空"
                        open={confirmOpenT.open}
                        okText="确认"
                        cancelText="取消"
                        onOk={allDelPz}
                        onCancel={() => setConfirmOpenT({open: false, levelName: ''})}
                    >
                        <p className={style.modalConfirm}>
                            <ExclamationCircleFilled style={{ color: '#1B76D2', fontSize: '24px', marginRight: '8px' }} />
                            是否要清空“{confirmOpenT.levelName}”这个分类下{confirmOpenT.levelName== '全部品种' ? '除暂未分类品种' : ''}的所有图书？
                        </p>
                    </Modal>
                }
                <ImportBreed 
                    name={key == '0' ? 'print' : 'stock'} 
                    visible={breedOpen} 
                    onCancel={(action)=>{
                        if (action == 'ok') {
                            // 全部导入成功后弹框莫关闭太快，如果点击取消则不设置定时器
                            setTimeout(() => {
                                setBreedOpen(false)
                                getVarietyListT()
                            }, 1000);
                        } else {
                            setBreedOpen(false);
                            _maq.trigger(['_trackEvent', `品种维护-添加品种-${key == '0' ? '重印' : '补货'}-取消添加品种`]); 
                        }
                    }}
                />
            </div>
        </>
    )
}


