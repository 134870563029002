import { requests, exportFile, exportFilePost } from "../utils/http";

// 查询品种分类
export const publiSystemConfig = (query) => {
    return requests({
        url: "/publiSystemConfig/list",
        method: 'get',
        params: query
    })
}
// 重印预测    
export const replenishmentCal = (data) => {
    return requests({
        url: "/appStockResult/predictCalculation",
        method: 'post',
        data,
        headers: { "Content-Type": "application/json" },
    })
}
// 展示列表
export const showList = (query) => {
    return requests({
        url: "/appPrintResult/list",
        method: 'get',
        params: query,
    })
}
// 重印导出结果
export const reprintExport = (query) => {
    return exportFile('/api/appPrintResultHistory/export', query)
}

//  获取最近的记录
export const appCountBatch = (query) => {
    return requests({
        url: "/appCountBatch/list",
        method: 'get',
        params: query
    })
}
// 查看历史数据
export const historyRecord = (query) => {
    return requests({
        url: "/appPrintResultHistory/list",
        method: 'get',
        params: query,
    })
}
// 导出历史结果
export const exportHistoryPrint = (query) => {
    return exportFile("/api/appPrintResultHistory/exportHistory", query)
}

export const delHistoryPrint= (query) => {
    return requests({
        url: "/appPrintResultHistory/delete",
        method: 'get',
        params: query,
    })
}

export const updatePrintNum = (data) => {
    return requests({
        url: "/appPrintResult/update",
        method: 'post',
        data,
        headers: { "Content-Type": "application/json" },
    })
}