// 获取地址栏参数
export const getQueryString = (name) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

export const getALLQuery = () => {
    let url = window.location.search;//获取url地址？至结尾的所有参数    
    if(url){
        localStorage.setItem('printUserInfo', url)
        // window.localStorage.setItem()
    }else{
        url = localStorage.getItem('printUserInfo')
    }
    let parmasObj = {};
    let str = url.substring(1, url.length);
    let arr = `${str}`.split('&');
    for (let i = 0; i < arr.length; i++) {
        let tmp_arr = arr[i].split("=");
        parmasObj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    return parmasObj;

}

export const downFile = (data) => {
    //fileName需要处理
    
    let fileName = /.*filename=(.*)/i.exec(data.headers["content-disposition"])?.[1] || "下载";
    let blob = new Blob([data.data]);
    const elink = document.createElement("a");
    elink.download = decodeURI(fileName);
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
}