import { Button, Form, Modal, Select, Upload, Input, message } from 'antd'
import React, { useEffect, useState } from 'react';
import style from './index.module.css'
import { importTemplate, importCusTable, importFieldMapping } from '../../api/importData'
import TimeModal from '../../components/TimeModal'
import { downFile } from '../../utils';
import { getALLQuery } from '../../utils';

export default function DataImortCustomCol({ visible, onCancel, userColumn, name, allField, file,  allModuled, query}) {
    const [form] = Form.useForm();
    const amodule = allModuled.filter(ele => ele.sheetName == name)[0]
    // options为用户导入的excel表格的所有字段
    const options = userColumn.map((item) => {
        return (
            {
                label: item,
                value: item
            }
        )

    })

    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false) // 控制缺失日期弹框的显示
    const [deletionTime, setDeletionTime] = useState([0, 0]) // 缺失日期
    const [loading, setLoading] = useState(false)

    // 由于点击自定义配置弹框的确定按钮后，发现表格中有缺失日期，此时需要弹出缺失日期对话框，让用户选择是否忽略缺失的日期，若用户选择忽略，
    // 则继续调用上传表格通用接口，此时参数data1即为父组件InCustomCol传给子组件TimeModal，
    const [data1, setData1] = useState()

    const userInfo = getALLQuery()

    // 查询上一次映射关系后，提前自动渲染到select的输入框中，作为默认值，用户可以自行改变
    useEffect(() => {
        form.resetFields()
        visible &&
            importFieldMapping({
                publiId: userInfo.publiId,
                userId: userInfo.userId,
                moduleId: amodule?.moduleId
            }).then(res => {
                let obj = {}
                
                
                res.rows.forEach(item => {
                    if (userColumn.find(ele => ele == item.inFieldName)) {
                        obj[item.fieldName] = item.inFieldName
                    }

                })
                
                form.setFieldsValue({ ...obj })
            })
    }, [visible])

    // 下载模板      
    const ImportTemplate = (data, filename) => {
        
        importTemplate({'moduleId': data.moduleId}).then((res) => {
            message.success('请耐心等待下载完成',2)
            downFile(res)
        })
    }

    const onCheck = async () => {
        setLoading(true)
        try {
            const values = await form.validateFields();
            const data1 = {
                ...query,
                file: file[0],
                mapResult: JSON.stringify(values),
                userId: userInfo.userId,
                moduleId: amodule?.moduleId,
                publiId: userInfo.publiId,
            }
            setData1(data1)

            // 将表格中的字段和数据库中的必填字段和非必填的映射关系传给后端
            importCusTable(data1).then(res => {
                setLoading(false)
                if (res.code == '302') {
                    // 
                    setIsTimeModalOpen(true)
                } else if (res.code != 200){
                    message.error(res.msg,2)
                } else {
                    message.success(!!res.msg ? res.msg : '导入成功',2)
                }
                console.log(file);
                onCancel(res.code == 200?file[0].name:'')
            })
        } catch (errorInfo) {
            
        }
    };
    return (
        <Modal width={900} open={visible} title={
            <div className={style.title}>
                <div><span>自定义配置({name})</span></div>
                <div style={{ fontSize: '14px' }}>
                    <span>存在无法匹配的必填项字段？</span>
                    <span className={style.downloadStyle}
                        onClick={() => ImportTemplate({
                            moduleId: name == '库存数据' ? 'kc' : 'pz'
                        }, `${name}模板.xls`)}
                    >下载模板</span>
                </div>
            </div>}
            onCancel={() => onCancel('')}
            footer={
                <>
                    <Button onClick={() => onCancel('')} type="ghost">取消</Button>
                    <Button onClick={onCheck} loading={loading} type="primary">确定</Button>
                </>
            }
            maskClosable={false}
        >
            <div>
                <div style={{ height: '420px', overflow: 'auto' }}>
                    <div className={style.head}>
                        <div style={{ marginLeft: '60px' }}>数据库字段</div>
                        <div>表格字段</div>
                    </div>
                    <Form
                        name="wrap"
                        labelCol={{
                            flex: '200px',
                        }}
                        wrapperCol={{
                            flex: '240px',
                        }}
                        className={style.formItem}
                        colon={false}
                        form={form}
                    >
                        {
                            allField?.map((item, index) => (
                                <Form.Item
                                    label={item.fieldName}
                                    name={item.fieldName}
                                    rules={[
                                        {
                                            required: item.isNeed == 1 ? true : false,
                                            message: '必填项不能为空'
                                        },
                                    ]}
                                    key={index}
                                >
                                    <Select
                                        size='small' options={options}  allowClear={true}
                                    />
                                </Form.Item>
                            ))
                        }
                    </Form>
                </div>
            </div>
            <TimeModal visible={isTimeModalOpen} deletionTime={deletionTime} onCancel={() => setIsTimeModalOpen(false)} data1={data1} file={file}/>
        </Modal>
    )
}
