import React from 'react'
import IndexRouter from './router/IndexRouter'
import './App.css'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'dayjs/locale/zh-cn';
import './utils/buringPoint';

export default function App() {
    return (
        <ConfigProvider locale={zhCN}>
            <IndexRouter></IndexRouter>
        </ConfigProvider>
    )   
}








