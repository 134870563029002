/* eslint-disable no-undef */
import React from 'react'
import { useState, useEffect } from 'react';
import { Button, Switch, Radio, message } from 'antd';
import style from './index.module.css'
import { getAllChannel, updateChannel } from '../../../../api/config'
import { getALLQuery } from '../../../../utils';

export default function Index() {
    const userInfo = getALLQuery()
    const [buttonIndex, setButtonIndex] = useState(null) // 控制选中其他1或者其它2后，再点击其他地方始终高亮（点击京东等，取消高亮）
    const [disabled, setDisabled] = useState(true) // 控制维护渠道配置一整个是否禁用
    const [channelList, setChannelList] = useState([]) // 保存的是后端返回的除了其他1或其它2渠道的所有渠道相关数据
    const [otherList, setOtherList] = useState([]) // 保存的是后端返回的其他1和其它2中的相关数据
    const [isExistDate, setIsExistDate] = useState('1') // 保存 文件中是否有销售日期 单选框中的值 
    const [length, setLength] = useState()  // 必须保留至少一个开关打开
    const [query, setQuery] = useState() // 调用修改接口传给后端的参数

    // 获取所有渠道
    useEffect(() => {
        // 查询所有渠道
        getAllChannel({ publiId: userInfo.publiId }).then(res => {
            if (res.code == 200) {
                getChannelList(res.rows)
                setQuery(res.rows)
                let length = 0
                // length 存储的值为 刚开始进入页面，所有渠道中默认打开的渠道的个数
                for (var i = 0; i < res.rows.length; i++) {
                    if (res.rows[i].isOpen == '1') {
                        length = length + 1
                    }
                }
                setLength(length)
            } else {
                message.error(res.msg,2)
            }
        })
    }, [])

    // 当修改了渠道配置页面的数据后，需要再查询所有渠道
    const getAllChannelFun = () => {
        getAllChannel({ publiId: userInfo.publiId }).then(res => {
            if (res.code == 200) {
                getChannelList(res.rows)
            } else {
                message.error(res.msg,2)
            }
        })
    }

    // 得到所有渠道后的相关操作   
    const getChannelList = (data) => {

        let channelList = []
        let otherList = []
        
        data.forEach(element => {
            if (element.channelName !== '其他1' && element.channelName !== '其他2') {
                channelList.push({
                    name: element.channelName,
                    value: element.channel,
                    isOpen: element.isOpen
                })
            } else {
                otherList.push({
                    name: element.channelName,
                    value: element.channel,
                    isOpen: element.isOpen
                })
            }
        });
        
        setChannelList([...channelList])
        setOtherList([...otherList])
    }

    // 修改渠道
    const update = (query, idx, type) => { // type： 0-打开/关闭渠道 3-是否有销售日期
        updateChannel(query).then(res => {
            if (res.code == 200) {
                getAllChannelFun()
                message.success({content: `${type == 0 ? `${query[idx].isOpen == '0' ? '关闭' : '开启'}成功` : '修改成功'}`,duration:2})
                _maq.trigger(['_trackEvent', `${type == 0 ? `${query[idx].isOpen == '0' ? '关闭' : '打开'}${query[idx].channelName}渠道` : `${query[idx].isSaleDate == 0 ? '无' : '有'}销售日期`}`]); 
            } else {
                message.error(res.msg,2)
            }
        })
    }

    const onChange = (checked, index, type) => {
        console.log(query);
        var len = length
        if (checked) {
            query[index].isOpen = '1'
            setLength(length + 1)
            len = length + 1
            setQuery([...query])
            // 修改渠道配置   
            update([...query], index, '0')
        } else {
            if (len === 1) {
                message.warning('关闭失败，至少保留一个开启的渠道',2)
            } else {
                query[index].isOpen = '0'
                setLength(length - 1)
                len = length - 1
                setQuery([...query])
                // 修改渠道配置    
                update([...query], index, '0')
            }
        }
        if (type == '2') {
            if (query[index].channelName === '其他1') {
                query[index].isMerge = '1'
                query[index].isSaleDate = isExistDate
            } else if (query.channelName === '其他2') {
                query[index].isMerge = '0'
                query[index].isSaleDate = isExistDate
            }
        }

    };
    // 文件中是否有销售日期      
    function handleChange(e) {
        setIsExistDate(e.target.value)
        // 修改渠道配置
        query[buttonIndex].isSaleDate = e.target.value
        console.log(query);
        setQuery([...query])
        update([...query], buttonIndex, '1')
    }
    return (
        <div>
            <div className={style.top}>
                <span style={{ marginRight: '14px' }}>添加外部渠道：</span>
                {
                    query?.map((item, index) => {
                        if (item.dictSort >= 7) {
                            return null
                        }
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }} key={index} onClick={() => {
                                return (
                                    setButtonIndex(),
                                    setDisabled(true)
                                )
                            }}>
                                <Switch
                                    onChange={(e) => onChange(e, index, '1')}
                                    checked={item.isOpen === '1' ? true : false}
                                    size='small' />
                                <div style={{ marginRight: '30px', marginLeft: '6px' }} >{item.channelName}</div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={style.center}>
                {
                    query?.map((item, index) => {
                        if (item.dictSort < 7) {
                            return null
                        }
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                {/* index+5  是因为其它1和其它2的索引0，1 ；在整个query中对应的索引是5，6*/}
                                <Switch onChange={(e) => onChange(e, index, '2')} size='small' checked={item.isOpen == '1' ? true : false} />
                                <Button
                                    onClick={() => {
                                        return (
                                            setButtonIndex(index),
                                            setDisabled(false),
                                            setIsExistDate(query[index].isSaleDate)
                                        )
                                    }}
                                    style={{ marginRight: '30px', marginLeft: '6px', fontSize: '14px', border: buttonIndex == index ? '1px solid #1890ff' : undefined }} size='small'>
                                    {item.channelName}
                                </Button>
                            </div>
                        )
                    })
                }
                <div style={{ color: '#C0C0C0', marginLeft: '14px' }}>提示：点击其他1、其他2的渠道名称后可对该渠道配置进行维护</div>
            </div>
            <div className={style.bottom} style={{ color: disabled ? '#C0C0C0' : 'black' }}>
                <span style={{ marginRight: '14px' }}>维护渠道配置：</span>
                <div>
                    {
                        buttonIndex == 7 ? <span>销售和库存<b>不处于</b>同一文件</span> : (buttonIndex == 6 ? <span>销售和库存<b>处于</b>同一文件</span> : <span>销售和库存是否处于同一文件</span>)
                    }
                    <div style={{ marginTop: '20px' }}>
                        <span>文件中是否有销售日期</span>
                        <Radio.Group onChange={handleChange} style={{ marginLeft: '15px' }} disabled={disabled}
                            value={buttonIndex != null ? isExistDate : null}>
                            <Radio value={'1'} style={{ marginRight: '30px' }} >是</Radio>
                            <Radio value={'0'}>否</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}
